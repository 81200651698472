import { Button, ColorPicker } from '@shopify/polaris'
import ColorUtils from '../lib/ColorUtils'
import { useState } from 'react'

function ColorModal({ showColorModal, setShowColorModal, value, setValue }) {
  const [newColor, setNewColor] = useState(ColorUtils.hexToHsb(value))

  const onCancel = () => {
    setValue(value)
    setShowColorModal(false)
  }

  const onSelect = () => {
    setValue(ColorUtils.hsbToHex(newColor))
    setShowColorModal(false)
  }

  if (!showColorModal) return <></>

  return (
    <div className="ColorModal">
      <div className="PickerContainer">
        <ColorPicker onChange={setNewColor} color={newColor} />
      </div>
      <div className="ButtonGroup">
        <Button destructive onClick={onCancel}>Cancel</Button>
        <Button primary onClick={onSelect}>Select</Button>
      </div>
    </div>
  )
}

export default ColorModal
