/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useReducer } from 'react'
import { useAppBridge } from '@shopify/app-bridge-react'
import request from '../../lib/request'
import moment from 'moment'
import reducer from '../../reducers/content_test'

const defaultState = {
  name: '',
  start_date: moment().toDate(),
  finish_date: moment().add(7, 'days').toDate(),
  status:'',
  changes: []
}

export default function useContentTest(id) {
  const [contentTest, dispatch] = useReducer(reducer, defaultState)

  const app = useAppBridge()

  const load = async () => {
    try {
      const data = await request(`content_test/${id}`, 'GET', undefined, undefined, app)
      dispatch({ type: 'LOAD_DATA', data: data.content_test })
    } catch (error) {}
  }

  const update = async (id) => {
    try {
      return request(`content_test/${id}`, 'PUT', contentTest, undefined, app)
    } catch (error) {}
  }

  const create = async () => {
    try {
      return request('content_test/', 'POST', contentTest, undefined, app)
      // dispatch({ type: 'LOAD_DATA', data: createdContentTest })
    } catch (error) {}
  }

  const save = async () => {
    console.log('Saving content change...')
    console.log({ contentTest })
    if (contentTest._id) return update(contentTest._id)

    return create()
  }

  const pause = async (id) => {
    try {
      return request(`content_test/${id}/change_status/paused`, 'POST', undefined, undefined, app)
    } catch (error) {
      console.error(error)
    }
  }

  const shopReachedFreePlanLimitation = async () => {
    try {
      const id = 100 //todo remove id
      const { reached } = await request('test/'+ id +'/reached_free_plan_limitation', 'get', undefined, undefined, app)
      return reached
    } catch (error) {}
  }

  useEffect(() => {
    if (id) load()
  }, [id])

  return { contentTest, shopReachedFreePlanLimitation, dispatch, save, pause, update }
}
