import { Card } from '@shopify/polaris'
import './style.scss'
import MedalImg from './medal.png'

function WinnerStatus({ testAB }) {
  if (testAB.status !== 'winner_assigned') return <></>

  return (
    <div className="WinnerStatus">
      <Card>
        <div className="left item">
          <Title />
          <Winner testAB={testAB} />
        </div>
        <div className="right item">
          <Medal />
        </div>
      </Card>
    </div>
  )
}

function Title() {
  return (
    <div className="Title">
      <strong> Winner assigned </strong>
    </div>
  )
}

function Winner({ testAB }) {
  if (!testAB.winner) return <></>

  const variant = () => {
    const value = testAB.winner.replace('test_', '')

    return `Variant ${value.toUpperCase()}`
  }

  return (
    <div className="Winner">
      <span>{variant()}</span>
    </div>
  )
}

function Medal() {
  return (
    <div className="Medal">
      <img src={MedalImg} alt="Winner" />
    </div>
  )
}

export default WinnerStatus
