import { Modal, TextContainer } from '@shopify/polaris'
import React from 'react'

function RunningChange({ isValidate, active, onClose, handleClose, handleNext, switchesBetween: { variant_every, variant_every_type } }) {
  return (
    <Modal
      open={active}
      onClose={onClose}
      title="Do you want to start your test right away?"
      primaryAction={isValidate ? {
        content: 'Start my test now',
        onAction: handleNext,
      }: false}
      secondaryActions={[
        {
          content: 'Pause my test',
          onAction: handleClose,
        },
      ]}
    >
      <Modal.Section>
        <TextContainer>
          {!isValidate ? <p>
            You already have an active test running on this product. You can't have two active tests on the same product. We're going to pause this new test for you.
          </p> :
          <p>
            Would you like TridentAB to start your AB Test right away? If so, we'll immediately start showing the Variant B of your product, and switch variants every {variant_every} {variant_every_type} moving forward.
          </p>}
        </TextContainer>
      </Modal.Section>
    </Modal>
  )
}

export default RunningChange
