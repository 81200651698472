import React, { useState, useCallback } from 'react'
import { Icon, ResourceList, Popover, Thumbnail } from '@shopify/polaris'
import { InfoMinor } from '@shopify/polaris-icons'

const BulkProductListTooltip = ({ testAB }) => {
  const [popoverActive, setPopoverActive] = useState(false)
  const [visibleTestIndex, setVisibleTestIndex] = useState(10)

  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    []
  )

  const handleScrolledToBottom = useCallback(() => {
    const totalIndexes = testAB.childrenTests.length
    const interval =
      visibleTestIndex + 3 < totalIndexes
        ? 3
        : totalIndexes - visibleTestIndex

    if (interval > 0) {
      setVisibleTestIndex(visibleTestIndex + interval)
    }
  }, [testAB.childrenTests.length, visibleTestIndex])

  const handleResourceListItemClick = useCallback(() => { }, [])

  const activator = (
    <div onMouseEnter={() => togglePopoverActive()}>
      <Icon
        source={InfoMinor}
        color="base"
      />
    </div>
  )

  const testList = testAB.childrenTests.map((testAB) => ({
    product_title: testAB.product_title,
    sample_image: testAB.sample_image
  }))

  return (

    <div onMouseLeave={() => togglePopoverActive()}>
      <Popover
        sectioned
        active={popoverActive}
        activator={activator}
        onClose={togglePopoverActive}
        ariaHaspopup={true}
      >
        <Popover.Pane onScrolledToBottom={handleScrolledToBottom}>
          <ResourceList items={testList} renderItem={renderItem} />
        </Popover.Pane>
      </Popover>
    </div>
  )

  function renderItem({ product_title, sample_image }) {

    return (
      <ResourceList.Item
        id={product_title}
        media={<Thumbnail size="small" source={sample_image} />}
        onClick={handleResourceListItemClick}
      >
        <div style={{ fontSize: '12px' }}>{product_title}</div>
      </ResourceList.Item>
    )
  }
}

export default BulkProductListTooltip
