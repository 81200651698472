import { useState } from 'react'
import { useAppBridge } from '@shopify/app-bridge-react'
import useCustomHistory from '../../hooks/useCustomHistory'
import request from '../../lib/request'
import { useChangePlan } from '../Pricing/hooks'
import PlanSetup_V2 from '../Pricing/lib/PlanSetup'

export function useTestAb(params) {
  const [state, setStateData] = useState({
    loaded: true,
    loadedValdate: true,
    test: null,
    isValidate: true,
    countActiveTest: 0,
    result: {
      upload: 0,
      count: 0,
    },
    reached_free_limitation: false,
    reached_impressions_limit: false,
    redirectOnCheck: null
  })
  const app = useAppBridge()

  const history = useCustomHistory()

  const setState = (data) => {
    setStateData((_state) => ({ ..._state, ...data }))
  }

  const [changePlan] = useChangePlan()

  const create = async (body) => {
    try {
      setState({ loaded: false })
      const test = await request('test', 'POST', body, undefined, app)
      setState({ test, loaded: false })
    } catch (error) {
      console.log('🚀 ~ file: hooks.js ~ line 38 ~ create ~ error', error)
      // setState({ loaded: true, test: null })
    }
  }

  const check = async (id) => {
    try {
      // setState({ loaded: false })
      const result = await request('test/check/' + id, 'GET', null, undefined, app)
      const result2 = await request ('default/shop', 'GET', null, undefined, app)
      console.log(result2)
      setState({ result })
      if (result.status !== 'draft') {
        setTimeout(() => {
          if (state.redirectOnCheck) {
            if (state.redirectOnCheck === 'upgrade') {
              switch (result2.pricingPlan) {
                case 'Premium Monthly':
                  changePlan(PlanSetup_V2.monthly[2].name)
                  break
                case 'Premium Yearly':
                  changePlan(PlanSetup_V2.yearly[2].name)
                  break
                case 'Business Monthly':
                  changePlan(PlanSetup_V2.monthly[3].name)
                  break
                case 'Business Yearly':
                  changePlan(PlanSetup_V2.yearly[3].name)
                  break
                default:
                  changePlan(PlanSetup_V2.monthly[1].name)
              }
            } else {
              history.push('/pricing')
            }
          }
          else {
            history.push('/')
          }

        }, 500)
      }
      // history.push('/')
      // setState({ loaded: true })
    } catch (error) {
      // setState({ loaded: true })
    }
  }

  const validate = async (id, handle) => {
    try {
      setState({ loadedValdate: false })
      let result = null
      if (id !== null)
        result = await request(`test/validate/${handle}?id=${id || ''}`, 'GET', null, undefined, app)
      const result2 = await request ('default/shop', 'GET', null, undefined, app)
      const reached = reachedImpressionsLimit(result2)
      setState({ loadedValdate: true, isValidate: result.status, countActiveTest: result.count_active_test, reached_free_limitation: result.reached_free_limitation, reached_impressions_limit: reached })
      // history.push('/')
      // setState({ loaded: true })
    } catch (error) {
      // setState({ loaded: true })
    }
  }

  const update = async (body) => {
    try {
      setState({ loaded: false })
      const test = await request('test/' + body._id, 'PUT', body, undefined, app)
      setState({ test, loaded: false })
      // history.push('/')
      // setState({ loaded: true })
    } catch (error) {
      console.log('🚀 ~ file: hooks.js ~ line 100 ~ update ~ error', error)
      // setState({ loaded: true })
    }
  }

  const redirectOnCheck = (value) => setState({ redirectOnCheck: value })

  const reachedImpressionsLimit = (shop) => {
    if (shop?.pricingPlanData?.impressionLimit < 0)
      return false

    if (shop?.currentMonthImpressions < shop?.pricingPlanData?.impressionLimit )
      return false

    return true
  }

  return [state, { create, update, check, validate, redirectOnCheck }]
}
