import PlanPriceCurrent from './components/PlanPriceCurrent'
import PlanPriceExtraInfo from './components/PlanPriceExtraInfo'
import PlanPriceHighlight from './components/PlanPriceHighlight'
import PlanPriceOld from './components/PlanPriceOld'
import './PlanPrice.scss'

function PlanPrice({ pricingPlan }) {
  return (
    <div className="PlanPrice">
      <PlanPriceOld pricingPlan={pricingPlan} />
      <PlanPriceCurrent pricingPlan={pricingPlan}/>
      <PlanPriceHighlight pricingPlan={pricingPlan} />
      <PlanPriceExtraInfo pricingPlan={pricingPlan} />
    </div>
  )
}

export default PlanPrice
