import { useCallback, useState } from 'react'
import './style.scss'
import { TextField } from '@shopify/polaris'
import Unlock from './images/unlock.png'
import Lock from './images/lock.png'

function ImageSizeAbsolute({ selectedElement: { newStyle }, changeStyle }) {
  const [locked, setLocked] = useState(true)
  const width = Math.round(newStyle.width.replace('px', ''))
  const height = Math.round(newStyle.height.replace('px', ''))

  const toggleLock = useCallback(() => {
    setLocked(prevLocked => !prevLocked)
  }, [])

  const onChangeHeight = (newHeight) => {
    const format = (value) => value.toString().concat('px')

    if (locked) {
      const ratio = newHeight / height
      const newWidth = width * ratio

      changeStyle({ height: format(newHeight), width: format(newWidth) })
    }
    else {
      changeStyle({ height: format(newHeight) })
    }
  }

  const onChangeWidth = (newWidth) => {
    const format = (value) => value.toString().concat('px')

    if (locked) {
      const ratio = newWidth / width
      const newHeight = height * ratio

      changeStyle({ width: format(newWidth), height: format(newHeight) })
    }
    else {
      changeStyle({ width: format(newWidth) })
    }
  }

  return (
    <>
      <div className="ImageSizeAbsolute">
        <TextField
          label="Width"
          type="number"
          value={width}
          onChange={onChangeWidth}
          autoComplete="off"
          suffix="px"
          readOnly={false}
        />
        <TextField
          label="Height"
          type="number"
          value={height}
          onChange={onChangeHeight}
          autoComplete="off"
          suffix="px"
          readOnly={false}
        />
        { locked && <button><img src={Lock} alt="Unlock" onClick={() => toggleLock()} /></button> }
        { !locked && <button><img src={Unlock} alt="Lock" onClick={() => toggleLock()} /></button> }
      </div>
    </>
  )
}

export default ImageSizeAbsolute
