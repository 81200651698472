import { Card } from '@shopify/polaris'
import { Field } from 'redux-form'
import { required } from 'redux-form-validators'
import CheckBoxInput from '../../../../../../components/Fields/CheckBoxInput'

const mustBeChecked = (value) =>
  value ? undefined : 'Please confirm that you understand this warning.'

function DontEditWarningSection() {
  return (
    <Card title="Warning!" sectioned>
      <p>
        Please do not edit the product listing in Shopify while the test is
        running. Doing so may cause errors. If you wish to edit Variant B,
        please do it in the test itself. If you wish to edit the original
        variant, please finish or delete the test first.
      </p>
      <Field
        name="dont_edit_warning"
        component={CheckBoxInput}
        label="I understand"
        uncheckedValue={false}
          validate={[required({ message: 'Please confirm that you understand this warning.' }), mustBeChecked]}
      />
    </Card>
  )
}

export default DontEditWarningSection
