import React, { createContext, useState } from 'react'

// To Do: rename and fix
// Create the context
export const ModalContext = createContext()

// Create a provider component to wrap the components that need access to the context
export const ModalProvider = ({ children }) => {
  const [showHTMLModal, setShowHTMLModal] = useState(false)

  return (
    <ModalContext.Provider value={{ showHTMLModal, setShowHTMLModal }}>
      {children}
    </ModalContext.Provider>
  )
}
