import Resizer from 'react-image-file-resizer'

class ImageParsing {
  constructor(file) {
    this.file = file
  }

  parse() {
    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        this.file,
        1024,
        1024,
        'JPEG',
        100,
        0,
        (uri) => {
          resolve(uri)
        },
        'blob'
      )
    })
  }
}
export default ImageParsing
