import './style.scss'

function ContentContainer({ pageRef, contentTest, urlPath, setUrlPath, onLoad, deviceType }) {
  const id = 'content_test_editor_iframe'
  const title = 'Content Editor'

  if (!contentTest) return <></>

  return (
    <div className="ContentContainer">
      <iframe
        className={deviceType === 'desktop' ? 'DesktopFrame' : deviceType === 'tablet' ? 'TabletFrame' : 'MobileFrame'}
        ref={pageRef}
        id={id}
        name={id}
        title={title}
        sandbox="allow-scripts allow-same-origin"
        src={`${contentTest.storeURLForIframe}?path_url=${urlPath}`}
        onLoad={onLoad}
      />
    </div>
  )
}

export default ContentContainer
