import { useState } from 'react'
import PlanFooterButtons from './components/PlanFooterButtons/PlanFooterButtons'
import PlanPrice from './components/PlanPrice/PlanPrice'
import PlanSpecs from './components/PlanSpecs'
import PlanTitle from './components/PlanTitle'
import './styles.scss'
import moment from 'moment'
import PrePaymentWarning from './components/PrePaymentWarning/PrePaymentWarning'
import FreeWarningModal from './components/FreeWarningModal/FreeWarningModal'

function Plan({ pricingPlan, shop, changePlan, setWarning }) {
  const [showWarning, setShowWarning] = useState(false)
  const checkWarningBeforeChange = (_pricingPlan) => {
    if (pricingPlan.name === 'Free') {
      const isTrialExpired = () => {
        const isTrialStarted = () => !!shop.trialStartedAt
        const trialExpiration = () => moment(shop.trialStartedAt).add(5, 'days') //To Do: check hard-code
        const now = () => moment()

        return isTrialStarted() && trialExpiration().isBefore(now())
      }
      if (!isTrialExpired()) {
        setShowWarning(true)
      }
      else {
        changePlan(pricingPlan.name)
      }
    }
    else {
      if (shop.pricingPlan) {
        changePlan(pricingPlan.name)
        return
      }

      setWarning(
        <PrePaymentWarning
          continueToPayment={() => {
            changePlan(pricingPlan.name)
          }}
        />
      )
    }
  }

  return (
    <div key={pricingPlan.title} className="Plan" style={{ border : shop.pricingPlan === pricingPlan.name ? '2px solid rgb(0,128,96)' : 'none' }}>
      <div className="block-header">
        <PlanTitle pricingPlan={pricingPlan} />
      </div>
      <div className="block-body">
        <PlanPrice pricingPlan={pricingPlan}/>
        <PlanSpecs pricingPlan={pricingPlan} />
      </div>
      <div className="block-footer">
        <PlanFooterButtons
          pricingPlan={pricingPlan}
          shop={shop}
          changePlan={checkWarningBeforeChange}
        />
      </div>
      <FreeWarningModal
        showWarning={showWarning}
        setShowWarning={setShowWarning}
        pricingPlan={pricingPlan}
        changePlan={changePlan}
      />
    </div>
  )
}
export default Plan
