function PlanPriceExtraInfo({ pricingPlan: { priceExtraInfo } }) {
  if (!priceExtraInfo) return <></>

  return (
    <div className="PlanPriceExtraInfo">
      { priceExtraInfo }
    </div>
  )
}

export default PlanPriceExtraInfo
