import { useEffect } from 'react'
import { Redirect } from '@shopify/app-bridge/actions'
import { Loading, useAppBridge, useNavigate } from '@shopify/app-bridge-react'
import SupportLink from '../../lib/SupportLink'
import { useGetShop } from '../../hooks/requests'
import { useSelector } from 'react-redux'

const SupportPage = () => {
    const app = useAppBridge()
    const redirect = Redirect.create(app)
    const navigate = useNavigate()

    const [getShop] = useGetShop()
    const shop = useSelector(state => state.shop && state.shop.shop || null)

    useEffect(() => {
      getShop()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        redirect.dispatch(Redirect.Action.REMOTE, {
            url: SupportLink().getSupport(shop),
            newContext: true,
        })
    }, [redirect, shop])

    useEffect(() => {
        navigate('/')
    }, [navigate])

    return (
      <Loading />
    )
}

export default SupportPage
