import { Page, Card, TextContainer, Link, TextField, InlineError, Thumbnail, Button, ResourceItem, ResourceList, TextStyle, Stack } from '@shopify/polaris'
import { ResourcePicker, useAppBridge } from '@shopify/app-bridge-react'
import React, { useState, useCallback } from 'react'
import SupportLink from '../../../../lib/SupportLink'
import NoProductsAccess from '../../../../components/NoAccess/NoProductsAccessModal'
import { Error } from '@shopify/app-bridge/actions'

export default function Step1({ formData, setFormData, validationErrors, setValidationErrors, touchedName, setTouchedName }) {
  const [outSideClick, setOutSideClick] = useState(true)
  const [productsAccess, setProductsAccess] = useState(false)
  const [testName, setTestName] = useState(formData.name)
  const [products, setProducts] = useState(formData.bulk_change.products || [])
  const [openResourcePicker, openResourcePickerSet] = useState(false)
  const app = useAppBridge()

  const handleChange = useCallback((newTestName) => {
    if (validationErrors.name) {
      setValidationErrors({ ...validationErrors, name: false })
    }
    setTestName(newTestName)
    setFormData({ ...formData, 'name': newTestName })
  }, [formData, setFormData, setValidationErrors, validationErrors])

  const onSelection = useCallback((data) => {
    openResourcePickerSet(false)
    const products = filterSelection(data.selection)
    setProducts(products)
    setFormData({ ...formData, 'bulk_change': { ...formData.bulk_change, 'products': products } })

    if (data.selection && validationErrors.products) {
      setValidationErrors({ ...validationErrors, products: false })
    }
  }, [formData, setFormData, setValidationErrors, validationErrors])

  const selectProducts = async () => {
    app.subscribe(Error.Action.PERMISSION, (data) => {
      if (data.message === 'Action is not permitted') {
        setProductsAccess(true)
        openResourcePickerSet(false)
      }
    })
    if (!productsAccess)
    openResourcePickerSet(true)
  }

  const onCancel = () => {
    openResourcePickerSet(false)
  }

  function filterSelection(selection) {
    const TRIDENT_LOGO = 'https://cdn.shopify.com/app-store/listing_images/a9ed5b1cd16d7dc609c1a4361115e818/icon/CMeH397MnPICEAE=.jpeg?height=120&quality=90&width=120%202x,%20https://cdn.shopify.com/app-store/listing_images/a9ed5b1cd16d7dc609c1a4361115e818/icon/CMeH397MnPICEAE=.jpeg?height=60&quality=90&width=60'

    return selection.map((product) => {
      console.log(variants())
      return {
        id: product.id.replace('gid://shopify/Product/', ''),
        title: product.title,
        sample_image: product.images.length > 0 ? product.images[0].originalSrc : TRIDENT_LOGO,
        handle: product.handle,
        variants: variants()
      }
      function variants() {
        return product.variants.map((variant) => {
          return {
            ...variant,
            id: variant.id.replace('gid://shopify/ProductVariant/', '')
          }
        })
      }
    })
  }

  return (
    <Page fullWidth>
      <Stack spacing="loose" distribution="fill" vertical>
        <div style={{ padding: '0 0.75rem' }}>
          <TextContainer spacing="loose">
            <div className="title" style={{ fontSize: '28px', fontWeight: 600, lineHeight: '32px' }}>Set up your Bulk A/B test</div>
            <p>Setting up your A/B test is simple. To get started, give your test a name (your visitors won’t see this name) and then choose <strong>which product</strong> you’d like to improve with your test.</p>
            <p>For more detailed information on how to set up your test, refer to the <Link external url={ new SupportLink().getInstructions()}>test instructions</Link></p>
          </TextContainer>
        </div>
        <div style={{ width: '100%' }}>
          <Card sectioned>
            <TextField
              label="Give a name to your test"
              value={testName}
              onChange={handleChange}
              onBlur={(event) => {
                !testName && setValidationErrors({ ...validationErrors, name: true })
                setOutSideClick(true)
              }}
              onFocus={(event) => {
                setTouchedName(true)
                setOutSideClick(false)
              }}
              error={outSideClick && touchedName && validationErrors.name ? 'Test name is required.' : null}
              autoComplete="off"
            />
          </Card>
        </div>
        <div style={{ width: '100%' }}>
          <Card>
            <div style={{ padding: '20px', display: 'flex', justifyContent: 'space-between' }}>
              <div>
                {validationErrors.products && <InlineError message="At least one product is required." fieldID="myFieldID" />}
                <ResourceList
                  resourceName={{ singular: 'product', plural: 'procuts' }}
                  items={products}
                  renderItem={(item) => {
                    const { id, sample_image, title } = item
                    const media = <Thumbnail
                      source={sample_image} />
                    return (
                      <ResourceItem
                        id={id}
                        media={media}
                        accessibilityLabel={`View details for ${title}`}
                      >
                        <h3>
                          <TextStyle variation="strong">{title}</TextStyle>
                        </h3>
                      </ResourceItem>
                    )
                  }}
                />
              </div>
              <div>
                <Button onClick={selectProducts}>Select Products</Button>
              </div>
            </div>
          </Card>
        </div>
      </Stack>
      <ResourcePicker
        resourceType="Product"
        initialSelectionIds={products.map((product) => { return { id: `gid://shopify/Product/${product.id}` } })}
        allowMultiple={true}
        showVariants={false}
        open={openResourcePicker}
        onSelection={onSelection}
        onCancel={onCancel}
      />
      <NoProductsAccess active={productsAccess} setActive={setProductsAccess}/>

    </Page >
  )
}
