import { Icon, Link } from '@shopify/polaris'
import { DesktopMajor, TabletMajor, MobileMajor } from '@shopify/polaris-icons'
import './style.css'

const DeviceType = ({ selectedMode, deviceType, setDeviceType }) => {

  if (selectedMode === 'interact') return <></>

  return (
    <div className="DeviceType">
      <Link onClick={() => setDeviceType('desktop')}>
        <Icon
          source={DesktopMajor}
          color={deviceType === 'desktop' ? 'base': 'subdued'}
        />
      </Link>
      <Link onClick={() => setDeviceType('tablet')}>
        <Icon
          source={TabletMajor}
          color={deviceType === 'tablet' ? 'base': 'subdued'}
        />
      </Link>
      <Link onClick={() => setDeviceType('mobile')}>
        <Icon
          source={MobileMajor}
          color={deviceType === 'mobile' ? 'base': 'subdued'}
        />
      </Link>
    </div>
  )
}

export default DeviceType
