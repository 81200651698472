import React from 'react'
import { Tooltip, TextStyle } from '@shopify/polaris'

const TextTooltiped = ({ text, toolTip }) => {
	return (<Tooltip content={toolTip}>
  <TextStyle>{text} <TextStyle variation="subdued">(?)</TextStyle></TextStyle>
		</Tooltip>)
}

export default TextTooltiped
