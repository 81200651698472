import { useHistory, useLocation } from 'react-router-dom'

function useCustomHistory() {
  let history = useHistory()
  let location = useLocation()
  return {
    location,
    push: (path) => {
      history.push(path)
    },
  }
}

export default useCustomHistory
