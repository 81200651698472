import HTMLForm from '../types/HTMLForm'
import ImageForm from '../types/ImageForm'
import TextForm from '../types/TextForm'

function SpecificForm({ changeStyle, selectedElement }) {
  const { newStyle } = selectedElement

  function isTextTag() {
    const list = [ 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'blockquote', 'dd', 'dt', 'figcaption', 'li', 'pre', 'span'] //To Do Refactor
    return list.includes(newStyle.tagName.toLowerCase())
  }

  function isImageTag() {
    console.log({ newStyle })
    return ['img'].includes(newStyle.tagName.toLowerCase())
  }

  if (isTextTag()) {
    return (
      <TextForm newStyle={newStyle} changeStyle={changeStyle} selectedElement={selectedElement} />
    )
  }

  if (isImageTag()) {
    return (
      <ImageForm newStyle={newStyle} changeStyle={changeStyle} selectedElement={selectedElement} />
    )
  }

  return <HTMLForm newStyle={newStyle} changeStyle={changeStyle} selectedElement={selectedElement} />

}

export default SpecificForm
