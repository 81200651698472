/* eslint-disable react-hooks/exhaustive-deps */
import { Spinner } from '@shopify/polaris'
import React, { useEffect } from 'react'
import './style.scss'

function ProgressBarTest({ test, check, result }) {
console.log('🚀 ~ file: ProgressBarTest.jsx ~ line 7 ~ ProgressBarTest ~ test', test)

  let timer

  useEffect(() => {
    if (test && test._id) {
      timer = setInterval(() => {
        check(test._id)
      }, 1000)
    }
    return () => {
      clearInterval(timer)
    }
  }, [test])

  let progress = result.count > 0 ? 100/result.count * result.upload : 0

  return <div className="spinner-container">
    {/* <Spinner /> */}
    <Spinner accessibilityLabel="Spinner example" size="large" />
    <div style={{ margin: '20px 0' }}>Please wait, we're currently creating your AB test 🚀...</div>
    <div className="ProgressBarTest">
      <div className="progress" style={{ width: (progress > 90 ? 90 : progress) + '%' }}></div>
      {/* <ProgressBar progress={progress} size="medium" /> */}
    </div>
  </div>
}

export default ProgressBarTest
