function SupportLink() {
  const zendeskDomain = 'https://tridentab.zendesk.com'
  return {
    help: () => `${zendeskDomain}/hc/en-us`,
    getSupport: (shop) => `${zendeskDomain}/hc/en-us/requests/new?tf_1500003460102=${shop.shopify_domain}`,
    getInstructions: () => `${zendeskDomain}/hc/en-us/articles/4402051427223-How-do-I-create-my-first-A-B-test-`,
    getSuggestionBox: () => 'https://tridentab.feedbear.com/boards/feature-requests'
  }
}

export default SupportLink
