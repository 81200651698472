import { Link } from '@shopify/polaris'

function TextModeLink({ mode, setMode }) {
  const text = (mode === 'text' ? 'Edit HTML' : 'Edit Text')
  const toggleMode = (mode === 'text' ? 'html' : 'text')

  return  (
    <Link removeUnderline={true} onClick={_event => setMode(toggleMode)}>{text}</Link>
  )
}

function TextModeLabel({ mode }) {
  const text = (mode === 'text' ? 'Text' : 'HTML')

  return (
    <label htmlFor="text">
      {text}
    </label>
  )
}

function TextMode({ mode, setMode }) {
  return (
    <div className="TextMode">
      <div className="left">
        <TextModeLabel mode={mode} />
      </div>
      <div className="right">
        <TextModeLink mode={mode} setMode={setMode} />
      </div>
    </div>
  )
}

export default TextMode
