import Details from './components/Content/components/Details/Details'
import Period from './components/Content/components/Period/Period'
import MultiStepForm from '../../components/MultiStepForm/MultiStepForm'
import { useState } from 'react'
import useContentTest from '../ContentTestEditor/hooks'
import { useParams } from 'react-router-dom'
import useCustomHistory from '../../hooks/useCustomHistory'
import UpsellModal from '../../components/Upsell/UpsellModal'
import { useChangePlan } from '../Pricing/hooks'
import { useAppBridge } from '@shopify/app-bridge-react'
import request from '../../lib/request'

const ContentTestForm = () => {
  const params = useParams()
  const [touchedName, setTouchedName] = useState(false)
  const [showUpsell, setShowUpsell] = useState(false)
  const { contentTest, dispatch, save, pause, update, shopReachedFreePlanLimitation } = useContentTest(params.id)
  const history = useCustomHistory()
  const [changePlan] = useChangePlan()
  const app = useAppBridge()

  const steps = [
    {
      label: 'Test Details',
      component: (
        <Details
          contentTest={contentTest}
          onChange={(data) => dispatch({ type: 'SET_DETAILS', data })}
          touchedName={touchedName} setTouchedName={setTouchedName}
        />
      ),
      validation: () => {
        setTouchedName(true)
        return !!contentTest.name
      }
    },
    {
      label: 'Choose Period',
      component: (
        <Period
          contentTest={contentTest}
          onChange={(data) => dispatch({ type: 'SET_PERIOD', data })}
        />
      ),
      validation: () => true
    }
  ]

  const reachedImpressionsLimit = (shop) => {
    if (shop?.pricingPlanData?.impressionLimit < 0)
      return false

    if (shop?.currentMonthImpressions < shop?.pricingPlanData?.impressionLimit )
      return false

    return true
  }

  const onSubmit = async () => {
    const reached_free_limitation = await shopReachedFreePlanLimitation()
    const shop = await request('default/shop', 'GET', null, undefined, app)
    const reached_impressions_limit = reachedImpressionsLimit(shop)

    if ((reached_free_limitation || reached_impressions_limit) && contentTest.status !== 'running') {
      setShowUpsell(true)
    }
    else {
      if (params.id) {
        await update(params.id)
        history.push('/')
      }
      else {
        const created = await save()
        history.push(`/content-test/${created._id}/editor`)
      }
    }
  }
  const pauseWithRedirect = async (redirectTo, planName) => {
    if (params.id) {
      history.push('/')
      await update(params.id)
    }
    else {
      const created = await save()
      await pause(created._id)
    }
    if (redirectTo === 'upgrade') {
      changePlan(planName)
    } else {
      history.push('/pricing')
    }
    // redirectOnCheck(redirectTo)
    console.log('pause with redirect')
  }

  return (
    <div className="ContentTestForm">
      <UpsellModal active={showUpsell} setActive={setShowUpsell} action={pauseWithRedirect} />

      <MultiStepForm list={steps} onSubmit={onSubmit} submitName={params.id ? 'Update Content Test' : 'Create Content Test'}/>
    </div>
  )
}

export default ContentTestForm
