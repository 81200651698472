const FontList = [
  {
      'value': '"SF Mono", Menlo, Consolas, Monaco, "Liberation Mono", "Lucida Console", monospace, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
      'label': 'Mono'
  },
  {
      'value': '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
      'label': 'Sans-serif'
  },
  {
      'value': '"New York", "Iowan Old Style", "Apple Garamond", Baskerville, "Times New Roman", "Droid Serif", Times, "Source Serif Pro", serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
      'label': 'Serif'
  },
  {
      'value': 'abel_n4_preview',
      'label': 'Abel'
  },
  {
      'value': 'abril_fatface_n4_preview',
      'label': 'Abril Fatface'
  },
  {
      'value': 'agmena_n4_preview',
      'label': 'Agmena'
  },
  {
      'value': 'akko_n4_preview',
      'label': 'Akko'
  },
  {
      'value': 'alegreya_n4_preview',
      'label': 'Alegreya'
  },
  {
      'value': 'alegreya_sans_n4_preview',
      'label': 'Alegreya Sans'
  },
  {
      'value': 'alfie_n4_preview',
      'label': 'Alfie'
  },
  {
      'value': 'americana_n4_preview',
      'label': 'Americana'
  },
  {
      'value': 'amiri_n4_preview',
      'label': 'Amiri'
  },
  {
      'value': 'anonymous_pro_n4_preview',
      'label': 'Anonymous Pro'
  },
  {
      'value': 'antique_olive_n4_preview',
      'label': 'Antique Olive'
  },
  {
      'value': 'arapey_n4_preview',
      'label': 'Arapey'
  },
  {
      'value': 'archivo_n4_preview',
      'label': 'Archivo'
  },
  {
      'value': 'archivo_narrow_n4_preview',
      'label': 'Archivo Narrow'
  },
  {
      'value': 'arimo_n4_preview',
      'label': 'Arimo'
  },
  {
      'value': 'armata_n4_preview',
      'label': 'Armata'
  },
  {
      'value': 'arvo_n4_preview',
      'label': 'Arvo'
  },
  {
      'value': 'asap_n4_preview',
      'label': 'Asap'
  },
  {
      'value': 'assistant_n4_preview',
      'label': 'Assistant'
  },
  {
      'value': 'asul_n4_preview',
      'label': 'Asul'
  },
  {
      'value': 'avenir_next_n4_preview',
      'label': 'Avenir Next'
  },
  {
      'value': 'avenir_next_rounded_n4_preview',
      'label': 'Avenir Next Rounded'
  },
  {
      'value': 'azbuka_n4_preview',
      'label': 'Azbuka'
  },
  {
      'value': 'basic_commercial_n4_preview',
      'label': 'Basic Commercial'
  },
  {
      'value': 'basic_commercial_soft_rounded_n4_preview',
      'label': 'Basic Commercial Soft Rounded'
  },
  {
      'value': 'baskerville_no_2_n4_preview',
      'label': 'Baskerville No 2'
  },
  {
      'value': 'bauer_bodoni_n4_preview',
      'label': 'Bauer Bodoni'
  },
  {
      'value': 'beefcakes_n4_preview',
      'label': 'Beefcakes'
  },
  {
      'value': 'bembo_book_n4_preview',
      'label': 'Bembo Book'
  },
  {
      'value': 'bernhard_modern_n4_preview',
      'label': 'Bernhard Modern'
  },
  {
      'value': 'bio_rhyme_n4_preview',
      'label': 'Bio Rhyme'
  },
  {
      'value': 'bitter_n4_preview',
      'label': 'Bitter'
  },
  {
      'value': 'bodoni_poster_n9_preview',
      'label': 'Bodoni Poster Black'
  },
  {
      'value': 'burlingame_n4_preview',
      'label': 'Burlingame'
  },
  {
      'value': 'cabin_n4_preview',
      'label': 'Cabin'
  },
  {
      'value': 'cachet_n4_preview',
      'label': 'Cachet'
  },
  {
      'value': 'cardamon_n4_preview',
      'label': 'Cardamon'
  },
  {
      'value': 'cardo_n4_preview',
      'label': 'Cardo'
  },
  {
      'value': 'carter_sans_n4_preview',
      'label': 'Carter Sans'
  },
  {
      'value': 'caslon_bold_n4_preview',
      'label': 'Caslon Bold'
  },
  {
      'value': 'caslon_old_face_n4_preview',
      'label': 'Caslon Old Face'
  },
  {
      'value': 'catamaran_n4_preview',
      'label': 'Catamaran'
  },
  {
      'value': 'centaur_n4_preview',
      'label': 'Centaur'
  },
  {
      'value': 'century_gothic_n4_preview',
      'label': 'Century Gothic'
  },
  {
      'value': 'chivo_n4_preview',
      'label': 'Chivo'
  },
  {
      'value': 'chong_modern_n4_preview',
      'label': 'Chong Modern'
  },
  {
      'value': 'claire_news_n3_preview',
      'label': 'Claire News Light'
  },
  {
      'value': 'cooper_bt_n5_preview',
      'label': 'Cooper BT Medium'
  },
  {
      'value': 'cormorant_n4_preview',
      'label': 'Cormorant'
  },
  {
      'value': '"Courier New", Courier, monospace',
      'label': 'Courier New'
  },
  {
      'value': 'crimson_text_n4_preview',
      'label': 'Crimson Text'
  },
  {
      'value': 'din_neuzeit_grotesk_n3_preview',
      'label': 'DIN Neuzeit Grotesk Light'
  },
  {
      'value': 'din_next_n4_preview',
      'label': 'DIN Next'
  },
  {
      'value': 'din_next_slab_n4_preview',
      'label': 'DIN Next Slab'
  },
  {
      'value': 'dm_sans_n4_preview',
      'label': 'DM Sans'
  },
  {
      'value': 'daytona_n4_preview',
      'label': 'Daytona'
  },
  {
      'value': 'domine_n4_preview',
      'label': 'Domine'
  },
  {
      'value': 'dosis_n4_preview',
      'label': 'Dosis'
  },
  {
      'value': 'eczar_n4_preview',
      'label': 'Eczar'
  },
  {
      'value': 'electra_n4_preview',
      'label': 'Electra'
  },
  {
      'value': 'eurostile_next_n4_preview',
      'label': 'Eurostile Next'
  },
  {
      'value': 'ff_meta_n4_preview',
      'label': 'FF Meta'
  },
  {
      'value': 'ff_meta_serif_n4_preview',
      'label': 'FF Meta Serif'
  },
  {
      'value': 'ff_tisa_n4_preview',
      'label': 'FF Tisa'
  },
  {
      'value': 'ff_tisa_sans_n4_preview',
      'label': 'FF Tisa Sans'
  },
  {
      'value': 'ff_unit_n4_preview',
      'label': 'FF Unit'
  },
  {
      'value': 'ff_unit_rounded_n4_preview',
      'label': 'FF Unit Rounded'
  },
  {
      'value': 'ff_unit_slab_n4_preview',
      'label': 'FF Unit Slab'
  },
  {
      'value': 'fs_kim_n4_preview',
      'label': 'FS Kim'
  },
  {
      'value': 'fs_koopman_n4_preview',
      'label': 'FS Koopman'
  },
  {
      'value': 'fs_siena_n4_preview',
      'label': 'FS Siena'
  },
  {
      'value': 'fette_gotisch_n4_preview',
      'label': 'Fette Gotisch'
  },
  {
      'value': 'fira_sans_n4_preview',
      'label': 'Fira Sans'
  },
  {
      'value': 'fjalla_one_n4_preview',
      'label': 'Fjalla One'
  },
  {
      'value': 'friz_quadrata_n4_preview',
      'label': 'Friz Quadrata'
  },
  {
      'value': 'frutiger_serif_n4_preview',
      'label': 'Frutiger Serif'
  },
  {
      'value': 'futura_n4_preview',
      'label': 'Futura'
  },
  {
      'value': 'futura_black_n4_preview',
      'label': 'Futura Black'
  },
  {
      'value': 'Garamond, Baskerville, Caslon, serif',
      'label': 'Garamond'
  },
  {
      'value': 'geometric_415_n4_preview',
      'label': 'Geometric 415'
  },
  {
      'value': 'georgia_pro_n4_preview',
      'label': 'Georgia Pro'
  },
  {
      'value': 'gill_sans_nova_n4_preview',
      'label': 'Gill Sans Nova'
  },
  {
      'value': 'glegoo_n4_preview',
      'label': 'Glegoo'
  },
  {
      'value': 'goudy_old_style_n4_preview',
      'label': 'Goudy Old Style'
  },
  {
      'value': 'harmonia_sans_n4_preview',
      'label': 'Harmonia Sans'
  },
  {
      'value': 'helvetica_n4_preview',
      'label': 'Helvetica'
  },
  {
      'value': 'hope_sans_n4_preview',
      'label': 'Hope Sans'
  },
  {
      'value': 'humanist_521_n4_preview',
      'label': 'Humanist 521'
  },
  {
      'value': 'ibm_plex_sans_n4_preview',
      'label': 'IBM Plex Sans'
  },
  {
      'value': 'itc_avant_garde_gothic_n4_preview',
      'label': 'ITC Avant Garde Gothic'
  },
  {
      'value': 'itc_benguiat_n4_preview',
      'label': 'ITC Benguiat'
  },
  {
      'value': 'itc_berkeley_old_style_n4_preview',
      'label': 'ITC Berkeley Old Style'
  },
  {
      'value': 'itc_bodoni_seventytwo_n4_preview',
      'label': 'ITC Bodoni Seventytwo'
  },
  {
      'value': 'itc_bodoni_twelve_n4_preview',
      'label': 'ITC Bodoni Twelve'
  },
  {
      'value': 'itc_caslon_no_224_n4_preview',
      'label': 'ITC Caslon No 224'
  },
  {
      'value': 'itc_charter_n4_preview',
      'label': 'ITC Charter'
  },
  {
      'value': 'itc_cheltenham_n4_preview',
      'label': 'ITC Cheltenham'
  },
  {
      'value': 'itc_clearface_n4_preview',
      'label': 'ITC Clearface'
  },
  {
      'value': 'itc_conduit_n4_preview',
      'label': 'ITC Conduit'
  },
  {
      'value': 'itc_esprit_n4_preview',
      'label': 'ITC Esprit'
  },
  {
      'value': 'itc_founders_caslon_n4_preview',
      'label': 'ITC Founders Caslon'
  },
  {
      'value': 'itc_franklin_gothic_n4_preview',
      'label': 'ITC Franklin Gothic'
  },
  {
      'value': 'itc_galliard_n4_preview',
      'label': 'ITC Galliard'
  },
  {
      'value': 'itc_gamma_n4_preview',
      'label': 'ITC Gamma'
  },
  {
      'value': 'itc_goudy_sans_n4_preview',
      'label': 'ITC Goudy Sans'
  },
  {
      'value': 'itc_johnston_n5_preview',
      'label': 'ITC Johnston Medium'
  },
  {
      'value': 'itc_mendoza_roman_n4_preview',
      'label': 'ITC Mendoza Roman'
  },
  {
      'value': 'itc_modern_no_216_n5_preview',
      'label': 'ITC Modern No 216 Medium'
  },
  {
      'value': 'itc_new_baskerville_n4_preview',
      'label': 'ITC New Baskerville'
  },
  {
      'value': 'itc_new_esprit_n4_preview',
      'label': 'ITC New Esprit'
  },
  {
      'value': 'itc_new_veljovic_n4_preview',
      'label': 'ITC New Veljovic'
  },
  {
      'value': 'itc_novarese_n4_preview',
      'label': 'ITC Novarese'
  },
  {
      'value': 'itc_officina_sans_n4_preview',
      'label': 'ITC Officina Sans'
  },
  {
      'value': 'itc_officina_serif_n4_preview',
      'label': 'ITC Officina Serif'
  },
  {
      'value': 'itc_stepp_n4_preview',
      'label': 'ITC Stepp'
  },
  {
      'value': 'itc_stone_humanist_n5_preview',
      'label': 'ITC Stone Humanist Medium'
  },
  {
      'value': 'itc_stone_informal_n4_preview',
      'label': 'ITC Stone Informal'
  },
  {
      'value': 'itc_stone_sans_ii_n4_preview',
      'label': 'ITC Stone Sans II'
  },
  {
      'value': 'itc_stone_serif_n4_preview',
      'label': 'ITC Stone Serif'
  },
  {
      'value': 'itc_tapioca_n5_preview',
      'label': 'ITC Tapioca Medium'
  },
  {
      'value': 'inconsolata_n4_preview',
      'label': 'Inconsolata'
  },
  {
      'value': 'inknut_antiqua_n4_preview',
      'label': 'Inknut Antiqua'
  },
  {
      'value': 'inter_n4_preview',
      'label': 'Inter'
  },
  {
      'value': 'joanna_nova_n4_preview',
      'label': 'Joanna Nova'
  },
  {
      'value': 'joanna_sans_nova_n4_preview',
      'label': 'Joanna Sans Nova'
  },
  {
      'value': 'josefin_sans_n4_preview',
      'label': 'Josefin Sans'
  },
  {
      'value': 'josefin_slab_n4_preview',
      'label': 'Josefin Slab'
  },
  {
      'value': 'kairos_n4_preview',
      'label': 'Kairos'
  },
  {
      'value': 'kalam_n4_preview',
      'label': 'Kalam'
  },
  {
      'value': 'karla_n4_preview',
      'label': 'Karla'
  },
  {
      'value': 'kreon_n4_preview',
      'label': 'Kreon'
  },
  {
      'value': 'lato_n4_preview',
      'label': 'Lato'
  },
  {
      'value': 'laurentian_n4_preview',
      'label': 'Laurentian'
  },
  {
      'value': 'libelle_n4_preview',
      'label': 'Libelle'
  },
  {
      'value': 'libre_baskerville_n4_preview',
      'label': 'Libre Baskerville'
  },
  {
      'value': 'libre_franklin_n4_preview',
      'label': 'Libre Franklin'
  },
  {
      'value': 'linotype_didot_n4_preview',
      'label': 'Linotype Didot'
  },
  {
      'value': 'linotype_gianotten_n4_preview',
      'label': 'Linotype Gianotten'
  },
  {
      'value': 'linotype_really_n5_preview',
      'label': 'Linotype Really Medium'
  },
  {
      'value': 'linotype_syntax_serif_n4_preview',
      'label': 'Linotype Syntax Serif'
  },
  {
      'value': 'lobster_n4_preview',
      'label': 'Lobster'
  },
  {
      'value': 'lobster_two_n4_preview',
      'label': 'Lobster Two'
  },
  {
      'value': 'lora_n4_preview',
      'label': 'Lora'
  },
  {
      'value': 'lucia_n4_preview',
      'label': 'Lucia'
  },
  {
      'value': '"Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Lucida, Helvetica, Arial, sans-serif',
      'label': 'Lucida Grande'
  },
  {
      'value': 'luthersche_fraktur_n4_preview',
      'label': 'Luthersche Fraktur'
  },
  {
      'value': 'madera_n4_preview',
      'label': 'Madera'
  },
  {
      'value': 'malabar_n4_preview',
      'label': 'Malabar'
  },
  {
      'value': 'mariposa_sans_n4_preview',
      'label': 'Mariposa Sans'
  },
  {
      'value': 'maven_pro_n4_preview',
      'label': 'Maven Pro'
  },
  {
      'value': 'megrim_n4_preview',
      'label': 'Megrim'
  },
  {
      'value': 'melior_n4_preview',
      'label': 'Melior'
  },
  {
      'value': 'memphis_n5_preview',
      'label': 'Memphis Medium'
  },
  {
      'value': 'memphis_soft_rounded_n5_preview',
      'label': 'Memphis Soft Rounded Medium'
  },
  {
      'value': 'mentor_sans_n4_preview',
      'label': 'Mentor Sans'
  },
  {
      'value': 'merriweather_sans_n4_preview',
      'label': 'Merriweather Sans'
  },
  {
      'value': 'metro_nova_n4_preview',
      'label': 'Metro Nova'
  },
  {
      'value': 'modern_no_20_n4_preview',
      'label': 'Modern No 20'
  },
  {
      'value': 'Monaco, "Lucida Console", "DejaVu Sans Mono", monospace',
      'label': 'Monaco/Lucida Console'
  },
  {
      'value': 'monotype_baskerville_n4_preview',
      'label': 'Monotype Baskerville'
  },
  {
      'value': 'monotype_bodoni_n4_preview',
      'label': 'Monotype Bodoni'
  },
  {
      'value': 'monotype_century_old_style_n5_preview',
      'label': 'Monotype Century Old Style Medium'
  },
  {
      'value': 'monotype_goudy_n4_preview',
      'label': 'Monotype Goudy'
  },
  {
      'value': 'monotype_goudy_modern_n4_preview',
      'label': 'Monotype Goudy Modern'
  },
  {
      'value': 'monotype_italian_old_style_n4_preview',
      'label': 'Monotype Italian Old Style'
  },
  {
      'value': 'monotype_new_clarendon_n5_preview',
      'label': 'Monotype New Clarendon Medium'
  },
  {
      'value': 'monotype_news_gothic_n4_preview',
      'label': 'Monotype News Gothic'
  },
  {
      'value': 'monotype_sabon_n4_preview',
      'label': 'Monotype Sabon'
  },
  {
      'value': 'montserrat_n4_preview',
      'label': 'Montserrat'
  },
  {
      'value': 'mouse_memoirs_n4_preview',
      'label': 'Mouse Memoirs'
  },
  {
      'value': 'muli_n4_preview',
      'label': 'Muli'
  },
  {
      'value': 'mundo_sans_n4_preview',
      'label': 'Mundo Sans'
  },
  {
      'value': 'neo_sans_n4_preview',
      'label': 'Neo Sans'
  },
  {
      'value': 'neue_aachen_n4_preview',
      'label': 'Neue Aachen'
  },
  {
      'value': 'neue_frutiger_1450_n4_preview',
      'label': 'Neue Frutiger 1450'
  },
  {
      'value': 'neue_haas_unica_n4_preview',
      'label': 'Neue Haas Unica'
  },
  {
      'value': 'neue_plak_n4_preview',
      'label': 'Neue Plak'
  },
  {
      'value': 'neue_swift_n4_preview',
      'label': 'Neue Swift'
  },
  {
      'value': 'neuton_n4_preview',
      'label': 'Neuton'
  },
  {
      'value': 'neuzeit_office_n4_preview',
      'label': 'Neuzeit Office'
  },
  {
      'value': 'neuzeit_office_soft_rounded_n4_preview',
      'label': 'Neuzeit Office Soft Rounded'
  },
  {
      'value': 'neuzeit_s_n4_preview',
      'label': 'Neuzeit S'
  },
  {
      'value': 'new_century_schoolbook_n4_preview',
      'label': 'New Century Schoolbook'
  },
  {
      'value': 'news_702_n4_preview',
      'label': 'News 702'
  },
  {
      'value': 'news_705_n4_preview',
      'label': 'News 705'
  },
  {
      'value': 'news_cycle_n4_preview',
      'label': 'News Cycle'
  },
  {
      'value': 'news_gothic_no_2_n4_preview',
      'label': 'News Gothic No 2'
  },
  {
      'value': 'news_plantin_n4_preview',
      'label': 'News Plantin'
  },
  {
      'value': 'nobile_n4_preview',
      'label': 'Nobile'
  },
  {
      'value': 'noticia_text_n4_preview',
      'label': 'Noticia Text'
  },
  {
      'value': 'noto_serif_n4_preview',
      'label': 'Noto Serif'
  },
  {
      'value': 'nunito_n4_preview',
      'label': 'Nunito'
  },
  {
      'value': 'nunito_sans_n4_preview',
      'label': 'Nunito Sans'
  },
  {
      'value': 'old_standard_tt_n4_preview',
      'label': 'Old Standard TT'
  },
  {
      'value': 'open_sans_n4_preview',
      'label': 'Open Sans'
  },
  {
      'value': 'open_sans_condensed_n3_preview',
      'label': 'Open Sans Condensed Light'
  },
  {
      'value': 'optima_nova_n4_preview',
      'label': 'Optima nova'
  },
  {
      'value': 'oswald_n4_preview',
      'label': 'Oswald'
  },
  {
      'value': 'ovo_n4_preview',
      'label': 'Ovo'
  },
  {
      'value': 'oxygen_n4_preview',
      'label': 'Oxygen'
  },
  {
      'value': 'pmn_caecilia_n4_preview',
      'label': 'PMN Caecilia'
  },
  {
      'value': 'pt_mono_n4_preview',
      'label': 'PT Mono'
  },
  {
      'value': 'pt_sans_n4_preview',
      'label': 'PT Sans'
  },
  {
      'value': 'pt_sans_narrow_n4_preview',
      'label': 'PT Sans Narrow'
  },
  {
      'value': 'pt_serif_n4_preview',
      'label': 'PT Serif'
  },
  {
      'value': 'pacifico_n4_preview',
      'label': 'Pacifico'
  },
  {
      'value': 'Palatino, "Palatino Linotype", "Book Antiqua", serif',
      'label': 'Palatino'
  },
  {
      'value': 'parma_n4_preview',
      'label': 'Parma'
  },
  {
      'value': 'perpetua_n4_preview',
      'label': 'Perpetua'
  },
  {
      'value': 'plantin_n4_preview',
      'label': 'Plantin'
  },
  {
      'value': 'playball_n4_preview',
      'label': 'Playball'
  },
  {
      'value': 'playfair_display_n4_preview',
      'label': 'Playfair Display'
  },
  {
      'value': 'poppins_n4_preview',
      'label': 'Poppins'
  },
  {
      'value': 'prata_n4_preview',
      'label': 'Prata'
  },
  {
      'value': 'prompt_n4_preview',
      'label': 'Prompt'
  },
  {
      'value': 'proza_libre_n4_preview',
      'label': 'Proza Libre'
  },
  {
      'value': 'quantico_n4_preview',
      'label': 'Quantico'
  },
  {
      'value': 'quattrocento_n4_preview',
      'label': 'Quattrocento'
  },
  {
      'value': 'quattrocento_sans_n4_preview',
      'label': 'Quattrocento Sans'
  },
  {
      'value': 'questrial_n4_preview',
      'label': 'Questrial'
  },
  {
      'value': 'quicksand_n4_preview',
      'label': 'Quicksand'
  },
  {
      'value': 'quire_sans_n4_preview',
      'label': 'Quire Sans'
  },
  {
      'value': 'rajdhani_n4_preview',
      'label': 'Rajdhani'
  },
  {
      'value': 'raleway_n4_preview',
      'label': 'Raleway'
  },
  {
      'value': 'really_no_2_n4_preview',
      'label': 'Really No 2'
  },
  {
      'value': 'righteous_n4_preview',
      'label': 'Righteous'
  },
  {
      'value': 'roboto_n4_preview',
      'label': 'Roboto'
  },
  {
      'value': 'roboto_condensed_n4_preview',
      'label': 'Roboto Condensed'
  },
  {
      'value': 'roboto_mono_n4_preview',
      'label': 'Roboto Mono'
  },
  {
      'value': 'roboto_slab_n4_preview',
      'label': 'Roboto Slab'
  },
  {
      'value': 'rockwell_n4_preview',
      'label': 'Rockwell'
  },
  {
      'value': 'rubik_n4_preview',
      'label': 'Rubik'
  },
  {
      'value': 'sabon_next_n4_preview',
      'label': 'Sabon Next'
  },
  {
      'value': 'sackers_square_gothic_n4_preview',
      'label': 'Sackers Square Gothic'
  },
  {
      'value': 'sagrantino_n4_preview',
      'label': 'Sagrantino'
  },
  {
      'value': 'scene_n4_preview',
      'label': 'Scene'
  },
  {
      'value': 'scherzo_n4_preview',
      'label': 'Scherzo'
  },
  {
      'value': 'shadows_into_light_n4_preview',
      'label': 'Shadows Into Light'
  },
  {
      'value': 'slabo_13px_n4_preview',
      'label': 'Slabo 13px'
  },
  {
      'value': 'slate_n4_preview',
      'label': 'Slate'
  },
  {
      'value': 'smooch_n4_preview',
      'label': 'Smooch'
  },
  {
      'value': 'soho_n4_preview',
      'label': 'Soho'
  },
  {
      'value': 'soho_gothic_n4_preview',
      'label': 'Soho Gothic'
  },
  {
      'value': 'source_code_pro_n4_preview',
      'label': 'Source Code Pro'
  },
  {
      'value': 'source_sans_pro_n4_preview',
      'label': 'Source Sans Pro'
  },
  {
      'value': 'space_mono_n4_preview',
      'label': 'Space Mono'
  },
  {
      'value': 'stempel_schneidler_n4_preview',
      'label': 'Stempel Schneidler'
  },
  {
      'value': 'swiss_721_n4_preview',
      'label': 'Swiss 721'
  },
  {
      'value': 'swiss_721_rounded_n7_preview',
      'label': 'Swiss 721 Rounded Bold'
  },
  {
      'value': 'syne_n4_preview',
      'label': 'Syne'
  },
  {
      'value': 'tenor_sans_n4_preview',
      'label': 'Tenor Sans'
  },
  {
      'value': 'tiemann_n4_preview',
      'label': 'Tiemann'
  },
  {
      'value': '"Times New Roman", Times, serif',
      'label': 'Times New Roman'
  },
  {
      'value': 'tinos_n4_preview',
      'label': 'Tinos'
  },
  {
      'value': 'titillium_web_n4_preview',
      'label': 'Titillium Web'
  },
  {
      'value': 'trade_gothic_n4_preview',
      'label': 'Trade Gothic'
  },
  {
      'value': 'trade_gothic_next_n4_preview',
      'label': 'Trade Gothic Next'
  },
  {
      'value': '"Trebuchet MS", sans-serif',
      'label': 'Trebuchet MS'
  },
  {
      'value': 'twentieth_century_n4_preview',
      'label': 'Twentieth Century'
  },
  {
      'value': 'ubuntu_n4_preview',
      'label': 'Ubuntu'
  },
  {
      'value': 'unica_one_n4_preview',
      'label': 'Unica One'
  },
  {
      'value': 'univers_next_n4_preview',
      'label': 'Univers Next'
  },
  {
      'value': 'univers_next_typewriter_n4_preview',
      'label': 'Univers Next Typewriter'
  },
  {
      'value': 'unna_n4_preview',
      'label': 'Unna'
  },
  {
      'value': 'vala_n4_preview',
      'label': 'Vala'
  },
  {
      'value': 'varela_n4_preview',
      'label': 'Varela'
  },
  {
      'value': 'varela_round_n4_preview',
      'label': 'Varela Round'
  },
  {
      'value': 'verdana_pro_n4_preview',
      'label': 'Verdana Pro'
  },
  {
      'value': 'vidaloka_n4_preview',
      'label': 'Vidaloka'
  },
  {
      'value': 'volkhov_n4_preview',
      'label': 'Volkhov'
  },
  {
      'value': 'vollkorn_n4_preview',
      'label': 'Vollkorn'
  },
  {
      'value': 'waza_n4_preview',
      'label': 'Waza'
  },
  {
      'value': 'wola_n4_preview',
      'label': 'Wola'
  },
  {
      'value': 'work_sans_n4_preview',
      'label': 'Work Sans'
  },
  {
      'value': 'ysobel_n4_preview',
      'label': 'Ysobel'
  },
  {
      'value': 'zurich_n4_preview',
      'label': 'Zurich'
  },
  {
      'value': 'zurich_extended_n4_preview',
      'label': 'Zurich Extended'
  }
]

// const ShortFontList = [
//   {
//     'value': '"SF Mono", Menlo, Consolas, Monaco, "Liberation Mono", "Lucida Console", monospace, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
//     'label': 'Mono'
//   },
//   {
//       'value': '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
//       'label': 'Sans-serif'
//   },
//   {
//       'value': '"New York", "Iowan Old Style", "Apple Garamond", Baskerville, "Times New Roman", "Droid Serif", Times, "Source Serif Pro", serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
//       'label': 'Serif'
//   },
//   {
//     'value': 'ubuntu_n4_preview',
//     'label': 'Ubuntu'
//   },
//   {
//     'value': 'work_sans_n4_preview',
//     'label': 'Work Sans'
//   },
// ]

// FontList.forEach(font => console.log(font.label.split(' ').join('%20')))
export default FontList
// export default ShortFontList
