const moment = require('moment')

const defaultState = {
  item: {
    start_date: moment().toDate(),
    finish_date: moment().add(1, 'days').toDate(),
    variant_every: 30,
    variant_every_type: 'minutes',
  },
  set_winner_modal: false,
  set_paused_modal: false,
}

const test_ab = (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_TEST_AB':
      return {
        ...state,
        item: action.data,
      }
    case 'SET_WINNER_MODAL':
      return {
        ...state,
        set_winner_modal: action.data,
      }
    case 'SET_PAUSED_MODAL':
      return {
        ...state,
        set_paused_modal: action.data,
      }
    case 'SET_TEST_AB_PERFORMANCE':
      return {
        ...state,
        performance: action.data,
      }
    case 'RESET_TEST_AB':
      return defaultState
    default:
      return state
  }
}

export default test_ab
