import ImageParsing from './ImageParsing'
import ImageType from './ImageType'

class ProductImage {
  constructor(file, parsing = undefined, type = undefined) {
    this.file = file
    this.parsing = parsing || new ImageParsing(file)
    this.type = type || new ImageType(file)
  }

  async process() {
    if (!this.validate()) return

    return this.parse()
  }

  validate() {
    return this.type.validate()
  }

  async parse() {
    return this.parsing.parse()
  }
}
export default ProductImage
