import React from 'react'
import './style.scss'
import classNames from 'classnames'

function StatusTestAb({ test }) {
  let text = ''
  switch (test.status) {
    case 'completed':
      text = 'Completed'
      break
    case 'pending':
      text = 'Pending'
      break
    case 'running':
      text = 'Running'
      break
    case 'winner_assigned':
      text = 'Winner assigned'
      break
    case 'paused':
      text = 'Paused'
      break
    default:
      break
  }

  return (
    <div className={classNames('StatusTestAb', test.status)}>
      <img src={`/status_${test.status}.svg`} alt="" /> {text}
    </div>
  )
}

export default StatusTestAb
