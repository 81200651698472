import Color from '../../../../../../../../components/Color/Color'

function BackgroundColor({ newStyle, changeStyle }) {
  const changeColor = (newColor) => {
    changeStyle({ backgroundColor: newColor })
  }

  return (
    <div className="content-form-item">
      <label htmlFor="backgroundColor">Background Color</label>
      <div>
        <Color id="backgroundColor" value={newStyle.backgroundColor} setValue={changeColor} defaultValue="#ffffff" />
      </div>
    </div>
  )
}

export default BackgroundColor
