import { Select, TextField } from '@shopify/polaris'
import FontList from '../../../../fonts'

function FontGroup({ newStyle, changeStyle }) {
  return (
    <>
      <div className="content-form-item">
        <Select
          labelInline={true}
          options={FontList.map(font => font.label)} //To Do: Transform hash into array only with labels
          value={ FontList.filter(font => newStyle.fontFamily.includes(font.label))[0].label }
          onChange={fontFamily => changeStyle({ fontFamily })}
          />
      </div>
      <div className="content-form-item" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ width:'48%' }}>
          <TextField
            label="Font Weight"
            type="number"
            value={ newStyle.fontWeight }
            onChange={fontWeight => changeStyle({ fontWeight })}
            autoComplete="off"
          />
        </div>
        <div style={{ width:'48%' }}>
          <TextField
            label="Size"
            type="number"
            value={ newStyle.fontSize.replace('px', '') }
            onChange={fontSize => changeStyle({ fontSize: `${fontSize}px` })}
            autoComplete="off"
            suffix="px"
          />
        </div>
      </div>
    </>
  )
}

export default FontGroup
