import React, { useState } from 'react'
import { Pagination } from '@shopify/polaris'
import TextInput from '../../../../../../components/Fields/TextInput'
import Photos from '../Photos/Photos'
import VariantItem from '../VariantItem/VariantItem'
import { Field, formValueSelector } from 'redux-form'
import { useSelector } from 'react-redux'
import { required } from 'redux-form-validators'
import TextEditInput from '../../../../../../components/Fields/TextEditInput'
import customPagination from '../../../../../../lib/customPagination'

function SectionProduct({ section, disabled = false, onChangesInImages = () => {} }) {
  const PAGE_LIMIT = 3
  const [currentPage, setCurrentPage] = useState(0)

  const selector = formValueSelector('test_a_b')
  const product = useSelector(state => selector(state, section))

  onChangesInImages(product)

  const { paginatedItems, hasNext, hasPrevious, pageInfo } = customPagination(product?.variants, currentPage, PAGE_LIMIT)
  const handleNext = () => {
    setCurrentPage(prev => prev + 1)
  }

  const handlePrevious = () => {
    setCurrentPage(prev => prev - 1)
  }

  return (
    <>
      <div className="m-b-20">
        <Field label="Product name" component={TextInput} inputProps={{ disabled }} name="title" validate={disabled ? [] : [required({ message: 'Product name is required.' })]} />
      </div>
      <div className="m-b-20">
        <Field component={TextEditInput} name="description" multiline={4} disabled={disabled} />
      </div>
      <Field name="images" disabled={disabled} component={Photos} onChangesInImages={onChangesInImages} onChange={onChangesInImages} validate={disabled ? [] : [required()]}/>
      {
        (product && product.variants) && paginatedItems.map((_v, index) =>
          <VariantItem pageLimit={PAGE_LIMIT} currentPage={currentPage} key={_v.id} index={index} variant={_v} product={product} disabled={disabled} />
        )
      }
      <div className="custom-pagination">
        <Pagination
          hasNext={hasNext}
          hasPrevious={hasPrevious}
          onNext={handleNext}
          onPrevious={handlePrevious}
          label={pageInfo}
        />
      </div>
    </>
  )
}

export default SectionProduct
