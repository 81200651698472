import BackgroundColor from '../shared/BackgroundColor'
import ImageSource from '../shared/ImageSource/ImageSource'
import ImageSizeSlider from '../shared/ImageSizeSlider/ImageSizeSlider'
import ImageSizeAbsolute from '../shared/ImageSizeAbsolute/ImageSizeAbsolute'
import MarginGroup from '../shared/MarginGroup'
import Toggle from '../shared/Toggle'
import ImageUpload from '../shared/ImageUpload/ImageUpload'

function ImageForm({ newStyle, changeStyle, selectedElement }) {
  return (
    <div style={{ width: '100%' }}>
      <Toggle newStyle={newStyle} changeStyle={changeStyle} selectedElement={selectedElement} />
      <ImageSource selectedElement={selectedElement} changeStyle={changeStyle} />
      <ImageUpload changeStyle={changeStyle} />
      <ImageSizeSlider selectedElement={selectedElement} changeStyle={changeStyle}/>
      <ImageSizeAbsolute selectedElement={selectedElement} changeStyle={changeStyle} />
      <BackgroundColor newStyle={newStyle} changeStyle={changeStyle} />
      <MarginGroup newStyle={newStyle} changeStyle={changeStyle} />
    </div>
  )
}
export default ImageForm
