const defaultState = {
  shop: {
    pricingPlan: 'Basic',
    pricingPlanData: {
      intervalLimit: 30, // minutes
      testsLimit: 1, // max active tests in the same moment
    },
  },
}

const shop = (state = defaultState, action) => {
  switch (action.type) {
    case 'GET_SHOP':
      return {
        ...state,
        shop: action.payload,
      }
    default:
      return state
  }
}

export default shop
