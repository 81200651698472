class DashboardHelper {
  static getTime(time, status) {
    return new TestRemainingTime(time, status).buildText()
  }
}

class TestRemainingTime {
  constructor(time, status) {
    this.time = time
    this.status = status
  }

  buildText() {
    if (this.status === 'completed') return 'Ended'
    if (this.status === 'winner_assigned') return 'Ended'
    if (this.timeInDays() > 0) return this.text(this.timeInDays(), 'day')
    if (this.timeInHours() > 0) return this.text(this.timeInHours(), 'hour')
    if (this.timeInMinutes() > 0) return this.text(this.timeInMinutes(), 'minute')
    return 'Ended'
  }

  timeInDays() {
    return Math.round(this.timeInHours() / 24)
  }

  timeInHours() {
    return Math.round(this.timeInMinutes() / 60)
  }

  timeInMinutes() {
    return Math.round(this.time / 1000 / 60)
  }

  text(time, timeMeasure) {
    return `${time} ${timeMeasureGrammarNumber()} left`

    function timeMeasureGrammarNumber() {
      if (time > 1) return `${timeMeasure}s`
      return timeMeasure
    }
  }
}

module.exports = DashboardHelper

