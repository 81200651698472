import { TextField } from '@shopify/polaris'

function ColorText({ value, id, setValue }) {
  return (
    <div className="ColorText">
      <TextField value={value} onChange={(newValue) => setValue({ [id]: newValue })}/>
    </div>
  )
}

export default ColorText
