import BackgroundColor from '../shared/BackgroundColor'
import HTMLEditor from '../shared/HTMLEditor/HTMLEditor'
import MarginGroup from '../shared/MarginGroup'
import Toggle from '../shared/Toggle'

function HTMLForm({ newStyle, changeStyle, selectedElement }) {
  return (
    <div style={{ width: '100%' }}>
      <Toggle newStyle={newStyle} changeStyle={changeStyle} selectedElement={selectedElement} />
      <HTMLEditor changeStyle={changeStyle} newStyle={newStyle} />
      <BackgroundColor newStyle={newStyle} changeStyle={changeStyle} />
      <MarginGroup newStyle={newStyle} changeStyle={changeStyle} />
    </div>
  )
}
export default HTMLForm
