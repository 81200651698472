import BestPerformanceContainer from './BestPerformanceContainer'

function VariantDescription({ description, bestPerformance, testAB, dispatch }) {
  return (
    <div>
      {description}
      {bestPerformance && !testAB.winner && <BestPerformanceContainer testAB={testAB} dispatch={dispatch}></BestPerformanceContainer>}
    </div>
  )
}

export default VariantDescription
