import { Checkbox, Modal, TextContainer } from '@shopify/polaris'
import React, { useState } from 'react'

function PriceChange({ active, handleClose, handleNext }) {
  const [checked, setChecked] = useState(false)
  return (
    <Modal
      open={active}
      onClose={handleClose}
      title="Price change warning"
      primaryAction={{
        content: 'Continue',
        onAction: handleNext,
        disabled: !checked,
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: handleClose,
        },
      ]}
    >
      <Modal.Section>
        <TextContainer>
          <p>
            Please note that by testing a different price on your product, it is possible that a "Switch" between variants happens at the same time as your user has this product in their cart.
          </p>
          <p>
            While very rare, if this happens, they may end up checking out with a different price than what they saw initially. Use this function carefully and reach out to our support team if you have any questions.
          </p>
          <Checkbox
            label="I understand and would like to test a price change"
            checked={checked}
            onChange={() => {
              setChecked(!checked)
            }}
          />
        </TextContainer>
      </Modal.Section>
    </Modal>
  )
}

export default PriceChange
