import { Grid, Stack } from '@shopify/polaris'
import PricingDiscountVector from '../PricingDiscountVector/PricingDiscountVector'
import PricingMode from '../PricingMode/PricingMode'
import './PricingHeader.scss'

function PricingHeader({ selectedMode, setSelectedMode }) {
  return (
    <div className="PricingHeader" style={{  marginBottom: '1rem' }}>
      <Grid>
        <Grid.Cell columnSpan= {{ xs: 6, sm: 6, md: 5, lg: 10, xl: 10 }}>
          <Stack vertical spacing="loose">
            <div className="text-28">
              Create A/B tests and skyrocket your business
            </div>
            <div className="text-14">
              Select a plan that fits your store.
            </div>
            <PricingMode selectedMode={selectedMode} setSelectedMode={setSelectedMode}/>
          </Stack>
        </Grid.Cell>
        <Grid.Cell columnSpan= {{ xs: 6, sm: 6, md: 1, lg: 2, xl: 2 }}>
          <PricingDiscountVector selectedMode={selectedMode}/>
        </Grid.Cell>
      </Grid>
    </div>
  )
}

export default PricingHeader
