import { Modal } from '@shopify/polaris'
import { useContext, useRef } from 'react'
import ContentForm from './components/ContentForm/ContentForm'
import { ModalContext } from '../../contexts/ModalContext'
import './styles.scss'
import HTMLModal from '../HTMLModal/HTMLModal'

function Form({ selectedElement, setSelectedElement, changeElementInThePage, dispatch, urlPath }) {
  const formRef = useRef(null)
  const { showHTMLModal, setShowHTMLModal } = useContext(ModalContext)
  const showModal = !!selectedElement

  const clear = () => changeElementInThePage(undefined, false)

  function onSubmitForm() {
    dispatch({ type: 'CHANGE_ELEMENT', data: { ...selectedElement, urlPath } })
    changeElementInThePage(undefined, true)
  }

  const onStyleChange = ({ newStyle }) => {
    changeElementInThePage({ ...selectedElement, newStyle })
  }

  function onCloseModal() {
    formRef.current && formRef.current.handleCancel()
  }

  if (!showModal) return <></>

  return (
    <>
      <Modal
        open={showModal}
        onClose={onCloseModal}
        title={selectedElement.initialStyle.tagName}
      >
        <ContentForm ref={formRef} selectedElement={selectedElement} setSelectedElement={setSelectedElement} onSave={onSubmitForm} onCancel={clear} onStyleChange={onStyleChange} />
      </Modal>
      <HTMLModal showHTMLModal={showHTMLModal} setShowHTMLModal={setShowHTMLModal} selectedElement={selectedElement} setSelectedElement={setSelectedElement} />
    </>
  )
}

export default Form
