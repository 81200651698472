import { Modal, TextContainer } from '@shopify/polaris'

function FreeWarningModal({ showWarning, setShowWarning, changePlan, pricingPlan }) {
  return (
    <Modal
        open={showWarning}
        onClose={() => setShowWarning(false)}
        title="Make A/B test for better business"
        primaryAction={{
          content: 'Go back',
          onAction: () => setShowWarning(false),
        }}
        secondaryActions={[
          {
            content: 'Continue with Free',
            onAction: () => {
              changePlan(pricingPlan.name)
              setShowWarning(false)
            },
          },
        ]}
      >
      <Modal.Section>
        <TextContainer>
          <div className="price-modal-test">
            <p>By selecting our Free Plan, you’ll be passing on your trial opportunity. Are you sure you want to continue with the Free Plan?</p>
            <p>PS. If you start a trial instead, you won’t be charged if you switch your plan to Free within 5 days.</p>
          </div>
        </TextContainer>
      </Modal.Section>
    </Modal>
  )
}

export default FreeWarningModal
