import { Label, InlineError } from '@shopify/polaris'
import React, { useState } from 'react'
import DateInput from '../../../../../../components/Fields/DateInput'
import DateTimeField from '../../../../../../components/Fields/DateTime'
import moment from 'moment'
import { useEffect } from 'react'

function DateTime({ label1, label2, input, disableDatesAfter, disableDatesBefore, handleChange, meta: { error, touched }, dateValue }) {

  const getDateValue = () => {

    if (dateValue) return moment(dateValue)
    if (moment(input.value).isValid()) return moment(input.value)

    return moment()
  }

  const getTimeValue = () => {

    if (dateValue) return moment(dateValue)
    if (moment(input.value).isValid()) return moment(input.value)

    return moment().add(1, 'hours')
  }
  const [date, setDate] = useState(getDateValue().format('YYYY-MM-DD'))
  const [time, setTime] = useState(getTimeValue().format('HH:mm'))

  useEffect(() => {
    input.onChange(moment(date + ' ' + time).toDate())
    if (handleChange) {
      handleChange(moment(date + ' ' + time).toDate())
    }
    input.onBlur()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, time])

  return (
    <>
      <div className="col-2">
        <Label>{label1}</Label>
        <DateInput
          dateProps={{
            disableDatesAfter,
            disableDatesBefore,
          }}
          input={{
            value: date,
            onChange: setDate,
          }} />
        {touched && error && <div style={{ marginTop: 10 }}><InlineError message={error}/></div>}
        {/* <TextField label={label1} value={''} onChange={() => {}} /> */}
      </div>
      <div className="col-2">
        <Label>{label2}</Label>
        <DateTimeField
           input={{
            value: time,
            onChange: setTime,
          }}
        />
        {/* <Select label={label2} value={time} onChange={setTime} options={options} /> */}
      </div>
    </>
  )
}

export default DateTime
