import BackgroundColor from '../shared/BackgroundColor'
import FontGroup from '../shared/FontGroup'
import MarginGroup from '../shared/MarginGroup'
import Text from '../shared/Text/Text'
import TextColor from '../shared/TextColor'
import Toggle from '../shared/Toggle'

function TextForm({ newStyle, changeStyle, selectedElement }) {
  return (
    <div style={{ width: '100%' }}>
      <Toggle newStyle={newStyle} changeStyle={changeStyle} selectedElement={selectedElement} />
      <Text changeStyle={changeStyle} newStyle={newStyle} />
      <FontGroup newStyle={newStyle} changeStyle={changeStyle}/>
      <TextColor newStyle={newStyle} changeStyle={changeStyle}/>
      <BackgroundColor newStyle={newStyle} changeStyle={changeStyle} />
      <MarginGroup newStyle={newStyle} changeStyle={changeStyle} />
    </div>
  )
}
export default TextForm
