import { Spinner } from '@shopify/polaris'
import React, { useState } from 'react'
import ProductImage from './image/ProductImage'
import './style.scss'

function AddImage({ onChange, value, changeVariantId, closeModal }) {

  const [loaded, setLoaded] = useState(true)

  const onChangeImg = async (event) => {
    event.preventDefault()
    setLoaded(false)
    let reader = new FileReader()
    let file = event.target.files[0]

    const productImage = await new ProductImage(file).process()

    if (!productImage) {
      console.log('Invalid file!')
      // add error message
      setLoaded(true)
      return false
    }

    reader.onloadend = () => {
      let img = new Image()
      img.onload = () => {
        setLoaded(true)
        onChange([ ...value, {
          status: 'create',
          src: reader.result,
          product_id: '',
          id: Date.now(),
          variant_ids: changeVariantId ? [changeVariantId] : [],
          position: Math.max(...[0, ...value.map(_i => _i.position)]) + 1,
        }])
        if (changeVariantId && typeof closeModal == 'function') {
          closeModal()
        }
      }
      img.src = reader.result
    }
    reader.readAsDataURL(productImage)
  }

  return (
    <div className="AddImage">
      <div className="photo-item">
        {!loaded && <Spinner />}
        {loaded && <label className="link">Add new
          <input style={{ display: 'none' }} type="file" onChange={onChangeImg} />
        </label>}
      </div>
    </div>
  )
}

export default AddImage
