import { useState } from 'react'
import { useAppBridge } from '@shopify/app-bridge-react'
import request from '../../lib/request'
import orderBy from 'lodash/orderBy'
import { isEmpty } from 'lodash'

const defaultState = {
  loaded: false,
  data: [],
  list: [],
  search: '',
  status: [],
  sort: 'name|asc',
}

export function useTestsAB() {
  const [state, setState] = useState(defaultState)
  const app = useAppBridge()

  function changeState(params) {
    const newState = { ...state, ...params }
    let list = newState.data

    if (newState.search) {
      list = list.filter(item =>
        item.name.toLowerCase().indexOf(newState.search.toLowerCase()) > -1
        || item.test_a.title.toLowerCase().indexOf(newState.search.toLowerCase()) > -1
      )
    }
    if (!isEmpty(newState.status)) {
      list = list.filter(item =>
        newState.status.includes(item.status)
      )
    }

    const [field, filter] = newState.sort.split('|')

    list = orderBy(list, [field], [filter])

    setState({ ...newState, list })
  }

  const setSearch = (search) => {
    changeState({
      search,
    })
  }
  const setStatus = (status) => {
    changeState({
      status,
    })
  }

  const setSort = (sort) => {
    changeState({
      sort,
    })
  }

  const getTests = async () => {
    try {
      setState({ ...defaultState, loaded: false, list: [] })
      let list = await request('test', 'GET', undefined, undefined, app)
      list = list.map(testAb => ({ ...testAb, product_title: productTitle({ testAb }), time: new Date(testAb.finish_date) - new Date() }))
      changeState({
        loaded: true, list, data: list,
      })
    } catch (error) {
      setState({ ...defaultState, loaded: true, list: [] })
    }
  }

  const getChildrenTests = async (parentTestAbId) => {
    try {
      setState({ ...defaultState, loaded: false, list: [], parentBulkTest: undefined })
      const response = await request(`test/${parentTestAbId}/bulk_children_tests`, 'GET', undefined, undefined, app)
      let list = response.tests.map(testAb => ({ ...testAb, product_title: productTitle({ testAb }), time: new Date(testAb.finish_date) - new Date() }))
      changeState({
        loaded: true, list, data: list, parentBulkTest: response.parentBulkTest,
      })
    } catch (error) {
      setState({ ...defaultState, loaded: true, list: [], parentBulkTest: undefined })
    }
  }

  function productTitle({ testAb }) {
    if (testAb.parent_on_bulk) return testAb.name
    return testAb.test_a.title
  }

  const deleteTest = async (id) => {
    try {
      setState({ ...state, loaded: false })
      await request('test/' + id, 'DELETE', undefined, undefined, app)

      const list = state.list.filter(_i => _i._id !== id)
      const data = state.data.filter(_i => _i._id !== id)

      changeState({
        loaded: true, list, data,
      })
    } catch (error) {
      setState({ ...state, loaded: true })
    }
  }

  const winnerTest = async ({ id, winner }) => {
    try {
      setState({ ...state, loaded: false })
      const data = await request(`test/${id}/winner/${winner}`, 'post', {}, undefined, app)
      const list = state.list.map(item => item._id === id ? { ...item, ...data } : { ...item })
      setState({ ...state, loaded: true, list })
    } catch (error) {
      setState({ ...state, loaded: true })
    }
  }

  const pauseTest = async (id, body) => {
    try {
      setState({ ...state, loaded: false })
      const data = await request('test/pause/' + id, 'post', body, undefined, app)
      const list = state.list.map(item => item._id === id ? { ...item, ...data } : { ...item })
      setState({ ...state, loaded: true, list })
    } catch (error) {
      setState({ ...state, loaded: true })
    }
  }

  const resumeTest = async (id) => {
    try {
      setState({ ...state, loaded: false })
      const data = await request('test/resume/' + id, 'post', undefined, undefined, app)
      const list = state.list.map(item => item._id === id ? { ...item, ...data } : { ...item })
      setState({ ...state, loaded: true, list })
    } catch (error) {
      setState({ ...state, loaded: true })
    }
  }

  return [state, { getTests, setSearch, setStatus, setSort, winnerTest, pauseTest, resumeTest, deleteTest, getChildrenTests }]
}
