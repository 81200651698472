import { useContext, useEffect, useState } from 'react'
import TextField from './components/TextField'
import TextMode from './components/TextMode'
import './style.scss'
import { ModalContext } from '../../../../../../../contexts/ModalContext'

function Text({ changeStyle, newStyle }) {
  const [mode, setMode] = useState('text')
  const { setShowHTMLModal } = useContext(ModalContext)

  useEffect(() => {
    setShowHTMLModal(mode === 'html')
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode])

  return (
    <div className="Text">
      <TextMode mode={mode} setMode={setMode} />
      <TextField mode={mode} changeStyle={changeStyle} newStyle={newStyle} />
    </div>
  )
}

export default Text
