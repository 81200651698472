import { Grid } from '@shopify/polaris'
import Form from './components/Form'
import Instructions from './components/Instructions'

const Details = ({ contentTest, onChange, touchedName, setTouchedName }) => {
  return (
    <Grid>
      <Grid.Cell columnSpan={{ xs: 3, sm: 3, md: 3, lg: 3, xl: 3 }} >
        <Instructions />
      </Grid.Cell>
      <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 6, lg: 9, xl: 9 }}>
        <Form contentTest={contentTest} onChange={onChange} touchedName={touchedName} setTouchedName={setTouchedName}/>
      </Grid.Cell>
    </Grid>
  )
}

export default Details
