import './style.scss'
export default function PricingDetailing({ price, oldPrice }) {
  return (
    <div className="PricingDetailing">
      <p className="oldPrice">${ oldPrice }</p>
      <p className="newPrice">${ price }/mo</p>
      <p className="text">Cancel anytime!</p>
    </div>
  )
}
