function PlanSpecs({ pricingPlan: { specs } }) {
  return (
    <div className="body-specs">
      <ul>
        {specs.map((spec) => <li key={spec}>{spec}</li>)}
      </ul>
    </div>
  )
}

export default PlanSpecs
