import { Card, Link } from '@shopify/polaris'
import React, { useState } from 'react'
import { Field } from 'redux-form'
import { required } from 'redux-form-validators'
import TextInput from '../../../../components/Fields/TextInput'
import SupportLink from '../../../../lib/SupportLink'
import ChangeProduct from '../ChangeProduct/ChangeProduct'
import './style.scss'
import NoProductsAccess from '../../../../components/NoAccess/NoProductsAccessModal'

function FormStep1({ validateTest }) {
  const [productsAccess, setProductsAccess] = useState(false)

  return (
    <div className="FormStep1">
      <NoProductsAccess active={productsAccess} setActive={setProductsAccess}/>
      <div className="text-content">
        <div className="title">Set up your A/B test</div>
        <p> Setting up your A/B test is simple. To get started, give your test a name (your visitors won’t see this name) and then choose <strong>which product</strong> you’d like to improve with your test.</p>
        <p>For more detailed information on how to set up your test, refer to the <Link external url={ new SupportLink().getInstructions() }>test instructions</Link></p>
      </div>
      <div className="form-content">
        <Card>
          <Card.Section>
            <Field
              component={TextInput}
              name="name"
              label="Give a name to your test"
              validate={[required({ message: 'Test name is required.' })]}
            />
          </Card.Section>
        </Card>
        <Card>
          <Card.Section>
            <Field component={ChangeProduct} validateTest={validateTest} setProductsAccess={setProductsAccess} name="test_a.product_id" validate={[required()]} />
          </Card.Section>
        </Card>
      </div>
    </div>
  )
}

export default FormStep1
