import { TextField, Thumbnail } from '@shopify/polaris'
import { useCallback } from 'react'
import './style.scss'

function ImageSource({ selectedElement, changeStyle }) {

  const handleChangeSrc = useCallback(
    (newValue) => {
      changeStyle({ src: newValue })
    },
    [changeStyle],
  )

  return (
    <div className="ImageSource">
      <div className="SourceEdit">
        <TextField
          label="Image URL"
          value={selectedElement.newStyle.src}
          onChange={handleChangeSrc}
          autoComplete="off"
          multiline={4}
        />
      </div>
      <div className="Thumbnail">
        <Thumbnail
          source={selectedElement.newStyle.src}
          size="large"
          alt="Preview"
          transparent={true}
        />
      </div>
    </div>
  )
}

export default ImageSource
