function getPrices(variants) {
  return variants.map(variant => {
    const priceF = parseFloat(variant.price)
    return isNaN(priceF) ? '0.00' : priceF.toFixed(2)
  })
}

function arePricesChanged(testA, testB) {
  const pricesA = getPrices(testA.variants)
  const pricesB = getPrices(testB.variants)
  if (pricesA.length !== pricesB.length) {
    return true
  }
  return pricesA.some((pricesAItem, index) => pricesAItem !== pricesB[index])
}

module.exports = {
  arePricesChanged,
}
