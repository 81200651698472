import React, { useEffect, useState } from 'react'
import { useChangePlan } from './hooks'
import './Pricing.scss'
import PricingHeader from './components/PricingHeader/PricingHeader'
import PricingBody from './components/PricingBody/PricingBody'
import PlanChangeError from './components/PlanChangeError/PlanChangeError'
import { Banner } from '@shopify/polaris'
import ImpressionCounter from './components/ImpressionCounter/ImpressionCounter'
import { useGetShop } from '../../hooks/requests'

const Pricing = ({ shop }) => {
  const [request, { error }] = useChangePlan()
  const [getShop, { loading, data }] = useGetShop()
  const [selectedMode, setSelectedMode] = useState(shop.pricingPlan === 'Monthly' ? 'monthly' : 'yearly')
  const [warning, setWarning] = useState()

  useEffect(() => {
    getShop()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const LegacyPlanBanner = () => {
    if (shop.pricingPlan === 'Yearly' || shop.pricingPlan === 'Premium')
      return (
        <div style={{ marginBottom: '1rem' }}>
          <Banner status="warning" icon={ 'none' }>
            <p style={{ fontWeight: 700, fontSize: '16px', lineHeight: '19.2px', fontFamily: 'SF Pro Text !important' }} >You are currently on our
              Legacy plan for <span style={{ color: '#2382A3' }}>{ shop.pricingPlan === 'Yearly' ? '$134.30 per year' : '$13.99 per month' }</span> which includes unlimited impressions and unlimited concurrent tests.</p>
            { shop.pricingPlan === 'Monthly' && <p style={{ fontWeight: 400, fontSize: '14px', lineHeight: '20px', fontFamily: 'SF Pro Text !important' }}>You can upgrade to our Yearly Billing plan any time. </p> }
          </Banner>
        </div>
      )
    return null
  }

  if (warning) return warning
  return (
    <div className="Pricing">
      <LegacyPlanBanner />
      <PricingHeader selectedMode={selectedMode} setSelectedMode={setSelectedMode}/>
      <PlanChangeError error={error} />
      <PricingBody
        request={request}
        getShop={getShop}
        selectedMode={selectedMode}
        shop={shop}
        setWarning={setWarning}
      />
      {!loading && <ImpressionCounter limit={data?.pricingPlanData?.impressionLimit} left={data?.pricingPlanData?.impressionLimit - data?.currentMonthImpressions} />}
    </div>
  )
}

export default Pricing
