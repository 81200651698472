import { Grid } from '@shopify/polaris'
import Form from './components/Form'
import Instructions from './components/Instructions'
import { reduxForm } from 'redux-form'

const Period = ({ contentTest, onChange }) => {
  return (
    <Grid>
      <Grid.Cell columnSpan={{ xs: 3, sm: 3, md: 3, lg: 3, xl: 3 }} >
        <Instructions />
      </Grid.Cell>
      <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 6, lg: 9, xl: 9 }}>
        <Form contentTest={contentTest} onChange={onChange} />
      </Grid.Cell>
    </Grid>
  )
}

export default reduxForm({
  form: 'content-test-period'
})(Period)
