import TextEditor from '../../../../../../../../../../components/TextEditor/TextEditor' //ToDo fix path @

function RenderableTextEditor({ mode, changeStyle, newStyle }) {
  function onTextChange(text) {
    changeStyle({ text })
  }
  const RichTextEditor = (
    <TextEditor value={newStyle.text} onChange={onTextChange} />
  )

  const SimpleTextEditor = (
    <textarea className="ContentTestHtmlEditor" value={newStyle.text} onChange={event => onTextChange(event.target.value)} />
  )

  if (mode === 'text') return RichTextEditor

  return SimpleTextEditor
}

function TextField({ mode, changeStyle, newStyle }) {
  return (
    <div className="content-form-item">
      <RenderableTextEditor mode={mode} changeStyle={changeStyle} newStyle={newStyle} />
    </div>
  )
}

export default TextField
