import { Frame, Loading } from '@shopify/polaris'

function ContentTestLoading({ selectedMode }) {
  if (selectedMode) return <></>

  return (
    <Frame>
      <Loading />
    </Frame>
  )
}

export default ContentTestLoading
