import React, { useState } from 'react'
import { TextField } from '@shopify/polaris'

const TextInput = ({ label, style, handleChange, align, type, inputProps, input, multiline, placeholder, className, meta: { error, touched }, handleBlur, handleFocus, eventName }) => {
  const [outSideClick, setOutSideClick] = useState(true)

  return (
    <div className={className} style={style}>
      <TextField
        {...inputProps}
        label={label}
        value={input.value ? input.value.toString() : input.value}
        onChange={(event) => {
          if (handleChange) {
            handleChange(event, input)
          } else {
            input.onChange(event)
          }
        }}
        placeholder={placeholder}
        onBlur={(event) => {
          setOutSideClick(true)
          if (handleBlur) {
            handleBlur(event, input)
          }
          if (input.onBlur) {
            input.onBlur(event)
          }
        }}
        onFocus={(event) => {
          setOutSideClick(false)
          if (handleFocus) {
            handleFocus(event)
          }
        }}
        multiline={multiline}
        type={type}
        align={align}
        error={outSideClick ? touched && error : null}
      />
    </div>
  )
}

export default TextInput
