/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import CodeMirror from '@uiw/react-codemirror'
import { html } from '@codemirror/lang-html'
import { html as beautifyHtml } from 'js-beautify'

function HTMLCodeEditor({ value, onChange }) {
  const onChangeInternals = React.useCallback((value, viewUpdate) => {
    onChange(value)
  }, [])

  const prettifiedHTML = beautifyHtml(value, {
    indent_size: 2,
    indent_inner_html: true
  })

  return (
    <CodeMirror
        value={prettifiedHTML} // Use the prettified HTML content
        height="200px"
        extensions={[html()]}
        onChange={onChangeInternals}
        options={{
          scrollbarStyle: 'native'
        }}
      />
  )
}

export default HTMLCodeEditor
