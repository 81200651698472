import { Button, ButtonGroup } from '@shopify/polaris'

function ContentFormButtonGroup({ handleCancel, isNewStyleChanged, onSave }) {
  return (
    <div className="content-form-button-group">
      <ButtonGroup>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button primary disabled={!isNewStyleChanged()} onClick={onSave}>Change</Button>
      </ButtonGroup>
    </div>
  )
}

export default ContentFormButtonGroup
