import { Button, Icon } from '@shopify/polaris'
import { UndoMajor, RedoMajor, EditMinor, SelectMinor } from '@shopify/polaris-icons'
import { useEffect, useState } from 'react'

function ChangeList({ contentTest, setSelectedElement, sendElementChangeToThePage }) {

  const [isOpen, setIsOpen] = useState(false)
  const [changes, setChanges] = useState([])

  useEffect(() => {
    contentTest.changes.sort((test_a, test_b) => test_b.urlPath.localeCompare(test_a.urlPath)).map(change => defineChanges(change, setChanges))
  }, [contentTest.changes])

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const handleRedo = (el) => {
    const selectedElement = el.elementChange
    const initialStyle = el[el.changedStyle].initialStyle
    const newStyle = el[el.changedStyle].newStyle

    setChanges(prevChanges => prevChanges.map(change => {
      if (change.selector === el.selector && change.changedStyle === el.changedStyle) {
        return { ...change, isUndo: false }
      }
      return change
    }))

    sendElementChangeToThePage({
      ...selectedElement,
      ...selectedElement.newStyle[el.changedStyle] = newStyle,
      ...selectedElement.initialStyle[el.changedStyle] = initialStyle
    })

    setIsOpen(false)
  }

  const handleUndo = (el) => {
    const selectedElement = el.elementChange
    const initialStyle = el[el.changedStyle].initialStyle
    const newStyle = el[el.changedStyle].newStyle

    setChanges(prevChanges => prevChanges.map(change => {
      if (change.selector === el.selector && change.changedStyle === el.changedStyle) {
        return { ...change, isUndo: true }
      }
      return change
    }))

    sendElementChangeToThePage({
      ...selectedElement,
      ...selectedElement.newStyle[el.changedStyle] = initialStyle,
      ...selectedElement.initialStyle[el.changedStyle] = newStyle
    })

    setIsOpen(false)
  }

  const handleEdit = (el) => {
    const selectedElement = el.elementChange
    setSelectedElement(selectedElement)
    setIsOpen(false)
  }

  return (
    <div className="ChangeList Item">
      <div onClick={toggleDropdown} className="Dropdown">
        <p>Changes</p>
        <Icon source={SelectMinor} color="base"/>
      </div>
      {isOpen && (
        <div className="Dropdown-Items">
          {changes.length ? changes.map(change => (
            <div key={change.selector + change.changedStyle} className="Dropdown-Item">
              <p>Edited "{change.selector.slice(0, 25) + '...'}" <b>{change.changedStyle}</b> from <b>{change[change.changedStyle].initialStyle || 'default'}</b> to <b>{change[change.changedStyle].newStyle || 'default'}</b></p>
              <div className="Dropdown-Item-Buttons">
                <Button icon={UndoMajor} disabled={change.isUndo} onClick={() => handleUndo(change)}></Button>
                <Button icon={RedoMajor} disabled={!change.isUndo} onClick={() => handleRedo(change)}></Button>
                <Button icon={EditMinor} onClick={() => handleEdit(change)}></Button>
              </div>
            </div>
          )) : <p className="Dropdown-No-Items">All changes will appear here.</p>}
        </div>
      )}
    </div>
  )
}

const defineChanges = (change, setChanges) => {

  const initialStyle = change.initialStyle
  const newStyle = change.newStyle
  const selector = change.selector.path
  const tagName = change.initialStyle.tagName

  for (const key in initialStyle) {
      if (initialStyle[key] !== newStyle[key]) {
        setChanges(prevChanges => {
          let isPresented = false
          const updatedChanges = prevChanges.map(item => {
            if (item.selector === selector && item.changedStyle === key) {
              isPresented = true
              return { ...item, elementChange: change, [key]: { initialStyle: initialStyle[key], newStyle: newStyle[key] } }
            } else if (item.selector === selector && item.changedStyle !== key) {
              return { ...item, elementChange: change }
            }

            return item
          })

          if (!isPresented) {
            return [...updatedChanges, { isUndo: false, elementChange: change, selector, tagName, changedStyle: key, [key]: { initialStyle: initialStyle[key], newStyle: newStyle[key] } }]
          }

          return updatedChanges
        })
      }
  }
}

export default ChangeList
