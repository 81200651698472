import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

function TextEditor(props) {
  const { value, onChange } = props

  return <ReactQuill
    theme="snow"
    value={value}
    onChange={onChange}
    modules={ {
      toolbar: [
        ['bold', 'italic', 'underline'],
        [{ align: '' }, { align: 'center' }, { align: 'right' }]
      ],
    } }
  />
}

export default TextEditor
