import { Modal, TextContainer } from '@shopify/polaris'

export default function ConfirmationModal({ showConfirmation, setShowConfirmation, action, planName }) {
  return (
    <Modal
      open={showConfirmation}
      onClose={() => setShowConfirmation(false)}
      title="Confirmation"
      primaryAction={{
        content: 'Continue',
        onAction: () => {planName ? action(showConfirmation, planName) : action(showConfirmation)},
      }}
    >
      <Modal.Section>
        <TextContainer>
          Your test will be Saved, Paused and Added to your Dashboard. After selecting a plan, you will be able to Resume it from the TridentAB Dashboard.
        </TextContainer>
      </Modal.Section>
    </Modal>
  )
}
