import React from 'react'
import ModeOption from './components/ModeOption'
import Modes from '../../../../../../lib/content-test/shared/Modes'

function Mode({ selectedMode, setSelectedMode }) {
  return (
    <div className="Mode Item">
      <div className="Controller">
        <ModeOption text="Edit" value={Modes.Edit} selectedMode={selectedMode} onOptionSelection={setSelectedMode} />
        <ModeOption text="Interact" value={Modes.Interact} selectedMode={selectedMode} onOptionSelection={setSelectedMode} />
      </div>
    </div>
  )
}

export default Mode
