import Footer from './components/Footer/Footer'
import Tab from './components/Tab/Tab'
import useMultiStep from './hooks/useMultiStep'
import './style.scss'

const MultiStepForm = ({ list, onSubmit, submitName }) => {
  const multiStep = useMultiStep(list)

  return (
    <div className="MultiStepForm">
      <Tab multiStep={multiStep} />
      <div className="form-content">
        { list[multiStep.step].component }
      </div>
      <Footer multiStep={multiStep} onSubmit={onSubmit} submitName={submitName}/>
    </div>
  )
}

export default MultiStepForm
