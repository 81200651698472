import BulletIcon from './BulletIcon'
import './styles.scss'

export default function BulletList({ specs }) {
  return (
    <div className="BulletList">
      {specs.map((text) => (
        <div key={text}>
          <BulletIcon />
          <span className="text">{text}</span>
        </div>
      ))}
    </div>
  )
}
