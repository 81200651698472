import { Button, InlineError, Toast } from '@shopify/polaris'
import { useState } from 'react'
import { ResourcePicker } from '@shopify/app-bridge-react'
import { Error } from '@shopify/app-bridge/actions'
import { useAppBridge } from '@shopify/app-bridge-react'
import getDataTestFromProduct from '../../../../lib/getDataTestFromProduct'
import { getProduct } from './actions'
import { change, formValueSelector } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import './style.scss'

function ChangeProduct({ meta: { touched, error }, validateTest, setProductsAccess }) {
  const dispatch = useDispatch()
  const [warning, setWarning] = useState(false)
  const selector = formValueSelector('test_a_b')
  const testA = useSelector(state => selector(state, 'test_a'))
  const id = useSelector(state => selector(state, '_id'))
  const [openResourcePicker, openResourcePickerSet]  = useState(false)
  const app = useAppBridge()

  const onSelection = async (selectPayload) => {
    openResourcePickerSet(false)
    const productId = selectPayload.selection[0].id.replace('gid://shopify/Product/', '')
    const product = await getProduct(productId, app)
    const testData = getDataTestFromProduct(product)
    dispatch(change('test_a_b', 'test_a', testData))
    dispatch(change('test_a_b', 'test_b', testData))
    if (typeof validateTest == 'function') {
      validateTest(id, testData.handle)
    }
  }

  const onCancel = () => {
    openResourcePickerSet(false)
  }

  const changeProduct = async () => {
    app.subscribe(Error.Action.PERMISSION, (data) => {

      if (data.message === 'Action is not permitted') {
        setProductsAccess(true)
        openResourcePickerSet(false)
      }
    })
    if (id) {
      setWarning(true)
      return null
    }

    if (process.env.REACT_APP_DEBUG_MODE) {
      const product = await getProduct('4352344850480')
      const testData = getDataTestFromProduct(product)
      dispatch(change('test_a_b', 'test_a', testData))
      dispatch(change('test_a_b', 'test_b', testData))
      if (typeof validateTest == 'function') {
        validateTest(id, testData.handle)
      }
    } else {
      openResourcePickerSet(true)
    }
  }

  const toggleActive = () => {
    setWarning(false)
  }

  let imgSrc = ''
  if (testA && testA.images.length) {
    imgSrc = testA.images[0].s3_src || testA.images[0].src
  }

  return (
    <div className="ChangeProduct">
      {touched && error && <InlineError message="Product is required."/>}
      {(testA && testA.product_id) && <div className="product-content">
        <div className="img"><img src={imgSrc || '/logo512.png'} alt="" /></div>
        <div>
          <div className="title">{testA.title}</div>
          <div className="text" dangerouslySetInnerHTML={{ __html: testA.description }}/>
        </div>
      </div> || <div />}
      <Button onClick={() => changeProduct()}> {id ? 'Change product' : 'Select product'}</Button>
      <ResourcePicker
        resourceType="Product"
        allowMultiple={false}
        showVariants={false}
        open={openResourcePicker}
        onSelection={onSelection}
        onCancel={onCancel}
      />
      {warning && <Toast content="You cannot chance the target product of a test. Please create a new test to use TridentAB on your other product." onDismiss={toggleActive} />}
    </div>
  )
}

export default ChangeProduct
