import { Button, Icon } from '@shopify/polaris'
import { ChevronLeftMinor } from '@shopify/polaris-icons'
import useCustomHistory from '../../../../../hooks/useCustomHistory'

const CancelButton = () => {
  const history = useCustomHistory()

  const cancel = () => history.push('/')
  return (
    <Button onClick={cancel} size="large"><Icon source={ChevronLeftMinor} /> Cancel test</Button>
  )
}

const PreviousButton = ({ previousStep }) => {

  return (
    <Button onClick={previousStep} size="large"><Icon source={ChevronLeftMinor} /> Previous step</Button>
  )
}

const LeftButton = ({ isFirstStep, previousStep }) => {
  console.log({ isFirstStep })
  if (isFirstStep) return (
    <CancelButton />
  )

  return (
    <PreviousButton previousStep={previousStep}/>
  )
}

export default LeftButton
