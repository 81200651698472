import LeftButton from './components/LeftButton'
import RightButton from './components/RightButton'

const Footer = ({ multiStep: { nextStep, previousStep, isFirstStep, isLastStep }, onSubmit, submitName }) => {

  return <div className="form-footer">
    <LeftButton isFirstStep={isFirstStep} previousStep={previousStep}/>
    <RightButton isLastStep={isLastStep} nextStep={nextStep} onSubmit={onSubmit} submitName={submitName} />
  </div>
}

export default Footer
