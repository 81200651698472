import { Button, Card, DataTable, Icon, Spinner } from '@shopify/polaris'
import { EditMinor, MobileBackArrowMajor } from '@shopify/polaris-icons'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import StatusTestAb from '../../components/StatusTestAb/StatusTestAb'
import TextTooltiped from '../../components/Fields/TextTooltiped'
import useCustomHistory from '../../hooks/useCustomHistory'
import PauseFinishModal from '../Dashboard/components/PauseFinishModal/PauseFinishModal'
import VariantDescription from './components/TableRow/VariantDescription'

import useGetTest, { useWinnerTest } from './hooks'
import './style.scss'
import WinnerStatus from './components/WinnerStatus/WinnerStatus'

function Details({ shop }) {
  const history = useCustomHistory()
  const [{ loaded }, getTest] = useGetTest()
  const params = useParams()

  const dispatch = useDispatch()

  const [, winnerTest] = useWinnerTest()

  const testAB = useSelector(state => state.test_ab.item)
  const performance = useSelector(state => state.test_ab.performance)

  useEffect(() => {
    getTest(params.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const rows = []

  if (testAB._id && performance) {
    rows.push(tableRow({
      description: 'Variant A (Control group, original)',
      revenuesPerView: performance.variantA.revenuesPerView,
      bestPerformance: performance.variantA.bestOverallPerformance,
      stat: testAB.stat_test_a,
      shop,
      testAB,
      dispatch: dispatch,
    }))

    rows.push(tableRow({
      description: 'Variant B (Your modified listing)',
      revenuesPerView: performance.variantB.revenuesPerView,
      bestPerformance: performance.variantB.bestOverallPerformance,
      stat: testAB.stat_test_b,
      shop,
      testAB,
      dispatch: dispatch,
    }))
  }

  return (
    <div className="Details">
      <div className="header">
        <div className="block-left">
          <Button icon={<Icon source={MobileBackArrowMajor} />} onClick={() => history.push('/')}></Button>
          Back to Dashboard
        </div>
        <div className="actions">
          <Button onClick={() => { getTest(params.id) }}>Refresh analytics</Button>
          {!testAB.child_test_created_from_bulk && <Button icon={<Icon source={EditMinor} />} onClick={() => history.push('/test')}>Edit</Button>}
          <PauseFinishModal variant={'set_winner'} updateTest={winnerTest} />
        </div>
      </div>
      {!loaded && <div className="spinner-container">
        <Spinner />
      </div>}
      {loaded && <div className="title">{testAB.name} <StatusTestAb test={testAB} /></div>}
      <WinnerStatus testAB={testAB} />
      {loaded && <Card>
        <DataTable
          columnContentTypes={[
            'text',
            'numeric',
            'numeric',
            'numeric',
            'numeric',
            'numeric',
            'numeric',
          ]}
          headings={[
            'Variant',
            <TextTooltiped text="Unique user page view" toolTip="The number of unique users that has viewed your product variant. If the same user saw the same variant twice or more, they will only be counted as 1 in this column." />,
            <TextTooltiped text="Product page views" toolTip="The total number of page views that your Product Listing Variant received. This includes repeated views from the same user." />,
            <TextTooltiped text="Add to cart" toolTip="The number of times the Add to cart button was clicked on your Product Listing Variant." />,
            <TextTooltiped text="Units sold" toolTip="The number of units you have sold through this Product Listing Variant." />,
            <TextTooltiped text="Revenues" toolTip="The volume of revenues you have generated using this Product Listing Variant." />,
            <TextTooltiped text="Revenue per views" toolTip="A ratio between Product page views of this Product Listing Variant and the revenue generated by that same variant. This is a strong indicator of variant success in most cases. Larger numbers here are better." />,
          ]}
          rows={rows}
        />
      </Card>}
    </div>
  )
}

function tableRow({ description, testAB, stat, shop, bestPerformance, revenuesPerView, dispatch }) {
  return [
    <VariantDescription description={description} testAB={testAB} bestPerformance={bestPerformance} dispatch={dispatch}></VariantDescription>,
    stat.unique_views,
    stat.views,
    stat.add_to_cart,
    stat.units_sold,
    stat.revenues.toFixed(2) + ' ' + (shop && shop.currency),
    revenuesPerView,
  ]
}

export default Details
