import { Link, Modal } from '@shopify/polaris'
import React, { useState } from 'react'
import { Field } from 'redux-form'
import Photos from '../../../Photos/Photos'

function ChangeImageVariant({ changeVariantId, onChangeVariantImage, images }) {

  const [active, setActive] = useState(false)

  const handleChange = () => {
    setActive(false)
  }

  return (
    <div className="ChangeImageVariant">
      <Modal
        activator={<div className="photo-item">
          <Link onClick={() => setActive(true)}>Add new</Link>
        </div>}
        open={active}
        onClose={handleChange}
        title="Change image"
        // primaryAction={{
        //   content: 'Add Instagram',
        //   onAction: handleChange,
        // }}
        // secondaryActions={[
        //   {
        //     content: 'Learn more',
        //     onAction: handleChange,
        //   },
        // ]}
      >
        <Modal.Section>
          <Field name="images" changeVariantId={changeVariantId} component={Photos} closeModal={handleChange} onChange={onChangeVariantImage} />
        </Modal.Section>
      </Modal>
    </div>
  )
}

export default ChangeImageVariant
