import { Page, Grid, Card, Button, Stack, RadioButton, TextField } from '@shopify/polaris'
import React, { useCallback, useState } from 'react'
import SupportLink from '../../../../lib/SupportLink'

export default function Step2({ formData, setFormData }) {
  const [operation, setOperation] = useState(formData.bulk_change.operation)
  const [percentage, setPercentage] = useState(formData.bulk_change.percentage)
  const [rounding, setRounding] = useState(formData.bulk_change.rounding)

  const handleChangeOperation = useCallback(
    (_checked, newOperation) => {
      setOperation(newOperation)
      setFormData({ ...formData, 'bulk_change': { ...formData.bulk_change, 'operation': newOperation } })
    },
    [formData, setFormData]
  )
  const handleChangePercentage = useCallback((newPercentage) => {
    setPercentage(newPercentage)
    setFormData({ ...formData, 'bulk_change': { ...formData.bulk_change, 'percentage': newPercentage } })
  }, [formData, setFormData])

  const handleRoundingChange = useCallback((_checked, newRounding) => {
    setRounding(newRounding)
    setFormData({ ...formData, 'bulk_change': { ...formData.bulk_change, 'rounding': newRounding } })
  }, [formData, setFormData])

  return (
    <Page fullWidth>
      <div
        style={{
          fontSize: '28px',
          fontWeight: 600,
          lineHeight: '32px',
          marginBottom: '20px',
          padding: '0 .75rem'
        }}
      >
        Create bulk product pricing test
      </div >
      <Grid>
        <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 3, lg: 6, xl: 6 }}>
          <Card >
            <div style={{ paddingBottom: '10px', paddingTop: '0px' }}>
              <Card.Section>
                <div style={{ fontSize: '17px', fontWeight: 600, lineHeight: '32px', marginBottom: '5px' }}>Price change to test (%)</div>
                <div style={{ marginBottom: '20px', color: '#6D7175' }}>For how much (in %) should we test increasing or decreasing the product price?</div>
                <Stack>
                  <RadioButton
                    label="Increase"
                    checked={operation === 'increase'}
                    id="increase"
                    onChange={handleChangeOperation}
                  />
                  <RadioButton
                    label="Decrease"
                    id="decrease"
                    checked={operation === 'decrease'}
                    onChange={handleChangeOperation}
                  />

                </Stack>
                <div style={{ paddingTop: '10px' }}>
                  <TextField
                    prefix="%"
                    min={0}
                    value={percentage}
                    type="number"
                    onChange={handleChangePercentage}
                    autoComplete="off"
                  />
                </div>
              </Card.Section>
              <div />
            </div>
          </Card>

        </Grid.Cell>
        <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 3, lg: 6, xl: 6 }}>
          <Card>
            <Card.Section>
              <div style={{ fontSize: '17px', fontWeight: 600, lineHeight: '32px', marginBottom: '5px' }}>Price rounding (decimals)</div>
              <div style={{ marginBottom: '20px', color: '#6D7175' }}>E.g: Round Number (23.68 to 24.00). No Change (23.68) or Custom Upward (23.99)</div>
              <div style={{ display: 'flex', gap: '16px', paddingBottom: '55px' }}>
                <RadioButton
                  label="Round"
                  checked={rounding === 'round'}
                  id="round"
                  onChange={handleRoundingChange}
                />
                <RadioButton
                  label="No Change"
                  id="no_change"
                  checked={rounding === 'no_change'}
                  onChange={handleRoundingChange}
                />
                <RadioButton
                  label="Custom Upward"
                  id="custom_upward"
                  checked={rounding === 'custom_upward'}
                  onChange={handleRoundingChange}
                />
              </div>
            </Card.Section>
          </Card>

        </Grid.Cell>
      </Grid>
      <Card>
        <div style={{ display: 'flex', marginTop: '20px' }}>
          <Grid>
            <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 3, lg: 6, xl: 6 }}>
              <div style={{ display: 'flex' }}>
                <img style={{ margin: 'auto' }} src="/Thumbnail.png" alt="" />
              </div>
            </Grid.Cell>
            <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 3, lg: 6, xl: 6 }}>
              <div style={{ display: 'flex', flexDirection: 'column', fontWeight: 600, padding: '0 .75rem' }}>
                <div className="title" style={{ fontSize: '15px', lineHeight: '52px' }}>How exactly will this help me increase my sales?</div>
                <p>
                  A/B testing is proven methodology to help improve the conversion rate of your product listings. It’s not as complicated as it sounds 😊.
                </p><p>
                  This involves showing two slightly different versions of a Shopify Product Listing to your visitors and measuring which one performs better.
                </p>
                <div style={{ margin: '25px 0' }}>
                  <Button external url={ new SupportLink().getInstructions() }>Learn more</Button>
                </div>
              </div>
            </Grid.Cell>
          </Grid>
        </div>
      </Card>
    </Page >
  )
}
