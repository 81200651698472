const customPagination = (items, currentPage, pageLimit) => {
	if (!items) {
		return []
	}
	const startIndex = currentPage * pageLimit
	const endIndex = startIndex + pageLimit
	const paginatedItems = items.slice(startIndex, endIndex)

	const hasNext = endIndex < items.length
	const hasPrevious = currentPage > 0
	const totalPages = Math.ceil(items.length / pageLimit)

	return { paginatedItems, hasNext, hasPrevious, pageInfo: `${currentPage + 1} / ${totalPages}` }
}

export default customPagination
