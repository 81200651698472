import { Button, Icon } from '@shopify/polaris'
import { ChevronRightMinor } from '@shopify/polaris-icons'

const CreateButton = ({ onSubmit, submitName }) => {
  return (
    <Button primary submit size="large" onClick={() => onSubmit()}>{submitName}</Button>
  )
}

const NextButton = ({ nextStep }) => {
  return (
    <Button primary onClick={nextStep} size="large">Next step <Icon source={ChevronRightMinor} /></Button>
  )
}

const RightButton = ({ isLastStep, nextStep, onSubmit, submitName }) => {
  if (isLastStep) return (
    <CreateButton isLastStep={isLastStep} onSubmit={onSubmit} submitName={submitName} />
  )

  return (
    <NextButton isLastStep={isLastStep} nextStep={nextStep} />
  )
}

export default RightButton
