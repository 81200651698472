import { Icon, Label } from '@shopify/polaris'
import { CircleCancelMajor } from '@shopify/polaris-icons'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { change, Field } from 'redux-form'
import { addValidator, numericality, required } from 'redux-form-validators'
import TextInput from '../../../../../../components/Fields/TextInput'
import ChangeImageVariant from './components/ChangeImageVariant/ChangeImageVariant'
import './style.scss'
import optimizeShopifyImageUrl from '../../../../../../lib/optimizeShopifyImageUrl'

const compareAtPrice = addValidator({
  validator: function(options, value, allValues) {
    if (value) {
      const price = parseFloat(options.price)
      const compare_at_price = parseFloat(value)
      if (compare_at_price < price && compare_at_price !== 0) {
        return {
          defaultMessage: 'The "Compare at price" needs to be bigger than the "Price" value.',
        }
      }
    }
  },
})

function VariantItem({ pageLimit, currentPage, index, variant, product, disabled }) {
  const image = product.images.find(_i => _i.variant_ids.map(id => id.toString()).indexOf(variant.id.toString()) > -1 && (!_i.status || _i.status !== 'delete'))
  const dispatch = useDispatch()
  const deleteImg = () => {
    const data = product.images.map(_i => ({ ..._i, variant_ids: _i.variant_ids.filter(_vId => _vId !== variant.id) }))
    dispatch(change('test_a_b', 'test_b.images', data))
    variant.image_id = null
  }
  const onChangeVariantImage = (data) => {
    const image = data.find(image => image.variant_ids.includes(variant.id))
    // variant.image_id = !!image ? image.id : null
    dispatch(change('test_a_b', `test_b.variants[${index}]`, { ...variant, image_id: !!image ? image.id : null }))
  }

  useEffect(() => {
    if (parseFloat(variant.compare_at_price) === 0 && !disabled)
      variant.compare_at_price = ''
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variant.compare_at_price])

  return (
    <div className="VariantItem">
      <div className="title">Variant: {variant.title}</div>
      <div className="row">
        <div className="col-2">
          {/* <input type="text" value={variant.price} /> */}
          <Field
            component={TextInput}
            name={`variants[${index + (currentPage * pageLimit)}].price`}
            label="Price"
            type="number"
            validate={disabled ? [] : [required(), numericality()]}
          />
        </div>
        <div className="col-2">
          <Field
            component={TextInput}
            name={`variants[${ index + (currentPage * pageLimit)}].compare_at_price`}
            label="Compare at price"
            type="number"
            validate={disabled ? [] : [compareAtPrice({ price: variant.price ? variant.price : 0 })]}
          />
        </div>
      </div>
      <div className="photos">
        <Label>Photos</Label>
        <div className="photo-items">
          {image && <div className="photo-item" style={{ backgroundImage: `url(${image.s3_src || optimizeShopifyImageUrl(image.src, 80)})` }}>
            {!disabled && <div className="close" onClick={deleteImg}>
              <Icon source={CircleCancelMajor} />
            </div>}
          </div>}
          {!disabled && <ChangeImageVariant changeVariantId={variant.id} images={product.images} onChangeVariantImage={onChangeVariantImage}/>}
        </div>
      </div>
    </div>
  )
}

export default VariantItem
