import { useState } from 'react'
import { useAppBridge } from '@shopify/app-bridge-react'
import request from '../../lib/request'
import DashboardData from './lib/DashboardData'

const defaultState = {
  loaded: false,
  data: [],
  list: [],
  total: 0,
  searchParam: '',
  status: [],
  sortParams: {
    field: 'sortable_status',
    order: 'asc'
  },
  appExtension: {
    loaded: true,
    uuid: ''
  },
  showUpsell: false,
  redirectOnResume: null
}

export function useTestsAB() {
  const [state, setState] = useState(defaultState)
  const app = useAppBridge()

  function changeState(params) {
    const newState = { ...state, ...params }

    setState({ ...newState, list: new DashboardData(newState).data() })
  }

  const setSearchParam = async (searchParam) => {
    changeState({ searchParam })
    await getTests(1, searchParam)
  }

  const setStatusParam = (statusParam) => {
    changeState({ statusParam })
  }

  const setSortParams = (sortParams) => {
    changeState({ sortParams })
  }

  const setShowUpsell = (showUpsell) => {
    changeState({ showUpsell })
  }

  const getTests = async (page, searchParam) => {
    try {
      setState({ ...defaultState, loaded: false, list: [] })
      let { list, total, app_extension } = await request(`test?page=${page}&searchText=${searchParam}`, 'GET', undefined, undefined, app)
      console.log({ app_extension })
      console.log({ total })
      list = enrichDataForDashboard(list)
      changeState({
        loaded: true, list, data: list, appExtension: app_extension, total
      })
    } catch (error) {
      setState({ ...defaultState, loaded: true, list: [] })
    }
  }

  function enrichDataForDashboard(list) {
    return list.map(enrichData)

    function enrichData(test) {
      return { ...test,
        product_title: productTitle(),
        time: time(),
        type: type(),
        // sortable_status: sortableStatus(),
      }

      function time() {
        return new Date(test.finish_date) - new Date()
      }

      function productTitle() {
        if (isProductAttribute()) return test.test_a.title
        if (isBulkParent()) return test.name
        if (isContentTest()) return test.name
      }

      function type() {
        if (isProductAttribute()) return '1 product'
        if (isBulkParent()) return 'Bulk'
        if (isContentTest()) return 'Content'
      }

  //     function sortableStatus() {
  //       const sequence = { running: 0, paused: 1, pending: 2, winner_assigned: 3, completed: 4, }
  //       return sequence[test.status]
  //     }

      function isBulkParent() {
        return test.testType === 'bulk_parent'
      }

      function isProductAttribute() {
        return test.testType === 'product_attribute'
      }

      function isContentTest() {
        return test.testType === 'content'
      }
    }

  }

  const deleteTest = async (id) => {
    try {
      setState({ ...state, loaded: false })
      await request('test/' + id, 'DELETE', undefined, undefined, app)

      const list = state.list.filter(_i => _i._id !== id)
      const data = state.data.filter(_i => _i._id !== id)

      changeState({
        loaded: true, list, data,
      })
    } catch (error) {
      setState({ ...state, loaded: true })
    }
  }

  const deleteContentTest = async (id) => {
    try {
      setState({ ...state, loaded: false })
      await request('content_test/' + id, 'DELETE', undefined, undefined, app)

      const list = state.list.filter(_i => _i._id !== id)
      const data = state.data.filter(_i => _i._id !== id)

      changeState({
        loaded: true, list, data,
      })
    } catch (error) {
      setState({ ...state, loaded: true })
    }
  }

  const changeContentTestStatus = async (id, status) => {
    try {
      setState({ ...state, loaded: false })
      await request(`content_test/${id}/change_status/${status}`, 'POST', undefined, undefined, app)

      const list = state.list.map(item => {
        if (item._id !== id) return item

        return { ...item, status: status }
      })

      const data = state.data.map(item => {
        if (item._id !== id) return item

        return { ...item, status: status }
      })

      changeState({
        loaded: true, list, data,
      })
    } catch (error) {
      setState({ ...state, loaded: true })
    }
  }

  const winnerTest = async ({ id, winner }) => {
    try {
      setState({ ...state, loaded: false })
      const data = await request(`test/${id}/winner/${winner}`, 'post', {}, undefined, app)
      const list = state.list.map(item => item._id === id ? { ...item, ...data } : { ...item })
      setState({ ...state, loaded: true, list })
    } catch (error) {
      setState({ ...state, loaded: true })
    }
  }

  const pauseTest = async (id, body) => {
    try {
      setState({ ...state, loaded: false })
      const data = await request('test/pause/' + id, 'post', body, undefined, app)
      const list = state.list.map(item => item._id === id ? { ...item, ...data } : { ...item })
      setState({ ...state, loaded: true, list })
    } catch (error) {
      setState({ ...state, loaded: true })
    }
  }
  const shopReachedFreePlanLimitation = async (id) => {
    try {
      setState({ ...state, loaded: false })
      //To Do refactor: remove id
      const { reached } = await request('test/' + id + '/reached_free_plan_limitation', 'get', undefined, undefined, app)
      console.log({ reached })
      return reached
    } catch (error) {}
  }

  const reachedImpressionsLimit = (shop) => {
    if (shop?.pricingPlanData?.impressionLimit < 0)
      return false

    if (shop?.currentMonthImpressions < shop?.pricingPlanData?.impressionLimit )
      return false

    return true
  }

  const resumeTestWithPlanCheck = async (id) => {
    const shop = await request('default/shop', 'GET', null, undefined, app)
    const reached_impressions_limit = reachedImpressionsLimit(shop)

    if (await shopReachedFreePlanLimitation(id) || reached_impressions_limit) {
      setShowUpsell(true)
    }
    else {
      resumeTest(id)
    }
  }

  const resumeBulkTestWithPlanCheck = async (id) => {
    const shop = await request('default/shop', 'GET', null, undefined, app)
    const reached_impressions_limit = reachedImpressionsLimit(shop)
    if (await shopReachedFreePlanLimitation(id) || reached_impressions_limit ) {
      setShowUpsell(true)
    }
    else {
      resumeBulkTest(id)
    }
  }

  const resumeTest = async (id) => {
    try {
      setState({ ...state, loaded: false })
      const data = await request('test/resume/' + id, 'post', undefined, undefined, app)
      const list = state.list.map(item => item._id === id ? { ...item, ...data } : { ...item })
      setState({ ...state, loaded: true, list })
    } catch (error) {
      setState({ ...state, loaded: true })
    }
  }

  const resumeBulkTest = async (id) => {
    try {
      setState({ ...state, loaded: false })
      const data = await request('bulk_test/resume/' + id, 'post', undefined, undefined, app)
      const list = state.list.map(item => item._id === id ? { ...item, ...data } : { ...item })
      setState({ ...state, loaded: true, list })
    } catch (error) {
      setState({ ...state, loaded: true })
    }
  }

  const deleteBulkTest = async (id) => {
    try {
      setState({ ...state, loaded: false })
      await request('bulk_test/delete/' + id, 'post', undefined, undefined, app)
      const list = state.list.filter(_i => _i._id !== id)
      const data = state.data.filter(_i => _i._id !== id)
      changeState({
        loaded: true, list, data,
      })

    } catch (error) {
      setState({ ...state, loaded: true })
    }
  }

  const pauseBulkTest = async (id) => {
    try {
      setState({ ...state, loaded: false })
      const data = await request('bulk_test/pause/' + id, 'post', undefined, undefined, app)
      console.log('paused return', data)
      const list = state.list.map(item => item._id === id ? { ...item, ...data } : { ...item })
      setState({ ...state, loaded: true, list })
    } catch (error) {
      setState({ ...state, loaded: true })
    }
  }

  const finishBulkTest = async (id) => {
    try {
      setState({ ...state, loaded: false })
      const data = await request('bulk_test/finish/' + id, 'post', undefined, undefined, app)
      const list = state.list.map(item => item._id === id ? { ...item, ...data } : { ...item })
      setState({ ...state, loaded: true, list })
    } catch (error) {
      setState({ ...state, loaded: true })
    }
  }

  const redirectOnResume = (value) => setState({ redirectOnResume: value })

  return [state, { getTests, setSearchParam, setStatusParam, setSortParams, setShowUpsell, winnerTest, pauseTest, resumeTest, deleteTest, resumeBulkTest, deleteBulkTest, pauseBulkTest, finishBulkTest, deleteContentTest, changeContentTestStatus, redirectOnResume, resumeTestWithPlanCheck, resumeBulkTestWithPlanCheck }]
}
