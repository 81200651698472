import { useImperativeHandle, forwardRef } from 'react'
import ContentFormButtonGroup from './components/ContentFormButtonGroup/ContentFormButtonGroup'
import SpecificForm from './components/SpecificForm/SpecificForm'

// from 'react-final-form' //To Do remove dependenct if not used anywhere else
function ContentEditorForm(props, ref) {
  useImperativeHandle(ref, () => ({ handleCancel }))

  const { selectedElement, setSelectedElement, onSave, onCancel, onStyleChange } = props
  const { initialStyle, newStyle } = selectedElement

  function changeStyle(change) {
    const changedStyle = { ...newStyle, ...change }
    if (!isChanged()) return
    setSelectedElement({ ...selectedElement, newStyle: changedStyle })
    onStyleChange({ newStyle: changedStyle })

    function isChanged() {
      return JSON.stringify(changedStyle) !== JSON.stringify(newStyle)
    }
  }

  function isNewStyleChanged() {
    return JSON.stringify(initialStyle) !== JSON.stringify(newStyle)
  }

  function handleCancel() {
    if (isNewStyleChanged()) onStyleChange({ newStyle: initialStyle })

    return onCancel()
  }

  return (
    <div className="content-form">
      <div className="content-form-section">
        <SpecificForm changeStyle={changeStyle} selectedElement={selectedElement} />
      </div>
      <ContentFormButtonGroup handleCancel={handleCancel} isNewStyleChanged={isNewStyleChanged} onSave={onSave} />
    </div>
  )
}
export default forwardRef(ContentEditorForm)
