const optimizeShopifyImageUrl = (url, width) => {
	if (!url || !url.includes('https://cdn.shopify.com')) {
		return url
	}

	const newUrl = new URL(url)
	newUrl.searchParams.append('width', width)
	return newUrl
}

export default optimizeShopifyImageUrl
