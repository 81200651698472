import React, { Component } from 'react'
import { Button, Icon, Popover, TextField } from '@shopify/polaris'
import classNames from 'classnames'
import { ChevronDownMinor, ChevronUpMinor, ClockMinor } from '@shopify/polaris-icons'
import moment from 'moment'
import './DateTime.scss'

class DateTime extends Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      month: parseInt(moment().format('M'), 10) - 1,
      year: parseInt(moment().format('YYYY'), 10),
    }
    this._Ref = React.createRef()
  }

  handleClick = (event) => {
    if (this._Ref && this._Ref.current.contains(event.target)) {
      return
    }
    this.setState({ active: false })
  };

  togglePopover = () => {
    this.setState(({ active }) => {
      return { active: !active }
    })
  };

  render() {
    const { active } = this.state
    const { className } = this.props

    const time = new Time(this.props)

    const activator = (
      <div className={classNames('DateTime-Input', { active: this.state.active })} onClick={this.togglePopover}>
        <div className={classNames('Polaris-Select__SelectedOption')}>
          {time.format()}
        </div>
        <Icon color="inkLighter" source={ClockMinor} />
      </div>
    )

    return (
      <div className={classNames('DateTime', className)}>
        <Popover
          active={active}
          activator={activator}
          onClose={() => {
            this.setState({ active: false })
          }}
          preferredAlignment="left"
        >
          <div style={{ padding: 15, paddingTop: 10 }}>
            <Button fullWidth onClick={time.now}>Now</Button>
          </div>
          <div className="date-time-content">
            <div className="input-time">
              <div onClick={time.incrementHours}>
                <Icon source={ChevronUpMinor} />
              </div>
              <TextField type="text" value={time.formattedHours} onChange={(value) => time.onChangeHours(value)} min={0} max={12} inputProps={{ pattern: '[0-9]*', inputMode: 'numeric', style: { textAlign: 'center' } }} />
              <div onClick={time.decrementHours}>
                <Icon source={ChevronDownMinor} />
              </div>
            </div>
            <div className="point">:</div>
            <div className="input-time">
              <div onClick={time.incrementMinutes}>
                <Icon source={ChevronUpMinor} />
              </div>
              <TextField   type="text" value={time.formattedMinutes} onChange={(value) => time.onChangeMinutes(value)} min={0} max={59} inputProps={{ pattern: '[0-9]*', inputMode: 'numeric', style: { textAlign: 'center' } }} />
              <div onClick={time.decrementMinutes}>
                <Icon source={ChevronDownMinor} />
              </div>
            </div>
            <div className="blocks">
              <div className={classNames('block', { active: time.isAm() })} onClick={time.changeToAm}>AM</div>
              <div className={classNames('block', { active: time.isPm() })} onClick={time.changeToPm}>PM</div>
            </div>
          </div>
          <div style={{ padding: 15, paddingTop: 0 }}>
            <Button primary fullWidth onClick={() => { this.setState({ active: false }) }}>Continue</Button>
          </div>
        </Popover>
      </div>
    )
  }
}

function Time(props) {
  const { input } = props

  let [hours, minutes] = (input.value || '').split(':').map(number => parseInt(number))

  const isPm = () => hours >= 12
  const isAm = () => !isPm()
  const period = () => isPm() ? 'PM' : 'AM'

  const formattedHours = () => {
    // if (hours === 0) return 12
    if (hours === 12) return 12
    if (isPm()) return hours - 12

    return hours
  }

  const formattedMinutes = () => minutes.toString().padStart(2, '0')

  const change = (hours, minutes) => {
    input.onChange(`${hours}:${minutes}`)
  }

  const onChangeHours = (value) => {
    if (!value || isNaN(value)) {
      input.onChange(`00:${minutes}`)
      return
    }

    const intValue = parseInt(value)
    if (intValue >= 0 && intValue <= 12) {
      change(intValue, minutes)
    }
  }

  const onChangeMinutes = (value) => {
    if (!value || isNaN(value)) {
      input.onChange(`${hours}:00`)
      return
    }

    const intValue = parseInt(value)
    if (intValue >= 0 && intValue <= 59) {
      change(hours, intValue)
    }
  }

  const changeHours = (difference) => {
    if (!valid()) return
    change(hours + difference, minutes)

    function valid() {
      return hours + difference >= 0 && hours + difference <= 23
    }
  }

  const changeMinutes = (difference) => {
    if (!valid()) return
    change(hours, minutes + difference)

    function valid() {
      return minutes + difference >= 0 && minutes + difference <= 59
    }
  }

  const changeToAm = () => {
    if (isAm()) return

    change(hours - 12, minutes)
  }

  const changeToPm = () => {
    if (isPm()) return

    change(hours + 12, minutes)
  }

  const now = () => input.onChange(moment().format('HH:mm'))

  return {
    formattedHours: formattedHours(),
    formattedMinutes: formattedMinutes(),
    format: () => `${formattedHours()}:${formattedMinutes()} ${period()}`,
    incrementHours: () => changeHours(+1),
    decrementHours: () => changeHours(-1),
    incrementMinutes: () => changeMinutes(+1),
    decrementMinutes: () => changeMinutes(-1),
    onChangeHours,
    onChangeMinutes,
    isPm,
    isAm,
    changeToAm,
    changeToPm,
    now
  }
}

export default DateTime
