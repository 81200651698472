export default function BulletIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <g clipPath="url(#clip0_2709_18895)">
        <path d="M9 0C4.02943 0 0 4.02943 0 9C0 13.9706 4.02943 18 9 18C13.9706 18 18 13.9706 18 9C18 4.02943 13.9706 0 9 0ZM12.8617 4.21545L14.7315 6.08532L8.89124 11.9268L7.03234 13.7845L5.16248 11.9147L3.26842 10.0195L5.12622 8.16173L7.02027 10.0569L12.8617 4.21545Z" fill="#4CC3C1"/>
      </g>
      <defs>
        <clipPath id="clip0_2709_18895">
          <rect width="18" height="18" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}
