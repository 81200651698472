import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useAppBridge } from '@shopify/app-bridge-react'
import  request from '../../lib/request'

export default function useGetTest() {
  const [state, setState] = useState({ loaded: false })
  const app = useAppBridge()

  const dispatch = useDispatch()

  const getTest = async (id) => {
    try {
      setState({ loaded: false })
      const data = await request('test/' + id, 'GET', undefined, undefined, app)
      dispatch({ type: 'SET_TEST_AB', data: data.test })
      dispatch({ type: 'SET_TEST_AB_PERFORMANCE', data: data.performance })
      setState({ loaded: true })
    } catch (error) {
      setState({ loaded: true })
    }
  }

  return [state, getTest]
}

export function useWinnerTest() {
  const [state, setState] = useState({ loaded: false })
  const app = useAppBridge()

  const dispatch = useDispatch()

  const winnerTest = async ({ id, winner }) => {
    try {
      setState({ loaded: false })
      const data = await request(`test/${id}/winner/${winner}`, 'post', {}, undefined, app)
      dispatch({ type: 'SET_TEST_AB', data: data })
      setState({ loaded: true })
    } catch (error) {
      setState({ loaded: true })
    }
  }

  return [state, winnerTest]
}
