import { Filters, ChoiceList } from '@shopify/polaris'
import { isEmpty } from 'lodash'
import { useEffect, useState } from 'react'

const DashboardFilter = ({ search, status, setSearch, setStatus }) => {
  const [searchText, setSearchText] = useState(search)

  const handleStatusChange = (value) => setStatus(value)
  const handleStatusRemove = () => setStatus(null)

  useEffect(() => {
    const call = setTimeout(() => {
      setSearch(searchText)
    }, 700)
    return () => {
      clearTimeout(call)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText])

  const filters = [
    {
      key: 'status',
      label: 'Status',
      filter: (
        <ChoiceList
          title="Status"
          titleHidden
          choices={[
            { label: 'Running', value: 'running' },
            { label: 'Paused', value: 'paused' },
            { label: 'Pending', value: 'pending' },
            { label: 'Winner assigned', value: 'winner_assigned' },
            { label: 'Completed', value: 'completed' },
          ]}
          selected={status || []}
          onChange={handleStatusChange}
          allowMultiple
        />
      ),
      shortcut: true,
    },
  ]

  function disambiguateLabel(key, value) {
    switch (key) {
      case 'status':
        return value.map((val) => `Status ${val}`).join(', ')
      default:
        return value
    }
  }

  const appliedFilters = []
  if (!isEmpty(status)) {
    const key = 'status'
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, status),
      onRemove: handleStatusRemove,
    })
  }

  return (
    <Filters
      queryValue={searchText}
      filters={filters}
      appliedFilters={appliedFilters}
      onQueryChange={setSearchText}
      onQueryClear={()=>{setSearch(''); setSearchText('')}}
    />
  )
}

export default DashboardFilter
