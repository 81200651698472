import PlanFooterMainButton from './components/PlanFooterMainButton'

function PlanFooterButtons({ pricingPlan, shop, changePlan }) {
  return (
    <div className="footer-btns">
      <PlanFooterMainButton
        pricingPlan={pricingPlan}
        shop={shop}
        changePlan={changePlan}
      />
    </div>
  )
}

export default PlanFooterButtons
