import { Icon, InlineError, Label, Pagination } from '@shopify/polaris'
import { CircleCancelMajor } from '@shopify/polaris-icons'
import React, { useState } from 'react'
import AddImage from './components/AddImage/AddImage'
import './style.scss'
import cloneDeep from 'lodash/cloneDeep'
import optimizeShopifyImageUrl from '../../../../../../lib/optimizeShopifyImageUrl'
import customPagination from '../../../../../../lib/customPagination'

function Photos({ disabled, input: { value, onChange }, label, changeVariantId, closeModal, onChangesInImages }) {
  const PAGE_LIMIT = 8
  const [currentPage, setCurrentPage] = useState(0)

  const deleteImg = (id) => () => {
    const data = value.map(_i => {
      if (_i.id === id) {
        return _i.status === 'create' ? null : null
      }
      return _i
    }).filter(_i => !!_i)
    onChange(cloneDeep(data))
  }

  const selectedImageVariant = (id) => {
    if (!changeVariantId) {
      return
    }
    const data = value.map(_i => _i.id === id  ?
      { ..._i, status: 'update', variant_ids: [..._i.variant_ids, changeVariantId] } :
      { ..._i, variant_ids: _i.variant_ids.filter(vId => changeVariantId !== vId) }
    )

    onChange(cloneDeep(data))
    if (closeModal) {
      closeModal()
    }
  }

  const images = value.filter(_i => !_i.status || _i.status !== 'delete')
  const { paginatedItems, hasNext, hasPrevious, pageInfo } = customPagination(images, currentPage, PAGE_LIMIT)

  const handleNext = () => {
    setCurrentPage(prev => prev + 1)
  }

  const handlePrevious = () => {
    setCurrentPage(prev => prev - 1)
  }

  return (
    <div className="Photos">
      {label && <Label>{label}</Label>}
      <div className="photo-items">
        {paginatedItems.map(_i =>
          <div key={'img_product_' + _i.id} className="photo-item" onClick={() => selectedImageVariant(_i.id)} style={{ backgroundImage: `url(${_i.s3_src || optimizeShopifyImageUrl(_i.src, 80)})` }}>
            {!changeVariantId && !disabled && <div className="close" onClick={deleteImg(_i.id)}>
              <Icon source={CircleCancelMajor} />
            </div>}
          </div>
        )}
        {!disabled && <AddImage onChange={onChange} value={value} changeVariantId={changeVariantId} closeModal={closeModal} />}
      </div>
      {paginatedItems.length === 0 && <div style={{ marginBottom: '10px', marginLeft: '16px' }}><InlineError message="At least one image is required."/></div>}
      <div className="custom-pagination">
        <Pagination
          hasNext={hasNext}
          hasPrevious={hasPrevious}
          onNext={handleNext}
          onPrevious={handlePrevious}
          label={pageInfo}
        />
      </div>
    </div>
  )
}

export default Photos
