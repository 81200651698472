import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import test_ab from './test'
import shop from './shop'

export default combineReducers({
  form: formReducer,
  test_ab,
  shop,
})
