function PlanPriceHighlight({ pricingPlan: { priceHighlight } }) {
  if (!priceHighlight) return <></>

  return (
    <div className="body-duration">
      { priceHighlight }
    </div>
  )
}

export default PlanPriceHighlight
