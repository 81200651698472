import { useEffect } from 'react'
import { Redirect } from '@shopify/app-bridge/actions'
import { Loading, useAppBridge, useNavigate } from '@shopify/app-bridge-react'
import SupportLink from '../../lib/SupportLink'

const SuggestionBox = () => {
    const app = useAppBridge()
    const redirect = Redirect.create(app)
    const navigate = useNavigate()

    useEffect(() => {
        redirect.dispatch(Redirect.Action.REMOTE, {
            url: SupportLink().getSuggestionBox(),
            newContext: true,
        })
    }, [redirect])

    useEffect(() => {
        navigate('/')
    }, [navigate])

    return (
      <Loading />
    )
}

export default SuggestionBox
