const content_test_reducer = (state, action) => {
  switch (action.type) {
    case 'LOAD_DATA':
      return action.data
    case 'SET_DETAILS':
      const { name } = action.data
      return { ...state, name }
    case 'SET_PERIOD':
      const { start_date, finish_date } = action.data
      return { ...state, start_date, finish_date }
    case 'CHANGE_ELEMENT': //To Do: add update logic, now only inserts
      return changeElement(state, action)
    default:
      return state
  }
}

const changeElement = (state, action) => {
  return { ...state, changes: data() }

  function data() {
    const newChange = action.data
    const { changes } = state

    if (index() >= 0) return changeItemAtIndex(index())

    return [
      ...changes,
      { ...newChange, isNew: true }
    ]

    function changeItemAtIndex(index) {
      const newArray = [...changes]

      newArray[index] = { ...newChange, isNew: true }

      return newArray
    }

    function index() {
      return changes.findIndex(compareChanges)

      function compareChanges(change) {
        return change.selector.path === newChange.selector.path
                && change.selector.index === newChange.selector.index
                && change.urlPath === newChange.urlPath
      }
    }
  }
}

export default content_test_reducer
