import { useState } from 'react'

function useMultiStep(list) {
  const [step, setStep] = useState(0)
  const labels = list.map(item => item.label)
  const validation = list[step].validation

  const changeStep = (newStep) => {
    if (!validation()) return console.log('not valid!')

    setStep(newStep)
  }

  const nextStep = () => changeStep(step + 1)
  const previousStep = () => changeStep(step - 1)
  const isFirstStep = step === 0
  const isLastStep = step === list.length - 1

  return { step, labels, validation, nextStep, previousStep, isFirstStep, isLastStep,  setStep }
}

export default useMultiStep
