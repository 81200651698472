class ImageType {
  static TYPES = ['image/apng', 'image/avif', 'image/gif', 'image/jpeg', 'image/png', 'image/svg+xml', 'image/webp']
  constructor(file) {
    this.file = file
  }
  validate() {
    return ImageType.TYPES.includes(this.file.type)
  }
}
export default ImageType
