import React from 'react'
import { Checkbox } from '@shopify/polaris'

const CheckBoxInput = ({ label, style, handleChange, align, type, inputProps, input, multiline, placeholder, className, meta: { error, touched }, handleBlur, handleFocus, eventName, uncheckedValue= '' }) => {
  return (
    <div className={className} style={style}>
      <Checkbox
        label={label}
        checked={Boolean(input.value)}
        onChange={() => {
          input.onChange(input.value ? uncheckedValue : true)
        }}
        error={touched && error || null}
      />
    </div>
  )
}

export default CheckBoxInput
