import React from 'react'
import './style.scss'
import classNames from 'classnames'

function Tab({ selected, formValues, setTabItem }) {

  const isUpdate = Boolean(formValues && formValues._id)

  const tabs = [
    {
      title: 'Test Details',
      name: 'details',
    },
    {
      title: 'Variants',
      name: 'create_variants',
    },
    {
      title: 'Period',
      name: 'choose_period',
    },
  ]

  return (
    <div className="Tab">
      {tabs.map((item, index) => <div
        key={item.name}
        onClick={() => {
          if (isUpdate) {
            setTabItem(item.name)
          }
        }}
        style={{ cursor: isUpdate ? 'pointer' : 'default' }}
        className={classNames('item', { active: selected === item.name })}
      >
        {index + 1}. {item.title}
      </div>)}
    </div>
  )
}

export default Tab
