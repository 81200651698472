import '@shopify/polaris/build/esm/styles.css'
import './App.scss'
import { AppProvider, Frame } from '@shopify/polaris'
import { Provider as AppBridgeProvider, NavigationMenu } from '@shopify/app-bridge-react'
import useToken from './hooks/useToken'
import { Provider as SessionProvider } from './context/main'
import { useEffect } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './store'
import RouterComponent from './Router'

// const RouterComponent = lazy(() => import('./Router'))

function App() {
  const { error, token } = useToken()

  useEffect(() => {
    const loadFullStory = async () => {
      if ('requestIdleCallback' in window) {
        requestIdleCallback(async () => {
          const { default: FullStoryScript } = await import('./lib/external/FullStoryScript')
          FullStoryScript()
        })
      } else {
        // Fallback for browsers that do not support requestIdleCallback
        const { default: FullStoryScript } = await import('./lib/external/FullStoryScript')
        FullStoryScript()
      }
    }
    loadFullStory()
  }, [])

  const urlParams = new URLSearchParams(window.location.search)
  const host = urlParams.get('host') || process.env.REACT_APP_DEBUG_HOST

  if (error) return <div>Error</div>

  return (
    <div className="App">
      <SessionProvider token={token}>
        <AppProvider>
          <AppBridgeProvider config={{ apiKey: process.env.REACT_APP_API_KEY, host, forceRedirect: !Boolean(process.env.REACT_APP_DEBUG_MODE) }}>
            <Provider store={store}>
              <Router>
                <Frame>
                  <NavigationMenu
                    navigationLinks={[
                      {
                        label: 'Dashboard',
                        destination: '/'
                      },
                      {
                        label: 'Billing',
                        destination: '/pricing'
                      },
                      {
                        label: 'Help',
                        destination: '/get-help'
                      },
                      {
                        label: 'Get support',
                        destination: '/get-support'
                      },
                      {
                        label: 'Suggestion Box',
                        destination: '/suggestion-box'
                      }
                    ]}
                  />
                  <RouterComponent />
                </Frame>
              </Router>
            </Provider>
          </AppBridgeProvider>
        </AppProvider>
      </SessionProvider>
    </div>
  )
}

export default App
