import { Button } from '@shopify/polaris'

function PlanFooterMainButton({ shop, changePlan, pricingPlan }) {
  const hasThatPlan = shop.pricingPlan === pricingPlan.name

  return (
    <span>
      <Button
        disabled={hasThatPlan}
        primary={!hasThatPlan}
        onClick={() => changePlan(pricingPlan.name)}
      >
        {pricingPlan.buttons.Main(shop)}
      </Button>
    </span>
  )
}

export default PlanFooterMainButton
