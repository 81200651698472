/* eslint-disable react-hooks/rules-of-hooks */
import { Button, ChoiceList, Icon, Modal, Spinner } from '@shopify/polaris'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './style.scss'
import { CircleInformationMajor } from '@shopify/polaris-icons'

function PauseFinishModal({ updateTest = () => { }, hideAcivator = false, variant }) {
  const [spinner, setSpinner] = useState(false)
  const [selected, setSelected] = useState('test_a')
  const dispatch = useDispatch()
  const active = variant === 'pause' ? useSelector(state => state.test_ab.set_paused_modal) : useSelector(state => state.test_ab.set_winner_modal)
  const testAB = useSelector(state => state.test_ab.item)
  const handleChange = () => {
    dispatch({ type: 'SET_PAUSED_MODAL', data: false })
    dispatch({ type: 'RESET_TEST_AB' })
  }

  if (!testAB || !testAB._id) {
    return null
  }

  const pause = async () => {
    await updateTest(testAB._id, {
      status: 'paused',
      selected_test: selected,
    })
    handleChange()
  }

  const setWinner = async () => {
    try {
      setSpinner(true)
      await updateTest({ id: testAB._id, winner: selected })
      setSpinner(false)
      handleChange()
    } catch (error) {
      setSpinner(false)
    }
  }

  return (
    <Modal
      activator={!hideAcivator && <Button primary onClick={() => dispatch({ type: 'SET_WINNER_MODAL', data: true })}>Set the winner</Button>}
      open={active}
      onClose={handleChange}
    >
      <div className="PauseSelectVariant">
        {spinner && <div className="spinner-block"><Spinner /></div>}
        <div className="title">{variant === 'pause' ? 'Please select a variant that will show while your test is paused:' : 'Please set the winner'}</div>
        <div className="variant">
          <ChoiceList
            title="Variant A (Control group, original)"
            choices={[
              { label: `Revenues ${testAB.stat_test_a.revenues}, Units Sold ${testAB.stat_test_a.units_sold}, Add to Cart ${testAB.stat_test_a.add_to_cart}, Product page views ${testAB.stat_test_a.views}`, value: 'test_a' },
            ]}
            selected={selected}
            onChange={(values) => setSelected(values[0])}
          />
        </div>
        <div className="border-top variant">
          <ChoiceList
            title="Variant B (Your modified listing)"
            choices={[
              { label: `Revenues ${testAB.stat_test_b.revenues}, Units Sold ${testAB.stat_test_b.units_sold}, Add to Cart ${testAB.stat_test_b.add_to_cart}, Product page views ${testAB.stat_test_b.views}`, value: 'test_b' },
            ]}
            selected={selected}
            onChange={(values) => setSelected(values[0])}
          />
        </div>
        {variant !== 'pause' && (
          <div className="info">
            <Icon
            source={CircleInformationMajor}
            color="base" />
            The details of the variant you select as the winner will automatically be applied to your live product on your store.
          </div>
        )}
        <div className="footer">
          <Button onClick={handleChange}>Cancel</Button>
          <Button primary onClick={
              ()=>{
                if (variant === 'pause')
                  pause()
                else
                  setWinner()
              }
            }
          >{variant === 'pause' ? 'Choose & Pause' : 'Set'}</Button>
        </div>
      </div>
    </Modal>

  )
}

export default PauseFinishModal
