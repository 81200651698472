// import PricingHelp from '../PricingHelp/PricingHelp'
// import Free from '../Plans/Free/Free'
// import Premium from '../Plans/Premium/Premium'
import PlanSetup_V2 from '../../lib/PlanSetup'
import { Grid } from '@shopify/polaris'
import Plan from '../Plan/Plan'

function PricingBody(params) {
  const { request, selectedMode, shop, setWarning, getShop } = params

  const changePlan =async  (pricingPlanName) => {
    await request(pricingPlanName)
    getShop()
  }

  return (
    <div className="page-body">
      <div className="plan-blocks">
        {selectedMode === 'monthly' && (
          <Grid>
            {PlanSetup_V2.monthly.map(plan => (
              <Grid.Cell columnSpan= {{ xs: 6, sm: 3, md: 3, lg: 3, xl: 3 }}>
                <Plan pricingPlan={plan} shop={shop} changePlan={changePlan} setWarning={setWarning} />
              </Grid.Cell>
            ))}
          </Grid>
        )}
        {selectedMode === 'yearly' && (
          <Grid>
            {PlanSetup_V2.yearly.map(plan => (
              <Grid.Cell columnSpan= {{ xs: 6, sm: 3, md: 3, lg: 3, xl: 3 }}>
                <Plan pricingPlan={plan} shop={shop} changePlan={changePlan} setWarning={setWarning} />
              </Grid.Cell>
            ))}
          </Grid>
        )}

      </div>
    </div>
  )
}
export default PricingBody
