import { Button, Card, DataTable, Icon } from '@shopify/polaris'
import TextTooltiped from '../../components/Fields/TextTooltiped'
import '../Details/style.scss'
import { useParams } from 'react-router-dom'
import useContentTest from '../ContentTestEditor/hooks'
import { MobileBackArrowMajor } from '@shopify/polaris-icons'
import useCustomHistory from '../../hooks/useCustomHistory'

function ContentTestDetails() {
  const params = useParams()
  const { contentTest } = useContentTest(params.id)

  const line = (variant) => {
    const stat = variant === 'A' ? contentTest.stat_test_a : contentTest.stat_test_b

    if (!stat) return []
    return [`Variant ${variant} (original content)`, stat.views, stat.unique_views, `${stat.time_on_page > 999 ? (stat.time_on_page / 1000).toFixed(1) + ' s' : parseInt(stat.time_on_page) + ' ms'}`, `${stat.bounce_rate.toFixed(2)}%`]
  }
  const history = useCustomHistory()

  return (
    <div className="Details">
      <div className="header">
        <div className="block-left" style={{ display: 'flex', marginBottom: '20px', alignItems: 'center', gap: '10px' }}>
          <Button icon={<Icon source={MobileBackArrowMajor} />} onClick={() => history.push('/')}></Button>
          Back to Dashboard
        </div>
      </div>
      <div className="header">
        <div className="title">Content Test {contentTest.name}</div>
      </div>
      <Card>
        <DataTable
          columnContentTypes={[
            'text',
            'numeric',
            'numeric',
            'numeric',
            'numeric',
          ]}
          headings={[
            'Variant',
            <TextTooltiped text="Views" toolTip="The total number of times the product variant has been visited. Each visit is counted, regardless of whether it's by the same user or not." />,
            <TextTooltiped text="Unique Views" toolTip="The count of individual users who have accessed the product variant. Each unique visitor is counted only once, regardless of the number of times they viewed the variant." />,
            <TextTooltiped text="Average Time on Page" toolTip="The average duration, in seconds or minutes, that users spend on the product variant page. It indicates the level of engagement and interest in the variant." />,
            <TextTooltiped text="Bounce Rate" toolTip="The percentage of users who leave the website after viewing only the product variant page. It reflects the level of engagement or relevancy of the content on that page." />,
          ]}
          rows={[
            line('A'),
            line('B')
          ]}
        />
      </Card>
    </div>
  )
}

export default ContentTestDetails
