import './PricingMode.scss'
import PricingModeOption from './components/PricingModeOption'

function PricingMode({ selectedMode, setSelectedMode }) {
  return (
    <div className="PricingMode">
      <PricingModeOption text="Monthly" value="monthly" selectedMode={selectedMode} onOptionSelection={setSelectedMode} />
      <PricingModeOption text="Yearly (Extra 20% OFF!)" value="yearly" selectedMode={selectedMode} onOptionSelection={setSelectedMode} />
    </div>
  )
}

export default PricingMode
