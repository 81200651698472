import { Checkbox } from '@shopify/polaris'

function Toggle({ newStyle, changeStyle, selectedElement }) {
  const checked = newStyle.display !== 'none'
  const onChange = (newChecked) => {
    const display = () => {
      if (newChecked) return selectedElement.initialStyle.display

      return 'none'
    }

    changeStyle({ display: display() })
  }

  return (
    <div>
      <Checkbox
        label="Display element"
        checked={checked}
        onChange={onChange}
      />
    </div>
  )
}

export default Toggle
