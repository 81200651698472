import ColorButton from './components/ColorButton'
import ColorModal from './components/ColorModal'
import ColorPattern from './components/ColorPattern'
import ColorText from './components/ColorText'
import './style.scss'
import { useState } from 'react'

function Color({ id, value, setValue, defaultValue='#000000' }) {
  const [showColorModal, setShowColorModal] = useState(false)
  const getValue = () => {
    if (value && value.length > 0)  return value

    return defaultValue
  }

  return (
    <div className="Color">
      <ColorText value={getValue()} id={id} setValue={setValue} />
      <ColorPattern value={getValue()} />
      <ColorButton showColorModal={showColorModal} setShowColorModal={setShowColorModal} />
      <ColorModal showColorModal={showColorModal} setShowColorModal={setShowColorModal} value={getValue()} setValue={setValue}/>
    </div>
  )
}

export default Color
