import { Button } from '@shopify/polaris'

function Save({ contentTest, onSave, selectedMode }) {
  const newChanges = contentTest.changes.some(change => !!change.isNew)

  if (selectedMode === 'interact') return <></>

  return (
    <div className="Save">
      <Button primary disabled={!newChanges} onClick={onSave}>
        Save
      </Button>
    </div>
  )
}

export default Save
