import { useEffect, useState } from 'react'
import { getSessionToken } from '@shopify/app-bridge-utils'
import { useAppBridge } from '@shopify/app-bridge-react'
import { useDispatch } from 'react-redux'

export function useFetch() {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()
  const [resData, setResData] = useState(null)
  const app = useAppBridge()

  async function request(url, method = 'GET', data, fakeResult) {
    setLoading(true)
    if (fakeResult) {
      setResData(fakeResult)
      setLoading(false)
      return fakeResult
    }
    try {
      const options = {
        method: method || 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
      if (data) {
        options.body = JSON.stringify(data)
      }
      let lToken = null
      if (process.env.REACT_APP_DEBUG_SHOPIFY_DOMAIN) {
        lToken = 'DEBUG'
      } else {
        lToken = await getSessionToken(app)
      }
      if (!lToken) {
        throw new Error('no token')
      }
      if (lToken) {
        options.headers.Authorization = `Bearer ${lToken}`
      }
      console.log('TCL: request -> options', options)

      const resp = await fetch(process.env.REACT_APP_API_URL + url, options)
      console.log('TCL: request -> resp', resp.status)
      let result = null
      try {
        const respData = await resp.json()
        if (resp.status === 200) {
          result = respData
          setResData(respData)
          setError(undefined)
        } else {
          setError({
            message: respData.message || 'unknown message',
          })
        }
      } catch (err) {
        setError({ message: 'unknown error' })
      }
      setLoading(false)
      return result
    } catch (err) {
      console.log('TCL: request -> err', err)
      setError({ message: 'Unknown' })
    }
  };
  return [request, { loading, error, data: resData }]
}

export function useAddProducts() {
  const [request, { loading, error, data }] = useFetch()
  return [
    (ids) => {
      return request(
        'api/products/sync',
        'POST',
        {
          ids,
        },
      )
    },
    { loading, error, data },
  ]

}

export function useGetVariants() {
  const [request, { loading, error, data }] = useFetch()
  return [
    () => {
      return request(
        'variants',
        'GET',
        undefined,
        // fakeData,
      )
    },
    { loading, error, data },
  ]
}

export function useGetShop() {
  const [request, { loading, error, data }] = useFetch()
  const dispatch = useDispatch()

  useEffect(() => {
    if (data) {
      dispatch({ type: 'GET_SHOP', payload: data })
    }
  }, [data, dispatch])
  return [
    () => {
      return request(
        'default/shop',
        'GET',
        undefined,
        // fakeData,
      )
    },
    { loading, error, data },
  ]
}

export function useGetDashboard() {
  const [request, { loading, error, data }] = useFetch()
  return [
    () => {
      return request(
        'dashboard',
        'GET',
        undefined,
        // fakeData,
      )
    },
    { loading, error, data },
  ]
}

export function useAddVariants() {
  const [request, { loading, error, data }] = useFetch()
  return [
    (body) => {
      return request(
        'variants',
        'POST',
        body,
        // fakeData,
      )
    },
    { loading, error, data },
  ]
}
