function PlanPriceOld({ pricingPlan: { oldPrice } }) {
  const showOldPrice = () => {
    if (!oldPrice) return ''

    return `$ ${oldPrice}`
  }

  return (
    <div className="body-oldPrice">
      {showOldPrice()}
    </div>
  )
}

export default PlanPriceOld
