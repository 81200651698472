import { useEffect } from 'react'
import { Redirect } from '@shopify/app-bridge/actions'
import { useAppBridge } from '@shopify/app-bridge-react'
import { useFetch, useGetShop } from '../../hooks/requests'

export function useChangePlan() {
  const [request, { loading, error, data }] = useFetch()
  const app = useAppBridge()
  const [getShop] = useGetShop()

  const redirect = Redirect.create(app)

  useEffect(() => {
    if (data) {
      if (data.confirmationURL) {
        redirect.dispatch(Redirect.Action.REMOTE, {
          url: data.confirmationURL,
        })
      } else {
        getShop()
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return [
    (pricingPlan) => {
      return request(
        `billing/${pricingPlan}`,
        'PUT',
        undefined,
      )
    },
    { loading, error, data },
  ]

}

export function useCurrentImpressions() {
  const [request, { loading, error, data }] = useFetch()
  return [
    () => {
      return request(
        'billing/current_Impressions',
        'GET',
        undefined,
        // fakeData,
      )
    },
    { loading, error, data },
  ]
}
