import { Modal, TextContainer } from '@shopify/polaris'
import React from 'react'

export default function NoProductsAccess({ active, setActive }) {

  return (
    <Modal
      open={active}
      onClose={() => setActive(false)}
      title="No access"
      primaryAction={{
        content: <><p style={{ padding: '0 2.5rem' }}>OK</p></>,
        onAction: () => setActive(false),
      }}
    >
      <Modal.Section>
        <TextContainer>
          <p>Your account does not have access to the store's products. Please request access to the store's products or switch to another account that already has the access.</p>
        </TextContainer>
      </Modal.Section>
    </Modal>
  )
}
