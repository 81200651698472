import orderBy from 'lodash/orderBy'
import { isEmpty } from 'lodash'

export default class DashboardData {
  constructor(state) {
    this.list = state.data
    this.state = state
  }

  sort({ field, order }) {
    this.list = orderBy(this.list, [field], [order])
  }

  filterByStatus(statusParam) {
    if (isEmpty(statusParam)) return

    this.list = this.list.filter(testAb => statusParam.includes(testAb.status))
  }

  data() {
    this.filterByStatus(this.state.statusParam)
    this.sort(this.state.sortParams)

    return this.list
  }
}
