import { Button } from '@shopify/polaris'
import { useContext, useEffect, useState } from 'react'
import './style.scss'
import { ModalContext } from '../../../../../../../contexts/ModalContext'

function TextField({ newStyle, changeStyle, isEditMode }) {
  function onChange(event) {
    changeStyle({ text: event.target.value })
  }

  if (!isEditMode) return <></>

  return (
    <div className="content-form-item">
      <textarea className="ContentTestHtmlEditor" value={newStyle.text} onChange={onChange} />
    </div>
  )
}

function ToggleButton({ isEditMode, setIsEditMode }) {
  const onClick = (_event) => {
    setIsEditMode(!isEditMode)
  }

  return (
    <div className="ToggleButton">
      <Button size="slim" onClick={onClick}>Edit HTML</Button>
    </div>
  )
}

function HTMLEditor({ newStyle, changeStyle }) {
  const [isEditMode, setIsEditMode] = useState(false)

  const { setShowHTMLModal } = useContext(ModalContext)

  useEffect(() => {
    setShowHTMLModal(isEditMode)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditMode])

  return (
    <>
      <ToggleButton isEditMode={isEditMode} setIsEditMode={setIsEditMode} />
      <TextField newStyle={newStyle} changeStyle={changeStyle} isEditMode={isEditMode} setIsEditMode={setIsEditMode} />
    </>
  )
}

export default HTMLEditor
