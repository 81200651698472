function PlanPriceCurrent({ pricingPlan: { price } }) {
  return (
    <div className="body-price">
      <span className="price-value">$ { price }</span>
      <span className="price-sufix">/mo</span>
    </div>
  )
}

export default PlanPriceCurrent
