import { Card } from '@shopify/polaris'
import { Field } from 'redux-form'
import CheckBoxInput from '../../../../../../components/Fields/CheckBoxInput'
function AutomaticWinnerSection() {
  return (
    <Card title="Set winner automatically" sectioned>
      <Field
          name="automatically_set_winner"
          component={CheckBoxInput}
          label="Do you want the variant with best performance to be automatically chosen after the test is finished?"
          uncheckedValue={false}
        />
    </Card>
  )
}

export default AutomaticWinnerSection
