import { Button, Card, DataTable, Spinner, Icon, Link } from '@shopify/polaris'
import { MobileBackArrowMajor } from '@shopify/polaris-icons'
import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import StatusTestAb from '../../components/StatusTestAb/StatusTestAb'
import useCustomHistory from '../../hooks/useCustomHistory'
import { useTestsAB } from './hooks'
import './style.scss'
import moment from 'moment'
import ItemMenu from './components/ItemMenu/ItemMenu'
import DashboardFilter from './components/DashboardFilter/DashboardFilter'
import PauseFinishModal from '../Dashboard/components/PauseFinishModal/PauseFinishModal'
import { useParams } from 'react-router-dom'
import DashboardHelper from '../../lib/DashboardHelper'

const getImageProduct = (test) => {

  if (test.sample_image) {
    return test.sample_image
  }
  return '/logo192.png'
}

function Dashboard() {
  const params = useParams()
  const history = useCustomHistory()
  const [{ list, search, status, sort, loaded, parentBulkTest }, { getChildrenTests, setSearch, setStatus, setSort, winnerTest, pauseTest, resumeTest }] = useTestsAB()

  const dispatch = useDispatch()

  const columns = ['name', 'status', 'time']

  useEffect(() => {
    getChildrenTests(params.id)
    dispatch({
      type: 'SET_TEST_AB', data: {
        start_date: moment().toDate(),
        finish_date: moment().add(14, 'days').toDate(),
        variant_every: 30,
        variant_every_type: 'minutes',
        automatically_set_winner: false,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const rows = list.map(test => (
    [
      <div className="productName">
        <div className="img"><img src={getImageProduct(test)} alt="" /></div> {test.name}
      </div>,
      <div className="block">
        <StatusTestAb test={test} />
      </div>,
      <div className="block">
        {DashboardHelper.getTime(test.time)}
      </div>,
      <div className="actions">
        <Link removeUnderline onClick={() => { history.push(detailsUrl(test)) }}>Results</Link>
        <ItemMenu status={test.status} id={test._id} testAB={test} resumeTest={resumeTest} />
      </div>,
    ]
  ))

  const handleSort = useCallback(
    (index, direction) => {
      setSort(columns[index] + '|' + (direction === 'descending' ? 'desc' : 'asc'))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rows],
  )

  // eslint-disable-next-line no-unused-vars
  const [field, direction] = (sort || '').split('|')
  return (
    <div className="Dashboard">
      <div className="header">
        <div className="block-left" style={{ display: 'flex', marginBottom: '20px', alignItems: 'center', gap: '10px' }}>
          <Button icon={<Icon source={MobileBackArrowMajor} />} onClick={() => history.push('/')}></Button>
          Back to Dashboard
        </div>
      </div>
      {loaded && <h1 className="parentBulkTestName">{parentBulkTest.name}</h1>}
      {!loaded && <div className="spinner-container">
        <Spinner />
      </div>}
      {loaded && <Card>
        <Card.Section>
          <DashboardFilter
            status={status}
            setStatus={setStatus}
            search={search}
            setSearch={setSearch}
          />
        </Card.Section>
        <PauseFinishModal variant={'set_winner'} updateTest={winnerTest} hideAcivator />
        <PauseFinishModal variant={'pause'} updateTest={pauseTest} hideAcivator />
        <DataTable
          columnContentTypes={[
            'text',
            'text',
            'text',
            'text',
          ]}
          headings={[
            'Product',
            'Status',
            'Time',
            '',
          ]}
          rows={rows}
          sortable={[true, true, true, true]}
          defaultSortDirection={direction === 'desc' ? 'descending' : 'ascending'}
          sort
          onSort={handleSort}
        />
      </Card>}
    </div>
  )

  function detailsUrl(test) {
    return `/details/${test._id}`
  }
}

export default Dashboard
