import { Link } from '@shopify/polaris'

function BestPerformanceContainer({ testAB, dispatch }) {
  return (<div style={{ fontSize: 12 }}>
    ⭐ This variant
    is the best performing so far 🎉 <Link onClick={() => dispatch({ type: 'SET_WINNER_MODAL', data: true })}>Set it as a winner?</Link>
  </div>)
}

export default BestPerformanceContainer
