function ModeOption({ text, value, selectedMode, onOptionSelection }) {
  const optionClass = `${value}-mode-button`
  const selectedClass = selectedMode === value ? 'selected-mode' : ''

  return (
    <div
      className={`${optionClass} ${selectedClass}`}
      onClick={() => onOptionSelection(value)}>
      { text }
    </div>
  )
}

export default ModeOption
