import { rgbToHsb, hsbToRgb, rgbToHex } from '@shopify/polaris'
class ColorUtils {
  static hexToRgb(hexColor, removeSharp = true) {
    const color = removeSharp ? hexColor.replace('#', '') : hexColor

    let aRgbHex = color.match(/.{1,2}/g)
    return {
      red: parseInt(aRgbHex[0], 16),
      green: parseInt(aRgbHex[1], 16),
      blue: parseInt(aRgbHex[2], 16)
    }
  }

  static hexToHsb(hexColor) {
    const rgb = ColorUtils.hexToRgb(hexColor)

    return rgbToHsb(rgb)
  }

  static hsbToHex(hsbColor) {
    const rgb = hsbToRgb(hsbColor)

    return rgbToHex(rgb)
  }
}

export default ColorUtils
