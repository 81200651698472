/* eslint-disable react-hooks/exhaustive-deps */
import { RangeSlider } from '@shopify/polaris'
import { useCallback, useState } from 'react'
import './style.scss'

function ImageSizeSlider({ selectedElement: { initialStyle }, changeStyle }) {
  const [rangeValue, setRangeValue] = useState(100)

  const handleRangeSliderChange = useCallback(
    (value) => {
      setRangeValue(value)
      changeStyle({
        height: (parseInt(initialStyle.height.replace('px', '')) * value / 100.0).toString().concat('px'),
        width: (parseInt(initialStyle.width.replace('px', '')) * value / 100.0).toString().concat('px')
      })
    },
    [],
  )

  return (
    <div className="ImageSizeSlider">
      <RangeSlider
        label="Image Size"
        value={rangeValue}
        min={0}
        max={200}
        prefix={'%'}
        suffix={<p>{rangeValue}</p>}
        onChange={handleRangeSliderChange}
        output
      />
    </div>
  )
}

export default ImageSizeSlider
