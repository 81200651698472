import { InlineError } from '@shopify/polaris'

function PlanChangeError({ error }) {
  if (!error) return <></>

  return (
    <InlineError message="Error changing plan!" />
  )
}

export default PlanChangeError
