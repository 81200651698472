import { Button } from '@shopify/polaris'
import ColorButtonImage from '../ColorButton.svg'

function ColorButton({ showColorModal, setShowColorModal }) {
  const onClick = () => setShowColorModal(!showColorModal)

  return (
    <>
      <Button primary size="slim" onClick={onClick}>
        <img src={ColorButtonImage} alt="text"/>
      </Button>
    </>
  )
}

export default ColorButton
