import './PricingDiscountVector.scss'

function PricingDiscountVector({ selectedMode }) {
  return (
    <div className="PricingDiscountVector">
      <img src="/DiscountVector.png" alt="Save 84% Forever"></img>
      <span>{`Save ${selectedMode === 'monthly' ? '80%':'84%'} Forever`}</span>
    </div>
  )
}

export default PricingDiscountVector
