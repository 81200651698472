import { Button, Card, Icon, Toast } from '@shopify/polaris'
import { CircleInformationMajor } from '@shopify/polaris-icons'
import React, { useCallback, useState } from 'react'
import ChangeProduct from '../ChangeProduct/ChangeProduct'
import { Field, FormSection } from 'redux-form'
import './style.scss'
import SectionProduct from './components/SectionProduct/SectionProduct'
import { required } from 'redux-form-validators'
import SupportLink from '../../../../lib/SupportLink'

function FormStep2({ validateTest, onChangesInImages }) {

  const [active, setActive] = useState(false)
  // const [testBImages, setTestBImages] = useState(false)
  const toggleActive = useCallback(() => setActive((active) => !active), [])
  const toastMarkup = active ? (
    <Toast content='Your "Variant A" cannot be edited. It represents your current product information. To edit your "Variant A", head to the Product section in Shopify.' onDismiss={toggleActive} duration={8000} />
  ) : null

  return (
    <div className="FormStep2">
      <div className="title">Create variants for your A/B test</div>
      <Card>
        <Card.Section>
          <Field component={ChangeProduct} validateTest={validateTest} name="test_a.product_id" validate={[required()]} />
        </Card.Section>
      </Card>
      <div className="row Variant">
        <div className="col-2">
          <div className="title">Variant A (Control group, original) <div><Icon source={CircleInformationMajor} /></div></div>
          <div className="text">Needed to compare the changes</div>
          <Card>
            <Card.Section>
              <FormSection name="test_a" >
                <SectionProduct section="test_a" disabled />
              </FormSection>
            </Card.Section>
            <div className="disabled" onClick={() => setActive(true)} />
          </Card>
        </div>
        <div className="col-2">
          <div className="title">Variant B (Your modified listing) <div><Icon source={CircleInformationMajor} /></div></div>
          <div className="text">You can change any field of this variant</div>
          <Card>
            <Card.Section>
              <FormSection name="test_b" >
                <SectionProduct section="test_b" onChangesInImages={onChangesInImages}/>
              </FormSection>
            </Card.Section>
          </Card>
        </div>
      </div>
      <div className="block-info">
        <div className="video">
          <img src="/Thumbnail.png" alt="" />
        </div>
        <div className="text-content">
          <div className="title">How exactly will this help me increase my sales?</div>
          <div className="text">
            A/B testing is proven methodology to help improve the conversion rate of your product listings. It’s not as complicated as it sounds 😊. This involves showing two slightly different versions of a Shopify Product Listing to your visitors and measuring which one performs better.
          </div>
          <Button external url={ new SupportLink().getInstructions() }>Learn more</Button>
        </div>
      </div>
      {toastMarkup}
    </div>
  )
}

export default FormStep2
