function PricingModeOption({ text, value, selectedMode, onOptionSelection }) {
  const optionClass = `${value}-mode-button`
  const selectedClass = selectedMode === value ? 'selected-mode' : ''

  return (
    <div
      className={`${optionClass} ${selectedClass}`}
      onClick={() => onOptionSelection(value)}
      style={{ wordWrap: 'break-word' }}
    >
      { text }
    </div>
  )
}

export default PricingModeOption
