import { getSessionToken } from '@shopify/app-bridge-utils'

async function request(url, method = 'GET', data, fakeResult, app) {
  if (fakeResult) {
    return fakeResult
  }
  try {
    const options = {
      method: method || 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }
    if (data) {
      options.body = JSON.stringify(data)
    }
    let lToken = null
    if (process.env.REACT_APP_DEBUG_SHOPIFY_DOMAIN) {
      lToken = 'DEBUG'
    } else {
      lToken = await getSessionToken(app)
    }
    if (!lToken) {
      throw new Error('no token')
    }
    if (lToken) {
      options.headers.Authorization = `Bearer ${lToken}`
    }
    console.log('TCL: request -> options', url, options)

    const resp = await fetch(process.env.REACT_APP_API_URL + url, options)
    console.log('TCL: request -> resp', resp.status)
    let result = null
    try {
      const respData = await resp.json()
      if (resp.status >= 200 && resp.status <= 299) {
        result = respData
      } else {
        throw new Error(resp.status)
      }
    } catch (err) {
      throw new Error('Unknown')
    }
    return result
  } catch (err) {
    console.log('TCL: request -> err', err)
    return new Error('Unknown')
  }
};

export default request
