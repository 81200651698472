import { useEffect, useState, useCallback } from 'react'
import createApp from '@shopify/app-bridge'
import { Redirect } from '@shopify/app-bridge/actions'
import { getSessionToken } from '@shopify/app-bridge-utils'

function useToken() {
  const [shopOrigin, setShopOrigin] = useState(null)
  const [error, setError] = useState(false)
  const [token, setToken] = useState(null)

  const handleRedirect = useCallback((app, _shopOrigin, url) => {
    Redirect.create(app).dispatch(Redirect.Action.REMOTE, `${url.protocol}//${url.host}/auth?shop=${_shopOrigin}`)
  }, [])

  const fetchToken = useCallback(async (app, _shopOrigin, url) => {
    try {
      const lToken = await getSessionToken(app)
      if (!lToken) {
        handleRedirect(app, _shopOrigin, url)
      } else {
        const resp = await fetch(`/api/verify_token?shop=${_shopOrigin}&token=${lToken}`)
        if (resp.ok) {
          const data = await resp.json()
          if (data && data.status === 'ok') {
            setShopOrigin(_shopOrigin)
            setToken(lToken)
          } else {
            handleRedirect(app, _shopOrigin, url)
          }
        } else {
          handleRedirect(app, _shopOrigin, url)
        }
      }
    } catch (err) {
      handleRedirect(app, _shopOrigin, url)
    }
  }, [handleRedirect])

  useEffect(() => {
    if (process.env.REACT_APP_DEBUG_SHOPIFY_DOMAIN) {
      setShopOrigin(process.env.REACT_APP_DEBUG_SHOPIFY_DOMAIN)
      setToken('DEBUG')
      return
    }

    const url = new URL(window.location.href)
    const _shopOrigin = shopOrigin || url.searchParams.get('shop')
    const _host = url.searchParams.get('host')

    if (!_shopOrigin) {
      setError(true)
      return
    }

    if (window.top === window.self) {
      window.location.assign(`/auth?shop=${_shopOrigin}`)
    } else {
      if (!_host) {
        setError(true)
        return
      }

      const app = createApp({
        apiKey: process.env.REACT_APP_API_KEY,
        host: _host,
      })

      fetchToken(app, _shopOrigin, url)
    }
  }, [shopOrigin, fetchToken])

  return { shopOrigin, error, token }
}

export default useToken
