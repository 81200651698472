import './styles.scss'

function ImpressionCounter({ limit, left }) {
  const formattedLimit = typeof limit === 'number' ? limit.toLocaleString('en-US') : limit
  const formattedLeft = typeof left === 'number' ? left.toLocaleString('en-US') : left

  return (
    <div className="Counter">
      <div className="block-header">
        <div className="counter-title">Impressions count</div>
      </div>
      <div className="block-body">
        <div className="counter-body">
          <p className="impression-limit">Impression limit per month: { limit < 0 ? 'Unlimited' : formattedLimit }</p>
          <p className="impression-left">Impressions remaining for the month: { limit < 0 ? 'Unlimited' : formattedLeft }</p>
          { limit > 0 && left === 0 && (
            <p className="impression-warning">
              *You have already reached your monthly impression limit. <br />
              Please upgrade your plan to continue running your A/B tests.
            </p>
          )}
        </div>
      </div>
    </div>
  )
}
export default ImpressionCounter
