const { Message, Source, Role } = require('./Types')

function MessageReader({ messageMapping }) {
  return (event) => {
    if (event.data.source !== Source.ContentEdition) return

    const message = event.data
    const action = messageMapping[message.messageType]
    action({ data: message.data })
  }
}

class Interface {
  static build(role, childWindow) {
    const otherWindow = (role === Role.Page) ? window.parent : childWindow

    return new Interface({ otherWindow, myWindow: window, myRole: role })
  }

  constructor({ otherWindow, myWindow, myRole }) {
    this.otherWindow = otherWindow
    this.myWindow = myWindow
    this.myRole = myRole
  }

  send({ messageType, data }) {
    console.log('INTERCEPT', { from: this.myRole, messageType, data })
    const message = new Message({ role: this.myRole, messageType, data })
    this.otherWindow.postMessage(message, '*')
  }

  subscribeToMessages({ messageMapping }) {
    const messageReader = MessageReader({ messageMapping })
    this.myWindow.addEventListener('message', messageReader, false)
  }
}

module.exports = Interface
