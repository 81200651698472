import { Button, ButtonGroup, Modal } from '@shopify/polaris'
import { useEffect } from 'react'
import HTMLCodeEditor from './HTMLCodeEditor'

function HTMLModal({ showHTMLModal, setShowHTMLModal, selectedElement, setSelectedElement }) {
  useEffect(() => {
    const modalContainers = document.querySelectorAll('.Polaris-Modal-Dialog__Container')
    if (modalContainers.length >= 2) {
      modalContainers[1].classList.add('html-modal-container')
    }
  }, [showHTMLModal])

  const reset = () => {
    setSelectedElement({
      ...selectedElement,
      newStyle: {
        ...selectedElement.newStyle,
        text: selectedElement.initialStyle.text
      }
    })
  }

  const onChange = (value) => setSelectedElement({
    ...selectedElement,
    newStyle: {
      ...selectedElement.newStyle,
      text: value
    }
  })

  const close = () => setShowHTMLModal(false)
  const cancel = () => {
    reset()
    close()
  }

  return (
    <Modal
      open={showHTMLModal}
      onClose={cancel}
      title={'Edit HTML'}
    >
      <div className="html-modal">
        <div className="text-container">
          <HTMLCodeEditor value={selectedElement.newStyle.text} onChange={onChange}/>
        </div>
        <div className="bottom-buttons">
          <ButtonGroup>
            <Button onClick={cancel}>Cancel</Button>
            <Button onClick={close} primary>Change</Button>
          </ButtonGroup>
        </div>
      </div>
    </Modal>
  )
}

export default HTMLModal
