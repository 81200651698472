import { Card } from '@shopify/polaris'
import { Field } from 'redux-form'
import DateTime from '../../../../../../TestForm/components/FormStep3/components/DateTime/DateTime'
import moment from 'moment'
import '../../../../../../TestForm/components/FormStep3/style.scss'

const Form = ({ contentTest, onChange }) => {

  return (
    <div className="FormStep3">
      <div className="form-content">
        <Card title="Adjust the timeframe" sectioned>
          <div className="row">
            <Field
              component={DateTime}
              label1="Start Date"
              label2="Start Time"
              name="start_date"
              dateValue={contentTest.start_date}
              disableDatesBefore={moment().toDate()}
              onChange={(newStartDate) => onChange({ start_date: newStartDate, finish_date: contentTest.finish_date }) }
            />
            <Field
              component={DateTime}
              name="finish_date"
              label1="Finish Date"
              label2="Finish Time"
              dateValue={contentTest.finish_date}
              disableDatesBefore={moment(contentTest.start_date).toDate()}
              onChange={(newFinishDate) => onChange({ start_date: contentTest.start_date, finish_date: newFinishDate }) }
            />
          </div>
        </Card>
      </div>
    </div>
  )
}

  export default Form
