import { Modal, TextContainer } from '@shopify/polaris'
import React, { useEffect, useState } from 'react'
import Image from './components/Image/Image'
import ConfirmationModal from './components/ConfirmationModal/ConfirmationModal'
import BulletList from './components/Bullet/BulletList'
import PricingDetailing from './components/PricingDetailing/PricingDetailing'
import { useGetShop } from '../../hooks/requests'
import PlanSetup_V2 from '../../containers/Pricing/lib/PlanSetup'

export default function UpsellModal({ active, setActive, action }) {
  const [showConfirmation, setShowConfirmation] = useState(undefined)
  const [getShop, { loading, data }] = useGetShop()

  useEffect(() => {
    getShop()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const recomendedPlan = () => {
    const shop = data
    let plan = null
    switch (shop?.pricingPlan) {
      case 'Premium Monthly':
        plan = PlanSetup_V2.monthly[2]
        break
      case 'Premium Yearly':
        plan = PlanSetup_V2.yearly[2]
        break
      case 'Business Monthly':
        plan = PlanSetup_V2.monthly[3]
        break
      case 'Business Yearly':
        plan = PlanSetup_V2.yearly[3]
        break
      default:
        plan = PlanSetup_V2.monthly[1]
        break
    }
    return plan
  }

  return (
    !loading && (
      <Modal
        open={active}
        onClose={() => setActive(false)}
        title="You have reached your plan limit"
        primaryAction={{
          content: `Upgrade to ${recomendedPlan().name}`,
          onAction: () => setShowConfirmation('upgrade'),
        }}
        secondaryActions={[
          {
            content: 'See payment plans',
            onAction: () => setShowConfirmation('plans'),
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>
            <div style={{ textAlign: 'center' }}>
              {data?.pricingPlan === 'Free' && data?.currentMonthImpressions < 500 && (
                <span>You can only run one test at a time. <br />
                  Please upgrade your plan if you need more.
                </span>
              )}
              {(data?.currentMonthImpressions >= data?.pricingPlanData.impressionLimit &&
              <span>You have reached your {data?.pricingPlanData.impressionLimit.toLocaleString('en-US')} impressions limit for this month. <br />
                Please upgrade your plan if you need more.
              </span>
              )}
            </div>
          </TextContainer>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: '16px' }}>
            <div style={{ flex: 1 }}>
              <Image />
            </div>
            <div style={{ flex: 2 }}>
              <div>
                <div>
                  GET OUR <span style={{ color: '#4CC3C1' }}>{recomendedPlan().title}</span> PLAN
                  <span className="upsell-body-duration">{recomendedPlan().priceHighlight} LIFETIME</span>
                  <BulletList specs={recomendedPlan().specs} />
                  <PricingDetailing price={recomendedPlan().price} oldPrice={recomendedPlan().oldPrice} />
                </div>
              </div>
            </div>
          </div>
        </Modal.Section>
        <ConfirmationModal
          showConfirmation={showConfirmation}
          setShowConfirmation={setShowConfirmation}
          action={action}
          planName={recomendedPlan().name}
        />
      </Modal>
    )
  )
}
