import { TextField } from '@shopify/polaris'

function MarginGroup({ changeStyle, newStyle }) {
  return (
    <div className="marginGroup" style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div style={{ width: '48%' }}>
        <TextField
              label="Margin Top"
              type="number"
              value={ newStyle.marginTop.replace('px', '') }
              onChange={marginTop => changeStyle({ marginTop: `${marginTop}px` })}
              autoComplete="off"
              suffix="px"
            />
        <div style={{ marginTop: '12px' }}>
          <TextField
                label="Margin Bottom"
                type="number"
                value={ newStyle.marginBottom.replace('px', '') }
                onChange={marginBottom => changeStyle({ marginBottom: `${marginBottom}px` })}
                autoComplete="off"
                suffix="px"
              />
        </div>
      </div>
      <div style={{ width: '48%' }}>
        <TextField
              label="Margin Right"
              type="number"
              value={ newStyle.marginRight.replace('px', '') }
              onChange={marginRight => changeStyle({ marginRight: `${marginRight}px` })}
              autoComplete="off"
              suffix="px"
            />
        <div style={{ marginTop: '12px' }}>
          <TextField
              label="Margin Left"
              type="number"
              value={ newStyle.marginLeft.replace('px', '') }
              onChange={marginLeft => changeStyle({ marginLeft: `${marginLeft}px` })}
              autoComplete="off"
              suffix="px"
            />
        </div>
      </div>
    </div>
  )
}

export default MarginGroup
