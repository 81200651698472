import Color from '../../../../../../../../components/Color/Color'

function TextColor({ newStyle, changeStyle }) {
  const changeColor = (newColor) => {
    changeStyle({ fontColor: newColor })
  }

  return (
    <div className="content-form-item">
      <label htmlFor="fontColor">Text Color</label>
      <div>
        <Color id="fontColor" value={newStyle.fontColor} setValue={changeColor} defaultValue="#000000" />
      </div>
    </div>
  )
}

export default TextColor
