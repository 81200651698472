import { Button, Icon } from '@shopify/polaris'
import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { getFormValues, reduxForm, change } from 'redux-form'
import moment from 'moment'
import FormStep1 from './components/FormStep1/FormStep1'
import FormStep2 from './components/FormStep2/FormStep2'
import FormStep3 from './components/FormStep3/FormStep3'
import Tab from './components/Tab/Tab'
import { useTestAb } from './hooks'
import './style.scss'
import useCustomHistory from '../../hooks/useCustomHistory'
import { ChevronLeftMinor, ChevronRightMinor } from '@shopify/polaris-icons'
import PriceChange from './components/PriceChange/PriceChange'
import ProgressBarTest from './components/ProgressBarTest/ProgressBarTest'
import RunningChange from './components/RunningChange/RunningChange'
import { arePricesChanged } from '../../lib/arePricesChanged'
import UpsellModal from '../../components/Upsell/UpsellModal'

function TestForm({ handleSubmit, initialize, change }) {

  const history = useCustomHistory()

  const [active, setActive] = useState(false)
  const [valid, setValid] = useState(true)

  const [switchesBetween, setSwitchesBetween] = useState({ variant_every: 0, variant_every_type: 'days' })
  const [activeRunningChange, setActiveRunningChange] = useState(false)
  const [showUpsell, setShowUpsell] = useState(false)

  const [{ loaded, test, result, isValidate, countActiveTest, reached_free_limitation, reached_impressions_limit }, { create, update, check, validate, redirectOnCheck }] = useTestAb()

  const [state, setState] = useState({
    tabActive: 'details',
    nextForm: 'create_variants',
    prevForm: '',
    isPriceChange: false,
    visitedThirdStep: false
  })

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [state.tabActive])

  const shop = useSelector(state => state.shop.shop)
  const item = useSelector(state => state.test_ab.item)

  useEffect(() => {
    initialize(item)
    if (item._id) {
      validate(item._id, item.test_a.handle)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function onChangesInImages(product) {
    setValid( product.images && product.images.length > 0 && product.title)
  }

  const setTabItem = (item) => {
    const newState = {
      ...state,
      tabActive: item,
    }

    switch (item) {
      case 'details':
        newState.nextForm = 'create_variants'
        newState.prevForm = ''
        break
      case 'create_variants':
        newState.nextForm = 'choose_period'
        newState.prevForm = 'details'
        break
      case 'choose_period':
        newState.nextForm = 'save'
        newState.prevForm = 'create_variants'
        break
      default:
        break
      }

    setState(newState)
  }

  const nextStep = async (values) => {
    const newState = {
      ...state,
      tabActive: state.nextForm,
    }
    switch (newState.nextForm) {
      case 'create_variants':
        newState.nextForm = 'choose_period'
        newState.prevForm = 'details'
        break
      case 'choose_period':
        // if (newState.isPriceChange) {
          newState.nextForm = 'save'
          newState.prevForm = 'create_variants'
          newState.visitedThirdStep = true
        // } else {
        //   setActive(true)
        //   return
        // }
        if (
          arePricesChanged(formValues.test_a, formValues.test_b)
          && !Boolean(values && values._id)
          && !state.visitedThirdStep
        ) {
          change('variant_every', '60')
        }
        break
      default:

        let newStatus = ''
        if (moment() < moment(values.start_date)) {
          newStatus = 'pending'
        } else if (moment(values.start_date) < moment() && moment() < moment(values.finish_date)) {
          newStatus = 'running'
        } else if (moment() > moment(values.finish_date)) {
          newStatus = 'completed'
        }

        if (!values._id) {
          if (moment(values.start_date) <= moment() || countActiveTest >= shop.pricingPlanData.testsLimit && ['pending', 'running'].includes(newStatus) || !isValidate) {
            setSwitchesBetween({ variant_every: values.variant_every, variant_every_type: values.variant_every_type })
            setActiveRunningChange(true)
            newState.tabActive = 'choose_period'
            return
          } else {
            create(values)
          }
        } else {
          if (!isValidate || !['winner_assigned', 'completed'].includes(values.status) || countActiveTest >= shop.pricingPlanData.testsLimit && ['pending', 'running'].includes(newStatus) ) {
            setSwitchesBetween({ variant_every: values.variant_every, variant_every_type: values.variant_every_type })
            setActiveRunningChange(true)
            newState.tabActive = 'choose_period'
          } else {
            update(values)
          }
        }

        break
    }
    setState(newState)
  }

  const prevStep = () => {
    setValid(true)
    const newState = {
      ...state,
      tabActive: state.prevForm,
    }

    switch (newState.prevForm) {
      case 'details':
        newState.nextForm = 'create_variants'
        newState.prevForm = ''
        break
      case 'create_variants':
        newState.nextForm = 'choose_period'
        newState.prevForm = 'details'
        break
      default:
        break
    }

    setState(newState)
  }

  const pauseWithRedirect = (redirectTo) => {
    redirectOnCheck(redirectTo)
    pause()
  }

  const pause = () => {
    if (formValues._id) {
      update({ ...formValues, status: 'paused' })
    } else {
      create({ ...formValues, status: 'paused' })
    }

    setActiveRunningChange(false)
  }

  const start = () => {

    if (reached_free_limitation && item.status !== 'running') {
      setShowUpsell(true)
    }
    else if (reached_impressions_limit) {
      setShowUpsell(true)
    }
    else {
      if (formValues._id) {
        update({ ...formValues, status: 'running' })
      } else {
        create(formValues)
      }
    }
    setActiveRunningChange(false)
  }

  const formValues = useSelector(state => getFormValues('test_a_b')(state))

  if (!loaded) {
    return <ProgressBarTest test={test} check={check} result={result} />
  }

  return (
    <div className="TestForm">
      <Tab selected={state.tabActive} setTabItem={setTabItem} formValues={formValues} />
      <PriceChange
        active={active}
        handleClose={() => setActive(false)}
        handleNext={() => {
          setState({
            ...state,
            tabActive: state.nextForm,
            nextForm: 'save',
            prevForm: 'create_variants',
          })
          setActive(false)
        }}
      />
      <UpsellModal active={showUpsell} setActive={setShowUpsell} action={pauseWithRedirect} />
      <RunningChange
        active={activeRunningChange}
        switchesBetween={switchesBetween}
        isValidate={isValidate}
        onClose={() => {
          setActiveRunningChange(false)
        }}
        isUpdate={Boolean(formValues && formValues._id)}
        handleClose={pause}
        handleNext={start}
      />
      <div className="form-content">
        {state.tabActive === 'details' && <FormStep1 validateTest={validate}/>}
        {state.tabActive === 'create_variants' && <FormStep2 validateTest={validate} onChangesInImages={onChangesInImages}/>}
        {state.tabActive === 'choose_period' && <FormStep3 />}
      </div>
      <div className="form-footer">
        {state.prevForm
          ? <Button onClick={prevStep} size="large"><Icon source={ChevronLeftMinor}/> Previous step</Button>
          : <Button size="large" onClick={() => history.push('/')}><Icon source={ChevronLeftMinor}/> Cancel test & return</Button>
        }
        <Button disabled={!valid} size="large" primary onClick={handleSubmit(nextStep)}>Next step <Icon source={ChevronRightMinor}/></Button>
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  change: (...args) => dispatch(change(...args)),
})

export default connect(null, mapDispatchToProps)(reduxForm({
  form: 'test_a_b',
})(TestForm))
