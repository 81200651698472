import { Link } from '@shopify/polaris'
import useCustomHistory from '../../../../hooks/useCustomHistory'
import './style.scss'

export default function NoTestsLink() {
  const history = useCustomHistory()
  return <div className="NoTestsLink">
    Looking to get started?<Link onClick={() => { history.push('/welcome') } }>Create your first test</Link> 🎉
  </div>
}
