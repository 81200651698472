import { Button, DropZone, Thumbnail } from '@shopify/polaris'
import { NoteMinor } from '@shopify/polaris-icons'
import { useState, useCallback } from 'react'
import './style.scss'

function ImageUpload({ changeStyle }) {
  const [file, setFile] = useState()

  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFile(acceptedFiles[0]),
    [],
  )

  const validImageTypes = ['image/gif', 'image/jpeg', 'image/png']

  const fileUpload = !file && <DropZone.FileUpload />
  const uploadedFile = file && (
    <div>
      <Thumbnail
        size="large"
        alt={file.name}
        source={
          validImageTypes.includes(file.type)
            ? window.URL.createObjectURL(file)
            : NoteMinor
        }
      />
      <div>
        <p>
          {file.size} bytes
        </p>
      </div>
    </div>
  )

  const onUpload = () => {
    if (file) {
      const formData = new FormData()
      formData.append('file', file)

      const uploadEndpoint = process.env.REACT_APP_API_URL.replace('/api', '/file_upload')

      fetch(uploadEndpoint, {
        method: 'PUT',
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          console.log('File uploaded successfully:', data)

          changeStyle({ src: data.imageUrl })
        })
        .catch((error) => {
          console.error('Error uploading file:', error)
        })
    }
  }

  return (
    <div className="ImageUpload">
      <DropZone allowMultiple={false} onDrop={handleDropZoneDrop}>
        {uploadedFile}
        {fileUpload}
      </DropZone>
      <Button onClick={onUpload} disabled={!file}>Upload</Button>
    </div>
  )
}

export default ImageUpload
