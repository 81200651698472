import { Card, TextField } from '@shopify/polaris'
import { useState } from 'react'

  const Form = ({ contentTest, onChange, touchedName, setTouchedName }) => {
    const [outSideClick, setOutSideClick] = useState(true)
    const error = touchedName && !contentTest.name

    return <Card sectioned>
      <TextField
        name="name"
        value={contentTest.name}
        label="Give a name to your test"
        onChange={(newName) => {
          onChange({ name: newName })
        }}
        onBlur={(event) => {
          setOutSideClick(true)
        }}
        onFocus={(event) => {
          setTouchedName(true)
          setOutSideClick(false)
        }}
        error={outSideClick && error && 'Test name is required.'}
      />
    </Card>
  }

  export default Form
