import React from 'react'
import './styles.scss'

const TabItem = ({ label, step, myStep, setStep }) => {
  const activeClass = step === myStep ? 'active' : ''

  return (
    <div
      onClick={() => setStep(myStep)}
      className={`item ${activeClass}`}
    >
      {`${myStep + 1}. ${label}`}
    </div>
  )
}

const Tab = ({ multiStep: { labels, step, setStep } }) => {
  return (
    <div className="ContentFormTab">
      { labels.map((label, index) => (
        <TabItem
          key={index}
          label={label}
          step={step}
          myStep={index}
          setStep={setStep}
        />
      ))}
    </div>
  )
}

export default Tab
