// const currentPlanVersion = 2

// const PremiumSpecs = [
//   'Unlimited simultaneous A/B tests',
//   'Analytics',
//   'Priority customer Support',
//   'Quicker variant change frequency',
//   'and more!',
// ]

const v2_specs = (free, limit) => {
  const formattedLimit = typeof limit === 'number' ? limit.toLocaleString('en-US') : limit

  if (free)
    return [
      `Up to ${formattedLimit} impressions per month`,
      'Run a single A/B test',
      'Test analytics',
      'Basic customer support'
    ]
  return [
    limit < 0 ? 'Unlimited impressions per month' : `Up to ${formattedLimit} impressions per month`,
    'Unlimited simultaneous A/B tests',
    'Test analytics',
    'Priority customer support',
    'Quicker variant change frequency',
    'And more!'
  ]
}

function PremiumButtons(planName) {
  return {
    Main: (shop) => {
      if (shop.pricingPlan === planName) return 'Chosen'
      if (shop.trialStartedAt || shop.pricingPlan === 'Free') return 'Choose'

      return 'Start your 5-days free trial'
    }
  }
}

// const PlanSetup = {
//   Free: {
//     priceHighlight: undefined,
//     priceExtraInfo: undefined,
//     oldPrice: undefined,
//     price: (_shop) => 0,
//     specs: [
//       'Run a single A/B test',
//       'Analytics',
//       'Basic Customer Support',
//     ],
//     title: 'Free',
//     name: 'Free',
//     buttons: {
//       Extra: () => undefined,
//       Main: (shop) => {
//         if (shop.pricingPlan === 'Free') return 'Chosen'

//         return 'Choose free'
//       }
//     }
//   },
//   Yearly: {
//     priceHighlight: 'SAVE 89%',
//     priceExtraInfo: 'Billed yearly at $134.30',
//     oldPrice: 99.99,
//     price: (_shop) => 11.19,
//     specs: PremiumSpecs,
//     title: 'Premium',
//     name: 'Yearly',
//     buttons: PremiumButtons('Yearly')
//   },
//   Monthly: {
//     priceHighlight: 'SAVE 86%',
//     priceExtraInfo: undefined,
//     oldPrice: 99.99,
//     price: (shop) => {
//       if (shop.pricingPlan === 'Premium' && shop.pricingPlanVersion !== currentPlanVersion) return 29.99

//       return 13.99
//     },
//     specs: PremiumSpecs,
//     title: 'Premium',
//     name: 'Premium',
//     buttons: PremiumButtons('Premium')
//   }
// }

const PlanSetup_V2 = {
  monthly: [
    {
      priceHighlight: undefined,
      priceExtraInfo: undefined,
      oldPrice: undefined,
      price: 0,
      specs: v2_specs(true, 500),
      title: 'FREE',
      name: 'Free',
      buttons: {
        Extra: () => undefined,
        Main: (shop) => {
          if (shop.pricingPlan === 'Free') return 'Chosen'
          return 'Choose free'
        }
      }
    },
    {
      priceHighlight: 'SAVE 80%',
      priceExtraInfo: undefined,
      oldPrice: 99.99,
      price: 19.99,
      specs: v2_specs(false, 5000),
      title: 'PREMIUM',
      name: 'Premium Monthly',
      buttons: PremiumButtons('Premium Monthly')
    },
    {
      priceHighlight: 'SAVE 80%',
      priceExtraInfo: undefined,
      oldPrice: 124.99,
      price: 24.99,
      specs: v2_specs(false, 50000),
      title: 'BUSINESS',
      name: 'Business Monthly',
      buttons: PremiumButtons('Business Monthly')
    },
    {
      priceHighlight: 'SAVE 80%',
      priceExtraInfo: undefined,
      oldPrice: 249.99,
      price: 49.99,
      specs: v2_specs(false, -1),
      title: 'ENTERPRISE',
      name: 'Enterprise Monthly',
      buttons: PremiumButtons('Enterprise Monthly')
    }
  ],
  yearly: [
    {
      priceHighlight: undefined,
      priceExtraInfo: undefined,
      oldPrice: undefined,
      price: 0,
      specs: v2_specs(true, 500),
      title: 'FREE',
      name: 'Free',
      buttons: {
        Extra: () => undefined,
        Main: (shop) => {
          if (shop.pricingPlan === 'Free') return 'Chosen'
          return 'Choose free'
        }
      }
    },
    {
      priceHighlight: 'SAVE 84%',
      priceExtraInfo: 'billed yearly $191.90',
      oldPrice: 99.99,
      price: 15.99,
      specs: v2_specs(false, 5000),
      title: 'PREMIUM',
      name: 'Premium Yearly',
      buttons: PremiumButtons('Premium Yearly')
    },
    {
      priceHighlight: 'SAVE 84%',
      priceExtraInfo: 'billed yearly $239.90',
      oldPrice: 124.99,
      price: 19.99,
      specs: v2_specs(false, 50000),
      title: 'BUSINESS',
      name: 'Business Yearly',
      buttons: PremiumButtons('Business Yearly')
    },
    {
      priceHighlight: 'SAVE 84%',
      priceExtraInfo: 'billed yearly $479.90',
      oldPrice: 249.99,
      price: 39.99,
      specs: v2_specs(false, -1),
      title: 'ENTERPRISE',
      name: 'Enterprise Yearly',
      buttons: PremiumButtons('Enterprise Yearly')
    },
  ]
}

export default PlanSetup_V2

