import request from '../../../../lib/request'

export async function getProduct(id, app) {
  try {
    const data = request(`default/product?id=${id}`, 'GET', undefined, undefined, app)
    return data
  } catch (error) {
    return {}
  }
}
