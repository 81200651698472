function Message({ role, messageType, data }) {
  this.role = role
  this.messageType = messageType
  this.data = data || {}
  this.source = Source.ContentEdition
}

const Source = {
  ContentEdition: 'content-edition'
}

const Role = {
  Page: 'page',
  Editor: 'editor'
}

const MessageTypes = {
  Page: {
    ElementSelected: 'element-selected',
    ElementSelectorChanged: 'element-selector-changed',
    UrlPathChanged: 'url-changed'
  },
  Editor: {
    EditorOpened: 'editor-opened',
    ModeChanged: 'mode-changed',
    ElementChanged: 'element-changed',
  }
}

module.exports = { Message, Role, MessageTypes, Source }
