import { Button } from '@shopify/polaris'
import './style.scss'

function PrePaymentWarning({ continueToPayment }) {
  return <div className="PrePaymentWarning">
    <div className="text-block">
      <p>The next page is the Shopify charge page.</p>
      <p className="highlighted-text">You will not be charged unless you keep the app longer than the <span style={{ color: '#2382A3' }}>5-day free trial</span>.</p>
      <p>If you don't want the app, you can uninstall it or switch to our free plan.</p>
      <div style={{ marginTop: '10px' }}>
        <Button primary onClick={continueToPayment} >Continue</Button>
      </div>
    </div>
    <div className="image-block">
      <img src="./TrialWarningPicture.svg" width="100%" alt="" />
    </div>
  </div>
}

export default PrePaymentWarning
