import React from 'react'
import { Editor } from '@tinymce/tinymce-react'
import { useRef } from 'react'
import './TextEditInput.scss'
import classNames from 'classnames'

const TextEditInput = ({ label, style, disabled, input, className, meta: { error, touched }, handleBlur, handleFocus, eventName }) => {
  const editorRef = useRef(null)

  return (
    <div className={classNames('TextEditInput', className, { disabled })} style={{ ...style, position: 'relative' }}>
      <Editor
         onInit={(evt, editor) => editorRef.current = editor}
         initialValue={input.value}
         onBlur={() => {
          if (editorRef.current) {
            input.onChange(editorRef.current.getContent())
          }
         }}
         init={{
           height: 250,
           menubar: false,
           images_upload_url: 'upload',
           plugins: [
             'advlist autolink lists link image charmap print preview anchor',
             'searchreplace visualblocks code fullscreen',
             'insertdatetime media table paste code help wordcount link image code media table',
           ],
           toolbar1: 'formatselect bold italic underline | bullist numlist outdent indent | code',
           toolbar2: '| alignright alignjustify alignselect aligncenter forecolor | table link image media removeformat',
           content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
         }}
       />
    </div>
  )
}

export default TextEditInput
