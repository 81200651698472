import React from 'react'
import { Select } from '@shopify/polaris'

const SelectInput = ({ label, input, options, className, handleChange, meta: { error, touched } }) => {
  return (
    <div className={className}>
      <Select
        label={label}
        options={options}
        onChange={(value) => {
          if (handleChange) {
            handleChange(value, input)
            return
          }
          if (input.onChange) {
            input.onChange(value)
          }
        }}
        error={touched && error || null}
        value={input.value}
      />
    </div>
  )
}

export default SelectInput
