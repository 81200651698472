 const getDataTestFromProduct = (product) => {
  const result = {
    product_id: product.id,
    title: product.title,
    handle: product.handle,
    description: product.body_html,
    variants: product.variants.map(_v => ({
      id: _v.id,
      compare_at_price: _v.compare_at_price,
      title: _v.title,
      price: _v.price,
      image_id: _v.image_id,
    })),
    images: product.images.map(shopifyImage => ({
      id: shopifyImage.id,
      product_id: shopifyImage.product_id,
      src: shopifyImage.src,
      position: shopifyImage.position,
      variant_ids: shopifyImage.variant_ids,
      alt: shopifyImage.alt
    })),
  }
  return result
}

export default getDataTestFromProduct
