import { ActionList, Button, Popover } from '@shopify/polaris'
import { MobileHorizontalDotsMajor } from '@shopify/polaris-icons'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

function ItemMenu({ status, id, testAB, resumeTest, deleteTest }) {
  const [active, setActive] = useState(false)
  const dispatch = useDispatch()

  const activator = (
    <Button onClick={() => { setActive(true) }} icon={MobileHorizontalDotsMajor}>
    </Button>
  )

  const items = []

  items.push({
    content: 'View Product Page', onAction: async () => {
      window.open(`https://${testAB.shopify_domain}/products/${testAB.test_a.handle}`, '_blank')
    },
  })
  if (status === 'running') {
    items.push({
      content: 'Pause', onAction: async () => {
        dispatch({ type: 'SET_TEST_AB', data: testAB })
        dispatch({ type: 'SET_PAUSED_MODAL', data: true })
      },
    })
    items.push({
      content: 'Finish & Choose Winner', onAction: async () => {
        dispatch({ type: 'SET_TEST_AB', data: testAB })
        dispatch({ type: 'SET_WINNER_MODAL', data: true })
      },
    })
  }
  if (status === 'paused') {
    items.push({ content: 'Resume', onAction: () => resumeTest(id) })
    items.push({
      content: 'Finish & Choose Winner', onAction: async () => {
        dispatch({ type: 'SET_TEST_AB', data: testAB })
        dispatch({ type: 'SET_WINNER_MODAL', data: true })
      },
    })
  }
  if (status === 'completed') {
    items.push({
      content: 'Choose Winner', onAction: async () => {
        dispatch({ type: 'SET_TEST_AB', data: testAB })
        dispatch({ type: 'SET_WINNER_MODAL', data: true })
      },
    })
  }
  return (
    <Popover
      active={active}
      activator={activator}
      preferredAlignment="right"
      onClose={() => {
        setActive(false)
      }}
    >
      <ActionList items={items} />
    </Popover>
  )
}

export default ItemMenu
