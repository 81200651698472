import './style.scss'

export default function Image () {
  return (
    <div className="image">
      <div className="overlap-group-wrapper">
        <div className="overlap-group">
          <img
            className="vector"
            alt="Vector"
            src="https://cdn.animaapp.com/projects/65293ec2d872a49b17c6cb6f/releases/65293f255724ca6e9a7b1564/img/vector.svg"
          />
          <img
            className="mask-group"
            alt="Mask group"
            src="https://cdn.animaapp.com/projects/65293ec2d872a49b17c6cb6f/releases/65293f255724ca6e9a7b1564/img/mask-group@2x.png"
          />
          <div className="frame">
            <img
              className="group"
              alt="Group"
              src="https://cdn.animaapp.com/projects/65293ec2d872a49b17c6cb6f/releases/65293f255724ca6e9a7b1564/img/group-331@2x.png"
            />
            <img
              className="img"
              alt="Vector"
              src="https://cdn.animaapp.com/projects/65293ec2d872a49b17c6cb6f/releases/65293f255724ca6e9a7b1564/img/vector-1.svg"
            />
            <img
              className="vector-2"
              alt="Vector"
              src="https://cdn.animaapp.com/projects/65293ec2d872a49b17c6cb6f/releases/65293f255724ca6e9a7b1564/img/vector-2.svg"
            />
            <img
              className="vector-3"
              alt="Vector"
              src="https://cdn.animaapp.com/projects/65293ec2d872a49b17c6cb6f/releases/65293f255724ca6e9a7b1564/img/vector-3.svg"
            />
            <img
              className="vector-4"
              alt="Vector"
              src="https://cdn.animaapp.com/projects/65293ec2d872a49b17c6cb6f/releases/65293f255724ca6e9a7b1564/img/vector-4.svg"
            />
            <div className="rectangle" />
          </div>
          <div className="div">
            <img
              className="group"
              alt="Group"
              src="https://cdn.animaapp.com/projects/65293ec2d872a49b17c6cb6f/releases/65293f255724ca6e9a7b1564/img/group-331-1@2x.png"
            />
            <img
              className="img"
              alt="Vector"
              src="https://cdn.animaapp.com/projects/65293ec2d872a49b17c6cb6f/releases/65293f255724ca6e9a7b1564/img/vector-5.svg"
            />
            <img
              className="vector-2"
              alt="Vector"
              src="https://cdn.animaapp.com/projects/65293ec2d872a49b17c6cb6f/releases/65293f255724ca6e9a7b1564/img/vector-6.svg"
            />
            <img
              className="vector-3"
              alt="Vector"
              src="https://cdn.animaapp.com/projects/65293ec2d872a49b17c6cb6f/releases/65293f255724ca6e9a7b1564/img/vector-7.svg"
            />
            <img
              className="vector-4"
              alt="Vector"
              src="https://cdn.animaapp.com/projects/65293ec2d872a49b17c6cb6f/releases/65293f255724ca6e9a7b1564/img/vector-8.svg"
            />
            <div className="rectangle" />
          </div>
          <img
            className="group-2"
            alt="Group"
            src="https://cdn.animaapp.com/projects/65293ec2d872a49b17c6cb6f/releases/65293f255724ca6e9a7b1564/img/group@2x.png"
          />
        </div>
      </div>
    </div>
  )
}
